import React, { Component } from 'react'
import { Row,Col,Menu, Dropdown,Icon,Avatar,Tooltip,Spin,Button, Empty, Modal, InputNumber } from 'antd'
import { Popconfirm, Tag } from 'antd';
import { connect } from 'react-redux';
import * as TeacherActions from '../../redux/actions/TeacherActions'
import * as UsersActions from '../../redux/actions/UsersActions'
import * as CoursesActions from '../../redux/actions/CoursesActions'
import AssistanceManagement from '../assistances/AssistanceManagement'
import CourseCompleteLayer from '../courses/CourseCompleteLayer'
import FinishCourseBtn from './buttons/FinishCourseBtn'
import './WeeklySchedule.css'
import { UserOutlined,CheckCircleTwoTone  } from '@ant-design/icons';
import { USUARIO,ADMINISTRADOR,INSTRUCTOR } from '../../utility/AppConstants'


const ADD_CLASS = '¿DESEA AGREGAR ESTA CLASE AL CURSO?'
const DELETE_CLASS = '¿ELIMINAR ESTA CLASE DEL CURSO?'
const CLOSE_ICON = 'close-circle'
const CHECK_ICON = 'check-circle'
const CLOSE_COLOR = 'red'
const CHECK_COLOR = 'green'

const { SubMenu } = Menu;
const { confirm } = Modal;

class WeeklySchedule extends Component{
    state={
        days:['Lunes','Martes','Miercoles','Jueves','Viernes','Sabado','Domingo'],
        times:[
            {time:9,text:'9am'},
            {time:11,text:'11am'},
            {time:13,text:'1pm'},
            {time:15,text:'3pm'},
            {time:17,text:'5pm'},
            {time:19,text:'7pm'}
        ],
        weekendTimes : [10,15],
        schedule :[   
            [false,false,false,false,false,false,false],
            [false,false,false,false,false,false,false],
            [false,false,false,false,false,false,false],
            [false,false,false,false,false,false,false],
            [false,false,false,false,false,false,false],
            [false,false,false,false,false,false,false],
        ],
        hoursAssigned:0,
        vencimiento: null,
        bloqueo: null,
        courseToleranceVisible: false,
        extraDays: 0,
        cursoFinalizado: null
    }
    componentDidUpdate(){
        if(this.props.UsersReducer.refreshCourses || this.props.CoursesReducer.refreshCoursesInCourses){
            this.buildSchedule()
            this.props.refreshCourses({refreshCourses:false})
            this.props.refreshCoursesInCoursesActions({refreshCoursesInCourses:false})
            /*if(this.props.view === ADMINISTRADOR){
                console.log('calling teachers in componentDidUpdate')
                this.props.getTeachersByCourseRequest({jwtToken: this.props.LoginReducer.sessionJWTToken})
            }*/
        }
        if(this.props.UsersReducer.coursesUpdated){
            this.buildSchedule()
            this.props.setCoursesUpdated({coursesUpdated:false})
        }
    }
    UNSAFE_componentWillMount(){
        if(this.props.userType === 3 && this.props.UsersReducer.userToShow !== undefined){
            console.log('call classes of course : '+this.props.curso.idCurso)
            this.props.getCoursesClassesRequest({
                jwtToken: this.props.LoginReducer.sessionJWTToken,
                courseId: this.props.curso.idCurso,
                studentId: this.props.UsersReducer.userToShow.idUsuario
            })
        }
        if(this.props.view === ADMINISTRADOR){
            console.log('calling teachers in UNSAFE_componentWillMount')
            this.props.getTeachersByCourseRequest({
                jwtToken: this.props.LoginReducer.sessionJWTToken,
                courseId: this.props.curso.idCurso
            })
        }
        this.buildSchedule()
    }
    //--------------------------------------------------------------------------------------------------------------------------------------------
    //Add lessons to the schedule
    buildSchedule= () =>{
        console.log('Building Schedule for course id :' + this.props.curso.idCurso)
        let vencimiento = this.props.curso.vencimiento
        let bloqueo = this.props.curso.bloqueo
        let cursoFinalizado = this.props.curso.cursoFinalizado
        let hoursAssigned = 0
        let auxSchedule =
        [   
            [false,false,false,false,false,false,false],
            [false,false,false,false,false,false,false],
            [false,false,false,false,false,false,false],
            [false,false,false,false,false,false,false],
            [false,false,false,false,false,false,false],
            [false,false,false,false,false,false,false],
        ]
        if(this.props.curso.clases !== undefined){
            this.props.curso.clases.forEach(classWeek => {
                console.log(classWeek)
                let row 
                let idDia = classWeek.diaClase.idDia
                let idHorario = classWeek.horarioClase.idHorarioClase
                if(idDia > 5){
                    if(idHorario === 7){
                        row = idHorario-7    
                    }else{
                        row = idHorario-5
                    }
                }else{
                    row = idHorario-1
                }
                auxSchedule[ row ][ (idDia-1) ]  =  {   
                                                        idCourse: this.props.curso.idCurso,
                                                        idClase:classWeek.idClase,
                                                        instructor:classWeek.instructor,
                                                        registred:classWeek.registred,
                                                        availability:classWeek.availability,
                                                        classHours:parseInt(classWeek.horarioClase.duracion)
                                                    };
                hoursAssigned+=classWeek.registred?parseInt(classWeek.horarioClase.duracion):0
            })
        }
        this.setState({ schedule:auxSchedule,
                        hoursAssigned,
                        vencimiento,
                        bloqueo,
                        cursoFinalizado
                    })
    }
    
    
    //--------------------------------------------------------------------------------------------------------------------------------------------
    //Ok option when you're an student user type
    onOk = (detailsClass,hoursAssigned) => { 
        this.props.okFunction(detailsClass,hoursAssigned);
    }
    //--------------------------------------------------------------------------------------------------------------------------------------------
    //Ok option when you're an admin user type
    deleteClass = (idCol,detailsClass) =>{
        console.log('this class will be delete: ')
        console.log(idCol)
        console.log(detailsClass)
        this.props.deleteClassRequest({
            jwtToken: this.props.LoginReducer.sessionJWTToken,
            idClass: detailsClass.idClase
        })
    }
    addClass = (dia,horario) =>{
        let curso= this.props.curso
        let idDia= dia+1
        let idHorarioClase= (idDia<6)?(horario+1):(
                                (horario===0)?(7):(8)
                            )
        console.log('this class will be addded to course: '+curso.idCurso)
        console.log(idDia)
        console.log(idHorarioClase)
        this.props.addNewCourseClass({
            jwtToken: this.props.LoginReducer.sessionJWTToken,
            curso,
            idDia,
            idHorarioClase
        })
    }
    onClickMenuTeachers = (e, idClass) => {
        console.log('sending request to add teacher to a class')
        this.props.addTeacherToClassRequest({
            jwtToken: this.props.LoginReducer.sessionJWTToken,
            idClass,
            idUsuario:e.key
        })
    }
    //--------------------------------------------------------------------------------------------------------------------------------------------
    //Show all available teachers
    teachersList(detailsClass){
        if(this.props.TeacherReducer.teachers !== undefined && this.props.TeacherReducer.teachers.length > 0){
            return <Menu 
                    style={{ width: 256 }}
                    onClick={  e => (e.key==='item1' ) ? (this.props.openAssistanceFunction(detailsClass)) : null } >
                        

                            <Menu.Item key="item1" /*icon={<CalendarOutlined />}*/>
                                ADMINISTRAR CLASE
                            </Menu.Item>
                            <Menu.Divider />
                            <SubMenu key="sub1" /*icon={<AppstoreOutlined />}*/ 
                            title="ASIGNAR INSTRUCTOR" s
                            onClick={ e => this.onClickMenuTeachers(e, detailsClass.idClase)} >
                                <Menu.ItemGroup title={"SELECCIONA UN INSTRUCTOR"}>
                                    {   (this.props.TeacherReducer.loading)
                                        ?<Menu.Item key="1" disabled={true}><Spin spinning={true}><h1> </h1></Spin></Menu.Item>
                                        :this.props.TeacherReducer.teachers.map(teacher => 
                                            <Menu.Item key={teacher.idUsuario}  > <UserOutlined />{(teacher.apellido === null) ? (teacher.nombre) : (teacher.nombre + ' ' + teacher.apellido)}</Menu.Item>
                                        )

                                    }
                                </Menu.ItemGroup>
                            </SubMenu>
                        
                    </Menu>
        }else{
            return  <Menu 
                    style={{ width: 256 }}
                    onClick={  e => (e.key==='item1' ) ? (this.props.openAssistanceFunction(detailsClass)) : null } >
                        

                            <Menu.Item key="item1" /*icon={<CalendarOutlined />}*/>
                                ADMINISTRAR CLASE
                            </Menu.Item>
                            <Menu.Divider />
                            <SubMenu key="sub1" /*icon={<AppstoreOutlined />}*/ 
                            title="ASIGNAR INSTRUCTOR" >
                                <Menu.ItemGroup title={"SELECCIONA UN INSTRUCTOR"} >
                                    {(this.props.TeacherReducer.loading)
                                        ? <Menu.Item key="1" disabled={true}><Spin spinning={true}><h1> </h1></Spin></Menu.Item>
                                        : <Menu.Item key="1">No hay maestros disponibles</Menu.Item>
                                    }
                                    
                                </Menu.ItemGroup>
                            </SubMenu>
                        
                    </Menu>
        }
    }
    //--------------------------------------------------------------------------------------------------------------------------------------------
    //Active lesson box from monday to thursday
    activeMidweekLesson(idCol,detailsClass) {
        let midweekLesson = <Col span={3} className='scheduleItem' key={idCol+1} style={{backgroundColor:this.props.curso.etiqueta}} 
                                onClick={ this.props.view === INSTRUCTOR ? ()=>this.onOk(detailsClass,this.state.hoursAssigned) :null}> 
                                        <Row>
                                            <Tooltip placement="bottom" title={'DISPONIBILIDAD: '+detailsClass.availability+' ESPACIOS'}>
                                            {detailsClass.availability<=1?
                                                <Avatar size={20} style={{ float: 'left', marginLeft:'.25em', marginTop:'.10em', fontSize: '1em', backgroundColor:"red" }}>{detailsClass.availability}</Avatar>
                                            :   detailsClass.availability <= 3
                                                ? <Avatar size={20} style={{ float: 'left', marginLeft:'.25em', marginTop:'.10em', fontSize: '1em', backgroundColor:'yellow', color: 'black' }}>{detailsClass.availability}</Avatar>
                                                : <Avatar size={20} style={{ float: 'left', marginLeft:'.25em', marginTop:'.10em', fontSize: '1em', backgroundColor:'#52c41a'  }}>{detailsClass.availability}</Avatar>
                                            }
                                            </Tooltip>
                                            {(detailsClass.registred)?(
                                                <Tooltip placement="bottom" title={'REGISTRADO'}>
                                                    <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: '1.25em' , marginRight:'.15em' , marginTop:'.15em', float: 'right' }} />
                                                </Tooltip>
                                            ):(null)}
                                        </Row>
                                    {(detailsClass.instructor)?(
                                        <div>
                                            <Row>
                                                <Tooltip placement="bottom" title={(detailsClass.instructor.apellido === "" || detailsClass.instructor.apellido === null)
                                                                                        ?'IMPARTIDA POR: '+(detailsClass.instructor.nombre).toUpperCase()
                                                                                        :'IMPARTIDA POR: '+(detailsClass.instructor.nombre+' '+detailsClass.instructor.apellido).toUpperCase()}>
                                                {
                                                    (detailsClass.instructor.fotoUsuario===null || detailsClass.instructor.fotoUsuario==="" ||detailsClass.instructor.fotoUsuario=== undefined )
                                                        ?<Avatar size={36} icon={<UserOutlined />}/>
                                                        :<Avatar size={36} src={`data:image/jpeg;base64,${detailsClass.instructor.fotoUsuario.archivo}`}/>
                                                }
                                                </Tooltip>
                                            </Row>
                                        </div>
                                    ):(null)} 
                                    
                            </Col>
        if(this.props.view === USUARIO || this.props.view === INSTRUCTOR){
            return  this.props.view === USUARIO 
                    ?
                    <Popconfirm
                     title     = { (detailsClass.registred)?(this.props.unenrollToClassMsg):(this.props.enrollToClassMsg) } 
                     icon      = { <Icon type={ (detailsClass.registred)?(CLOSE_ICON):(CHECK_ICON) } style={{ color: (detailsClass.registred)?(CLOSE_COLOR):(CHECK_COLOR)  }}/> }
                     onConfirm = { ()=>this.onOk(detailsClass,this.state.hoursAssigned)} >
                        { midweekLesson }
                    </Popconfirm>
                    :
                        midweekLesson
        }
        else if(this.props.view === ADMINISTRADOR){

            return  <Popconfirm 
                     title = { DELETE_CLASS }
                     icon  ={ <Icon type={CLOSE_ICON} style={{ color: CLOSE_COLOR }}/> }
                     onConfirm = {()=>this.deleteClass(idCol,detailsClass)}>
                        <Dropdown overlay={()=>this.teachersList(detailsClass)} trigger={['contextMenu']}>
                            { midweekLesson }
                        </Dropdown>
                    </Popconfirm>;
        }
    }
    //--------------------------------------------------------------------------------------------------------------------------------------------
    //Desactive lesson box from monday to thursday
    deactivatedMidweekLesson(idCol,idRow) {
        if(this.props.view === USUARIO || this.props.view === INSTRUCTOR){
            return  <Col span={3} className='scheduleItem' key={idCol+1} style={{cursor:'default'}}/>
        }
        else if(this.props.view === ADMINISTRADOR){
            return  <Popconfirm 
                     title = { ADD_CLASS }
                     icon  = { <Icon type={CHECK_ICON} style={{ color: CHECK_COLOR }}/> }
                     onConfirm = {()=>this.addClass(idCol,idRow)}>
                        <Col span={3} className='scheduleItem' key={idCol+1} ></Col>
                    </Popconfirm>
        }
    }
    //--------------------------------------------------------------------------------------------------------------------------------------------
    //Free lesson box just for friday's
    midweekFreeLesson(idCol,idRow) {
            return  <Col span={3} className='scheduleItem' key={idCol+1} style={{cursor:'default', backgroundColor:this.props.curso.etiqueta}}>
                        <Tooltip placement="bottom" title={'PRACTICA LIBRE'}>
                                <Avatar 
                                    size={40} 
                                    src={require('../../images/free-day.png')}
                                    style={{ backgroundColor: 'rgba(0, 0, 0, 1)', marginTop:'.45em' }} 
                                />
                        </Tooltip>
                    </Col>
    }
    //--------------------------------------------------------------------------------------------------------------------------------------------
    //Active lesson box for saturdays and sundays
    activeWeekendLesson(idCol,margin,detailsClass) {
        const weekendLesson =   <Row className='scheduleTimeWeekEnd' style={{marginTop:margin,backgroundColor:this.props.curso.etiqueta}}
                                    onClick={ this.props.view === INSTRUCTOR ? ()=>this.onOk(detailsClass,this.state.hoursAssigned) :null}>
                                        <Row>
                                            <Tooltip placement="bottom" title={'DISPONIBILIDAD: '+detailsClass.availability+' ESPACIOS'}>
                                            {detailsClass.availability<=1?
                                                <Avatar size={25} style={{ float: 'left', marginLeft:'.25em', marginTop:'.20em', fontSize: '1.15em', backgroundColor:"red" }}>{detailsClass.availability}</Avatar>
                                            :   detailsClass.availability <= 3
                                                ? <Avatar size={25} style={{ float: 'left', marginLeft:'.25em', marginTop:'.20em', fontSize: '1.15em', backgroundColor:'yellow', color: 'black' }}>{detailsClass.availability}</Avatar>
                                                : <Avatar size={25} style={{ float: 'left', marginLeft:'.25em', marginTop:'.20em', fontSize: '1.15em', backgroundColor:'#52c41a' }}>{detailsClass.availability}</Avatar>
                                            }
                                            </Tooltip>
                                            {(detailsClass.registred)?(
                                                <Tooltip placement="bottom" title={'REGISTRADO'}>
                                                    <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: '1.40em' , marginRight:'.15em' , marginTop:'.30em', float: 'right' }} />
                                                </Tooltip>
                                            ):(null)}
                                        </Row>
                                        {(detailsClass.instructor)?(
                                        <div>
                                            <Row>
                                                <Tooltip placement="bottom" title={(detailsClass.instructor.apellido === "" || detailsClass.instructor.apellido === null)
                                                                                    ?'IMPARTIDA POR: '+(detailsClass.instructor.nombre).toUpperCase()
                                                                                    :'IMPARTIDA POR: '+(detailsClass.instructor.nombre+' '+detailsClass.instructor.apellido).toUpperCase()}>
                                                {
                                                    (detailsClass.instructor.fotoUsuario===null || detailsClass.instructor.fotoUsuario===""||detailsClass.instructor.fotoUsuario===undefined)
                                                        ?<Avatar size={50} style={{ marginTop:'1em'}} icon={<UserOutlined />}/>
                                                        :<Avatar size={50} style={{ marginTop:'1em'}} src={`data:image/jpeg;base64,${detailsClass.instructor.fotoUsuario.archivo}`}/>
                                                }
                                                </Tooltip>
                                            </Row>
                                        </div>
                                    ):(null)}
                                </Row>
        if(this.props.view === USUARIO || this.props.view === INSTRUCTOR){
            return  this.props.view === USUARIO 
                    ?
                    <Col span={3} className='scheduleItemWeekEnd' key={idCol+1} >
                        <Popconfirm 
                         title={ (detailsClass.registred)?(this.props.unenrollToClassMsg):(this.props.enrollToClassMsg) } 
                         icon={ <Icon type={ (detailsClass.registred)?(CLOSE_ICON):(CHECK_ICON) } style={{ color: (detailsClass.registred)?(CLOSE_COLOR):(CHECK_COLOR)  }}/> }
                         onConfirm = { ()=>this.onOk(detailsClass,this.state.hoursAssigned) }>
                            {weekendLesson}
                        </Popconfirm>
                    </Col>
                    :
                    <Col span={3} className='scheduleItemWeekEnd' key={idCol+1} >
                        {weekendLesson}
                    </Col>
        }
        else if(this.props.view === ADMINISTRADOR){
            return  <Col span={3} className='scheduleItemWeekEnd' key={idCol+1} >
                        <Popconfirm 
                         title = { DELETE_CLASS }
                         icon  = { <Icon type={CLOSE_ICON} style={{ color: CLOSE_COLOR }}/> }
                         onConfirm = {()=>this.deleteClass(idCol,detailsClass)}>
                            <Dropdown overlay={this.teachersList(detailsClass)} trigger={['contextMenu']}>
                                {weekendLesson}
                            </Dropdown>
                        </Popconfirm>
                    </Col>
                    
        }
    }
    //--------------------------------------------------------------------------------------------------------------------------------------------
    //Desactive lesson box for saturdays and sundays
    deactivatedWeekendLesson(idCol,idRow,margin) {
        if(this.props.view === USUARIO || this.props.view === INSTRUCTOR){
            return  <Col span={3} className='scheduleItemWeekEnd' key={idCol+1} >
                        <Row className='scheduleTimeWeekEnd' style={{marginTop:margin,cursor:'default'}}/>
                    </Col>  
        }
        else if(this.props.view === ADMINISTRADOR){
            return  <Col span={3} className='scheduleItemWeekEnd' key={idCol+1} >
                        <Popconfirm 
                         title = { ADD_CLASS }
                         icon  = { <Icon type={CHECK_ICON} style={{ color: CHECK_COLOR }}/> }
                         onConfirm = {()=>this.addClass(idCol,idRow)}>
                            <Row className='scheduleTimeWeekEnd' style={{marginTop:margin}}/>
                        </Popconfirm>
                    </Col>
        }
    }

    showCourseToleranceModal = () => {
        this.setState({
          courseToleranceVisible: true
        });
    };

    handleOkOnCourseToleranceModal = async (newDueDate) => {
        console.log("new due date: " + newDueDate)
        await this.props.setNewDueDateCourseForStudent({
            jwtToken: this.props.LoginReducer.sessionJWTToken,
            courseId: this.props.curso.idCurso,
            studentId: this.props.UsersReducer.userToShow.idUsuario,
            newDueDate
        })
        this.setState({
            courseToleranceVisible: this.props.UsersReducer.dueDateModalVisibility
        })
    };

    confirmOkOnCourseToleranceModal = (t) => {
        var newDueDateString = t.calculateNewDueDate(t.props.curso.tolerancia, t.state.extraDays)
        confirm({
            title: '¿Seguro que deseas activar el tiempo de tolerancia para este alumno?',
            content: 'La nueva fecha de vencimiento sera el ' + newDueDateString,
            onOk() {
                console.log("extra days: " + t.state.extraDays);
                console.log("tolerancia: " + t.props.curso.tolerancia);
                t.handleOkOnCourseToleranceModal(newDueDateString)
            },
            onCancel() {
              console.log('Cancel');
            },
          });
    };

    calculateNewDueDate = (toleranciaInMonths, extraToleranciaDays) => {
        var dueDate = new Date();
        dueDate.setMonth(dueDate.getMonth()+toleranciaInMonths);
        dueDate.setDate(dueDate.getDate() + extraToleranciaDays);
        return dueDate.toLocaleDateString('en-CA');
    }
    
    handleCancelOnCourseToleranceModal = e => {
        console.log(e);
        this.setState({
            courseToleranceVisible: false,
            extraDays: 0
        });
    };

    onChangeExtraDays = value => {
        console.log('Extra days changed to:', value);
        this.setState({
            extraDays: value,
        });
    }
    //--------------------------------------------------------------------------------------------------------------------------------------------
    render(){
        return(
            <div>
                {(this.state.bloqueo === true && this.state.cursoFinalizado === false) 
                    ? 
                        <div style={{backgroundColor:"white"}}>
                            <Row>
                                <Empty  image       = {require('../../images/UsuarioDenegado.png')}
                                        imageStyle  = {{height: 60}}
                                        description = {<span><b>Usuario Bloqueado</b><br/>Tu fecha limite para finalizar este curso fue el {this.state.vencimiento}</span>}
                                >
                                    {
                                        (this.props.LoginReducer.sessionInformation.user.userType === 1)
                                        ?
                                        <Row justify="space-evenly">
                                            <Col span={12}>
                                                <Button type="primary" onClick={this.showCourseToleranceModal}>Activar Tolerancia</Button>
                                            </Col>
                                            <Col span={12}>
                                                <FinishCourseBtn estudiante={this.props.UsersReducer.userToShow} curso={this.props.curso}/>
                                            </Col>
                                                <Modal
                                                    title="Activar tolerancia del curso"
                                                    visible={this.state.courseToleranceVisible}
                                                    onOk={() => this.confirmOkOnCourseToleranceModal(this)}
                                                    okText="Activar tolerancia"
                                                    onCancel={!this.props.UsersReducer.loadingDueDateModal?this.handleCancelOnCourseToleranceModal:null}
                                                    cancelText="Cancelar"
                                                    closable={false}
                                                >
                                                    <Spin spinning={this.props.UsersReducer.loadingDueDateModal === true}>
                                                        <b><p>El curso "{this.props.curso.nombre}" cuenta con un tiempo de tolerancia de {this.props.curso.tolerancia} {(this.props.curso.tolerancia === 1)?"mes":"meses"}</p></b>
                                                        <p>Dias extras de tolerancia : <InputNumber min={0} max={15} defaultValue={0} value={this.state.extraDays} onChange={this.onChangeExtraDays}/></p>
                                                    </Spin>
                                                </Modal>
                                        </Row>  
                                        :null
                                    }
                                </Empty>    
                            </Row> 
                        </div> 
                    : 
                        (this.state.cursoFinalizado === true)
                            ?
                                <CourseCompleteLayer/>
                            :
                            <div>
                                {
                                    (this.props.userType === 3)
                                    ?
                                        <div>
                                            <Tag style={{marginBottom: '10px'}} color={this.props.curso.etiqueta}>
                                                {
                                                    (this.state.vencimiento === null) 
                                                    ? 'ASISTE A TU PRIMERA CLASE!' 
                                                    : 'Vencimiento de tu curso: ' + this.state.vencimiento
                                                }
                                            </Tag>
                                            <br/>
                                        </div>
                                    :
                                    null
                                }
                                <div className='scheduleLayer'>
                                    <AssistanceManagement/>
                                    <Row>
                                        <Col span={3} className='scheduleHeader' style={{backgroundColor:this.props.curso.etiqueta}} />
                                        {this.state.days.map((day,id)=>
                                            <Col span={3} className='scheduleHeader' style={{backgroundColor:this.props.curso.etiqueta}} key={id+1} >{day}</Col>
                                        )}
                                    </Row>

                                    {this.state.times.map((hour,idRow)=>
                                        <Row key={idRow+1} >
                                            <Col span={3} className='scheduleItemHour' style={{backgroundColor:this.props.curso.etiqueta}} >{hour.text}</Col>
                                            {this.state.days.map((day,idCol)=>
                                                    ((idCol+1) === 6 || (idCol+1) === 7)?(
                                                        this.state.weekendTimes.map((weekendTime,id)=>
                                                            (weekendTime === hour.time)?(
                                                                ((this.state.schedule[idRow][idCol]))?(
                                                                    this.activeWeekendLesson(idCol,'0px',(this.state.schedule[idRow][idCol]))
                                                                ):(
                                                                    this.deactivatedWeekendLesson(idCol,idRow,'0px')
                                                                )
                                                            ):(  
                                                                (weekendTime === (hour.time+1))?(
                                                                    ((this.state.schedule[idRow][idCol]))?(
                                                                        this.activeWeekendLesson(idCol,'30px',(this.state.schedule[idRow][idCol]))
                                                                    ):(
                                                                        this.deactivatedWeekendLesson(idCol,idRow,'30px')
                                                                    )
                                                                ):(
                                                                    null
                                                                )
                                                            )
                                                        )
                                                    ):(
                                                        ((idCol+1) === 5)
                                                        ?(
                                                            this.midweekFreeLesson(idCol)
                                                        )
                                                        :(
                                                            ((this.state.schedule[idRow][idCol]))?(
                                                                this.activeMidweekLesson(idCol,(this.state.schedule[idRow][idCol]))
                                                            ):(
                                                                this.deactivatedMidweekLesson(idCol,idRow)
                                                            )
                                                        )
                                                        
                                                    )
                                            )}
                                        </Row>
                                    )}
                                </div>
                            </div>
                }
            </div>
        )
    }
}
const mapStateToProps=(state)=>{
    return state
}
const mapDispatchToProps = {
    ...TeacherActions,
    ...UsersActions,
    ...CoursesActions
};
export default connect (mapStateToProps,mapDispatchToProps)(WeeklySchedule);