import React,{Component} from 'react'
import { connect } from 'react-redux';
import CoursesTable from '../../components/table/InfoTable'
import NewCourse from '../../components/courses/NewCourse'
import * as CoursesActions from '../../redux/actions/CoursesActions'
import { Icon,Input,Button } from 'antd'
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import CourseDetailsModal from '../../components/courses/CourseDetailsModal'
import '../layers.css'



class CoursesLayer extends Component{

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
    };
    
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    constructor(props){
        let getColumnSearchProps = dataIndex => ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
              <div style={{ padding: 8 }}>
                <Input
                  ref={node => {
                    this.searchInput = node;
                  }}
                  placeholder={`Buscar por ${dataIndex}`}
                  value={selectedKeys[0]}
                  onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                  onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                  style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                
                  <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                  >
                    Buscar
                  </Button>
                  <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Limpiar
                  </Button>
                
              </div>
            ),
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilter: (value, record) =>
                record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: visible => {
              if (visible) {
                setTimeout(() => this.searchInput.select());
              }
            },
            render: text =>
              this.state.searchedColumn === dataIndex ? (
                <Highlighter
                  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                  searchWords={[this.state.searchText]}
                  autoEscape
                  textToHighlight={text.toString()}
                />
              ) : (
                text
              ),
        });

        super(props)
        this.state = {
            searchText: '',
            searchedColumn: '',
        };
        this.coursesColumns = [
            {title: 'NOMBRE', dataIndex: 'nombre',key: 'nombre',...getColumnSearchProps('nombre')},
            {title: 'CUPO POR CLASE', dataIndex: 'cupo',key: 'cupo'},
            {title: 'ETIQUETA',key: 3, 
                render: (text, record) => (
                    <Icon 
                        type="tag" 
                        theme="twoTone" 
                        twoToneColor={record.etiqueta} 
                        style={{fontSize:30}} 
                    />
                ), 
            },
            {title: '',key: 'OPC',
                render: (text, record) => (
                    <span>
                        <a href='# ' key={record.idUsuario} onClick={()=>this.showDetails(record)}>Detalles</a>
                    </span>
                ),
            },
        ];
    }
    showDetails = (record) => {
        console.log('Course details')
        console.log(record)
        this.props.courseDetails({
            showDetails : true,
            courseDetails : record
        })
        if(this.props.LoginReducer.sessionInformation.user.userType === 1){
            console.log('retrieving course information {course id: '+record.idCurso+'}.....')
            this.props.getCourseRequest({
                jwtToken: this.props.LoginReducer.sessionJWTToken,
                courseId: record.idCurso
            })
        }
        else if(this.props.LoginReducer.sessionInformation.user.userType === 3){
            console.log('retrieving student course information {course id: '+record.idCurso+'}.....')
            this.props.getStudentCourseRequest({
                jwtToken: this.props.LoginReducer.sessionJWTToken,
                studentId: this.props.LoginReducer.sessionInformation.user.userId,
                courseId: record.idCurso
            })
        }
    }

    coursesData = () => {
        console.log('send request to retrieve all courses: ' + this.props.LoginReducer.sessionJWTToken)
        this.props.getCoursesRequest({
            jwtToken: this.props.LoginReducer.sessionJWTToken,
            userType: this.props.LoginReducer.sessionInformation.user.userType,
            userId: this.props.LoginReducer.sessionInformation.user.userId,
        })
    }
    UNSAFE_componentWillMount(){
        //this.coursesData()
    }
    componentDidUpdate(){
        if(this.props.CoursesReducer.updatedData){
            this.coursesData()
        }
    }
    
    render(){
        return(
            <div className='layer'>
                <CourseDetailsModal />

                {
                    this.props.LoginReducer.sessionInformation.user.userType===1
                    ?
                        <div>
                            <h1>Cursos</h1>
                            <div className='background-new-user-btn'>
                                <div className='new-user-btn'>
                                    <NewCourse loadingFlag = {this.props.CoursesReducer.loading}/>
                                </div>
                            </div>
                        </div>
                    : 
                        <h1>Mis Cursos</h1>
                }

                <div className='users-table'>
                    <CoursesTable
                        columns={this.coursesColumns} 
                        dataSource={this.props.CoursesReducer.courses} 
                        rowKey = "idCurso"
                        mountFunction={(this.props.CoursesReducer.courses!==undefined)
                                        ?(()=>{})
                                        :this.coursesData} 
                        refreshFunction={this.coursesData} 
                        loadingFlag = {this.props.CoursesReducer.loading}
                    />
                </div> 
            </div>  
        )
    }
}
const mapStateToProps=(state)=>{
    return state
}
const mapDispatchToProps = {
    ...CoursesActions
};
export default connect(mapStateToProps,mapDispatchToProps)(CoursesLayer);