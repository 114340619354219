import React,{ Component } from 'react'
import { Tabs, Row, Badge } from 'antd'
import { connect } from 'react-redux';
import NotificationList from '../../components/notifications/NotificationList'
import * as NotificationsActions from '../../redux/actions/NotificationsActions'

const { TabPane } = Tabs;

class NotificationsLayer extends Component{
    render(){
        return(
            <div>
                <Row>
                <h1 style={{float:"center"}}>Notificaciones</h1>
                </Row>
    
                <Row>
                    <Tabs defaultActiveKey="1" tabPosition="left" style={{ margin:"5px" }}>
                        
                        <TabPane tab={<span><Badge count={this.props.NotificationsReducer.unviewedNotificationsSize} style={{marginRight:"10px"}} showZero/>NUEVAS</span>} key="1" style={{padding:"5px", alignContent:"center"}} >
                            <NotificationList viewedNotifications={false}/>
                        </TabPane>
            
                        <TabPane tab="HISTORIAL" key="2">
                            <NotificationList viewedNotifications={true}/>    
                        </TabPane>

                    </Tabs>
                </Row>
            </div>
        )
    }
}
const mapStateToProps=(state)=>{
    return state
}
export default connect(mapStateToProps, NotificationsActions)(NotificationsLayer);


