import React,{ Component } from 'react'
import {Empty} from 'antd';

class CourseCompleteLayer extends Component{
    render(){
        return(
            <Empty  image = {require('../../images/CursoCompleto.png')}
                    imageStyle  = {{height: 60}}
                    description = {<span><b>CURSO FINALIZADO</b><br/>Felicidades, haz logrado completar el 100% del contenido de este curso!</span>}>
        
            </Empty>  
        )
    }
}
export default CourseCompleteLayer;