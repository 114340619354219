import React,{ Component } from 'react'
import { Carousel, Row } from 'antd';
import './Carousel.css' 

class HomeCarousel extends Component{

    render(){
        return(
            <Carousel>
                <div>
                    <Row>
                        <Row type='flex' justify="center" align="top" >  
                            <img src={require('../../images/axss.png')} alt='Axss' /> 
                        </Row>
                    </Row>
                </div>
            </Carousel>
        )
    }
}
export default HomeCarousel;