import  axios from 'axios';
import  {SERVER_URL,
        PATH_USER,
        PATH_PWD,
        LOGIN_URL,
        UPDATE,
        PASSWORD,
        REQUEST
        } from '../../utility/AppConstants'
import decode from 'jwt-decode';
import { message } from  'antd'

export function loginRequest(payload){
    const LOGIN = SERVER_URL+LOGIN_URL;
    return( dispatch=>{
        dispatch(setRequestInProgress ({requestInProgress:true}))
        return  axios.post( LOGIN, 
                            {
                                username: payload.username,
                                password: payload.password
                            },
                            {
                                headers:{
                                    'Content-Type': 'application/json',
                                    'module': payload.module
                                }  
                            }
        ).then( response=>{
            console.log( 'Status Code: ' + response.status )
            if(response.status === 200){
                dispatch(setRequestInProgress ({requestInProgress:false}))
                console.log( 'Success response -> ' + LOGIN )
                console.log(response.data)
                dispatch(
                    loginResponse({
                        successLogin : true,
                        sessionJWTToken : response.headers['authorization'],
                        sessionModule : payload.module, 
                        loadingLogin : false
                    })
                )
                const token = decode(response.headers['authorization']);
                dispatch(
                    setUserInSession({
                        user:{
                            userType:token.UserType,
                            userId:token.IdUser,
                            username:token.Username,
                            intensiveMode:token.IntensiveMode,
                            resetPwd: token.ResetPwd, 
                            userEnable: token.UserEnable
                        }
                    })
                )
            }
        }).catch( error => {
            console.error('LOGIN FAILURE')
            dispatch(setRequestInProgress ({requestInProgress:false}))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if(error.response.status === 401){
                    message.error('El username o la contraseña no son validos, vuelva a intentar!');
                }else{
                    message.error('Ha ocurrido un error interno, vualva a intentar mas tarde!');
                }
            }else{
                message.error('Servicio inhabilitado, contacte a soporte!');
            }
            dispatch(
                loginResponse({
                    successLogin : false,
                    sessionJWTToken : '',
                    sessionModule : '', 
                    loadingLogin:false
                })
            )
        })
    })
    
}
export function setRequestInProgress(payload){
    return{
        type:'SET_REQUEST_IN_PROGRESS',
        payload
    }
}
export function updatePwdRequest(payload){
    const UPDATE_PWD_REQUEST = SERVER_URL+PATH_USER+UPDATE+PASSWORD;
    return( dispatch=>{
        dispatch(setLoading({loadingLogin:true}))
        return  axios.post( UPDATE_PWD_REQUEST, 
                            {
                                idUsuario: payload.idUsuario,
                                password: payload.password
                            },
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }  
                            }
        ).then( response=>{
            dispatch(setLoading({loadingLogin:false}))
            console.log( 'Status Code: ' + response.status )
            if(response.status === 200){
                console.log( 'Success response -> ' + UPDATE_PWD_REQUEST )
                dispatch(pwdUpdated())
            }
        }).catch( error => {
            console.error('PWD UPDATE HAS FAILURE')
            dispatch(setLoading({loadingLogin:false}))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                message.error('Ha ocurrido un error interno, vualva a intentar mas tarde! Codigo de error('+error.response.status+')');
            }else{
                message.error('Servicio inhabilitado, contacte a soporte!');
            }
        })
    })
    
}
export function pwdChgRequest(payload){
    const PWD_CHG_REQUEST = SERVER_URL+PATH_PWD+REQUEST;
    return( dispatch=>{
        dispatch(setLoadingCr({loadingCh:true}))
        return  axios.post( PWD_CHG_REQUEST, 
                            {
                                username: payload.username
                            }
        ).then( response=>{
            dispatch(setLoadingCr({loadingCh:false}))
            console.log( 'Status Code: ' + response.status )
            if(response.status === 200){
                console.log( 'Success response -> ' + PWD_CHG_REQUEST )
                console.log(response.data)
                message.success(response.data.details);
                dispatch(pwdChgReqModal({pwdChgReqVisible: false}))
            }else{
                message.warning(response.data.details);
            }
        }).catch( error => {
            console.error('PWD CHG-REQ HAS FAILURE')
            dispatch(setLoadingCr({loadingCh:false}))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                message.error(error.response.data.details+' ('+error.response.status+')');
            }else{
                message.error('Servicio inhabilitado, contacte a soporte!');
            }
        })
    })
    
}
export function changePwdRequest(payload){
    const CHANGE_PWD_REQUEST = SERVER_URL+PATH_PWD;
    return( dispatch=>{
        dispatch(setLoading({loadingLogin:true}))
        return  axios.post( CHANGE_PWD_REQUEST, 
                            {
                                idToken : payload.idToken,
                                token: payload.token,
                                user:{
                                    username: payload.username,
                                    password: payload.password
                                }
                            }
        ).then( response=>{
            dispatch(setLoading({loadingLogin:false}))
            console.log( 'Status Code: ' + response.status )
            if(response.status === 200){
                console.log( 'Success response -> ' + CHANGE_PWD_REQUEST )
                message.success(response.data.details);
                dispatch(pwdChanged({pwdChanged: true}))
            }else{
                message.warning(response.data.details);
            }
        }).catch( error => {
            console.error('CHG PWD HAS FAILURE')
            dispatch(setLoading({loadingLogin:false}))
            dispatch(pwdChanged({pwdChanged: false}))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                message.error(error.response.data.details+' ('+error.response.status+')');
            }else{
                message.error('Servicio inhabilitado, contacte a soporte!');
            }
        })
    })
    
}
export function loginResponse(payload){
    return{
        type:'LOGIN_RESPONSE',
        payload
    }
}
export function setLoading(payload){
    return{
        type:'SET_LOADING',
        payload
    }
}
export function setUserInSession(payload){
    return{
        type:'SET_USER_IN_SESSION',
        payload
    }
}
export function userLogOut(){
    return{
        type:'USER_LOGOUT'
    }
}
export function pwdUpdated(){
    return{
        type:'PWD_UPDATED'
    }
}
export function pwdChgReqModal(payload){
    return{
        type:'PWD_CHG_REQ_MODAL',
        payload
    }
}
export function pwdChanged(payload){
    return{
        type:'PWD_CHANGED',
        payload
    }
}
export function setLoadingCr(payload){
    return{
        type:'SET_LOADING_CR',
        payload
    }
}