import React,{Component} from 'react'
import {Form, Button, Col, Row, Input, Icon} from 'antd'
import { connect } from 'react-redux';
import * as CoursesActionsCreators from '../../redux/actions/CoursesActions'


class LessonForm extends Component{
    state = { 
        idCourse: this.props.idCourse,
        title:'',
        description:''
    }
    onOk = () => {
        this.props.form.validateFields(err => {
          if (!err) {
            console.log('sending new lesson request')
            this.props.addNewCourseLesson({
                idCurso: this.state.idCourse,
                tituloTema: this.state.title,
                descripcionTema: this.state.description,
                jwtToken: this.props.LoginReducer.sessionJWTToken
            })
          }
        });
        
    }
    onChange = (e) => {
        this.setState({
          [e.target.id]: e.target.value
        })
    }
    componentDidUpdate(){
        if(this.props.CoursesReducer.addedLesson){
            this.props.form.resetFields();
            this.setState({
                title:'',
                description:''
            })
            this.props.lessonAddedFlag({
                addedLesson:false
            })
        }
    }
    render(){
        const { getFieldDecorator } = this.props.form;
        return(
            <div>
                <Form layout="horizontal" hideRequiredMark>
                    <Row >
                        <h3 style={{float:'left'}}>TEMA NUEVO</h3>
                    </Row>
                    <Row gutter={16} type="flex" justify="start" align="middle">
                        <Col span={7}>
                            <Form.Item>
                                {getFieldDecorator('title', {
                                 rules: [{ required: true, message: 'Introduzca un titulo' }],
                                })(<Input id='title' placeholder="TITULO" maxLength={70} onChange={this.onChange} />)}
                            </Form.Item>
                        </Col>
                        <Col span={12} >
                            <Form.Item>
                                {getFieldDecorator('description', {
                                    rules: [{ required: false, message: '' }],
                                })(<Input id='description' placeholder="DESCRIPCION" maxLength={210} onChange={this.onChange} />)}
                            </Form.Item>
                        </Col>
                        <Col span={5} >
                            <Form.Item >
                                <Button type="primary" onClick={this.onOk} >
                                    <Icon type="plus" /> Agregar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }
}
const mapStateToProps=(state)=>{
    return state
}
export default connect (mapStateToProps,CoursesActionsCreators)(Form.create()(LessonForm));