import React,{Component} from 'react'
import { connect } from 'react-redux';
import NewPwdForm from '../../components/password/NewPwdForm'
const style = { 
    backgroundColor:'rgba(181,180,180,1)',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display:'flex',
    width: '100vw', /* viewport width */
    height:'100vh', /* viewport height */
    overflowY:'scroll',
    overflowX:'hidden',
}

class ResetPwdLayer extends Component{
    state={
        id:null,
        tk:null,
    }
    
    UNSAFE_componentWillMount(){
        const query = new URLSearchParams(this.props.location.search);
        this.setState({
            id:query.get('id'),
            tk:query.get('tk')
        })
        console.log(this.state)
    }
    
    render(){
        return(
            <div style={style} >
                <NewPwdForm {...this.props} id={this.state.id} tk={this.state.tk}/> 
            </div>
        )
    }
}
const mapStateToProps=(state)=>{
    return state
}
export default connect(mapStateToProps)(ResetPwdLayer);
