
const NotificationsReducer=(state={}, action)=>{
    if(action.type === 'RQ_IN_PROGRESS'){
        return{
            ...state,
            rqInProgress: action.payload.inProgress
        }
    }
    else if(action.type === 'RQ_IN_PROGRESS_FOR_COMPONENT'){
        return{
            ...state,
            rqInProgressForComponent: action.payload.inProgressForComponent
        }
    }
    else if(action.type === 'SET_NOTIFICATIONS'){
        return{
            ...state,
            notifications: action.payload.notifications
        }
    }
    else if(action.type === 'SET_SEEN_NOTIFICATIONS'){
        return{
            ...state,
            seenNotifications: action.payload.seenNotifications
        }
    }
    else if(action.type === 'SET_NOTIFICATION_CONFIRMED'){
        let notificationConfirmed = action.payload.notificationConfirmed

        let oldNotifications = {...state.notifications}
        let newNotifications = Object.values(oldNotifications).filter(notification => notification!==notificationConfirmed)
        console.log('.-.-.-.-')
        console.log(oldNotifications)
        console.log('.--.-.-.-')
        let oldSeenNotifications = {...state.seenNotifications}
        console.log('*******')
        console.log(notificationConfirmed)
        console.log('*******')
        let newSeenNotifications = Object.values(oldSeenNotifications).concat(notificationConfirmed)

        return{
            ...state,
            notifications: newNotifications,
            seenNotifications: newSeenNotifications,
            unviewedNotificationsSize: (notificationConfirmed.notiType === 1) ? state.unviewedNotificationsSize-1 : state.unviewedNotificationsSize
        }
    }
    else if(action.type === 'SET_VIEWED_NOTIFICATIONS_SIZE'){
        console.log("before if viewedNotificationsSize")
        console.log("action viewedNotificationsSize:" + action.payload.viewedNotificationsSize)
        console.log("state viewedNotificationsSize:" + state.viewedNotificationsSize)
        
        if(action.payload.viewedNotificationsSize !== state.viewedNotificationsSize){
            console.log("viewedNotificationsSize it's differente, then update components")
            return{
                ...state,
                viewedNotificationsSize: action.payload.viewedNotificationsSize
            }
        }
        return state
    }
    else if(action.type === 'SET_UNVIEWED_NOTIFICATIONS_SIZE'){
        console.log("before if")
        console.log("action:" + action.payload.unviewedNotificationsSize)
        console.log("state:" + state.unviewedNotificationsSize)
        
        if(action.payload.unviewedNotificationsSize !== state.unviewedNotificationsSize){
            console.log("it's differente, then update components")
            return{
                ...state,
                unviewedNotificationsSize: action.payload.unviewedNotificationsSize
            }
        }
        return state
    }
    else if(action.type === 'SET_UNVIEWED_NOTIFICATIONS_ACK_ADMIN'){
        return{
            ...state,
            unviewedNotificationsAckAdmin: action.payload.unviewedNotificationsAckAdmin
        }
    }
    else {
        return state
    }
}
export default NotificationsReducer;