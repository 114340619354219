import React,{Component} from 'react'
import {Spin,Row,Button,Table} from 'antd'
import { connect } from 'react-redux';
import * as UserActionsCreators from '../../redux/actions/UsersActions'
import * as SedeActionsCreators from '../../redux/actions/SedeActions'
import * as DrawerActionsCreators from '../../redux/actions/DrawerActions'

class UsersTable extends Component{
    UNSAFE_componentWillMount(){
        this.props.mountFunction()
    }
    componentDidUpdate(){
        if(this.props.rowKey === "idUsuario"){
            if(this.props.UsersReducer.updatedData){
                this.props.refreshFunction()
                this.props.setResetForm({resetForm: true})
                this.props.closeDrawer();
                this.props.setUpdatedData({
                    updatedData:false,
                    loading:false
                })
                
            }
        }
        else if(this.props.rowKey === "idSede"){
            if(this.props.SedeReducer.updatedData){
                this.props.setUpdatedDataSede({
                    updatedData:true,
                    loading:false
                })
                this.props.refreshFunction()
            }
        }
        else if(this.props.rowKey === "idCurso"){
            if(this.props.UsersReducer.updatedData){
                this.props.refreshFunction()
                this.props.setUpdatedData({
                    updatedData:false,
                    loading:false
                })
            }
        }
    
    }
    render(){
        return(
            <Spin spinning={this.props.loadingFlag === true}>
                <Row type='flex' justify='end'>
                    <Button style={{marginBottom:'10px'}} onClick={this.props.refreshFunction} type="primary" icon="reload" size='large' />
                </Row>
                <Table columns={this.props.columns} dataSource={this.props.dataSource} rowKey={this.props.rowKey}/>
            </Spin>
        )
    }
}
const mapStateToProps=(state)=>{
    return state
}
const mapDispatchToProps = {
    ...UserActionsCreators,
    ...SedeActionsCreators,
    ...DrawerActionsCreators
};
export default connect(mapStateToProps,mapDispatchToProps)(UsersTable);