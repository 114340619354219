import React,{Component} from 'react'
import LessonForm from './LessonForm'
import LessonsList from './LessonsList'
import { Spin, Tag } from 'antd';
import { connect } from 'react-redux';
import ProgressBar from '../progressbar/ProgressBar'

class LessonsContainer extends Component{
    render(){
        return(
            <div style={{padding:'15px 30px'}}>
                <Spin spinning={this.props.CoursesReducer.loading}>
                    {
                        this.props.LoginReducer.sessionInformation.user.userType===1 
                        ?
                            <LessonForm 
                                idCourse={this.props.idCourse}/>        
                        : this.props.LoginReducer.sessionInformation.user.userType===3
                            ?
                                <div>
                                    <ProgressBar progressType='CURSO' percent={this.props.CoursesReducer.courseDetails.progressPercentage} />
                                    <Tag style={{marginBottom: '10px'}} color={this.props.CoursesReducer.courseDetails.etiqueta}>{(this.props.CoursesReducer.courseDetails.duracion === 1) ? 'Duracion: 1 Mes' :  'Duracion: ' +this.props.CoursesReducer.courseDetails.duracion +' Meses' }</Tag>
                                </div>
                                
                            :null
                    }
                    
                    <LessonsList
                        data={this.props.CoursesReducer.courseDetails.temario}/>
                </Spin>
            </div>
        )
    }
}
const mapStateToProps=(state)=>{
    return state
}
export default connect(mapStateToProps)(LessonsContainer);