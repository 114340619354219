import React, { Component } from 'react';
import {Switch, Route, Redirect } from 'react-router-dom';
import decode from 'jwt-decode';
import { connect } from 'react-redux';

import * as LoginActions from './redux/actions/LoginActions';

import MenuBar from './components/navbar/MenuBar'
import MenuHeader from './components/navbar/MenuHeader'
import ChangePwd from './components/login/ChangePwd'
import AlertNotif from './components/notifications/AlertNotif'

import LoginLayer from './layers/login/index';
import ResetPwdLayer from './layers/reset-pwd/index';
import HomeLayer from './layers/home/index';
import StudentsLayer from './layers/students/index'
import AdministrationLayer from './layers/administration/index'
import CoursesLayer from './layers/courses/index'
import InvalidLayer from './layers/invalid/index';
import UnauthorizedLayer from './layers/unauthorized/index';
import NorificationsLayer from './layers/notifications/index';
import NotAvailable from './layers/not-available/index';
import { Layout, Spin } from 'antd';

import './App.css';

const { Content, Footer } = Layout;
const ADMIN_ROLE = "ROLE_ADMINISTRADOR"
let AuthRoute,roleInSession;

class App extends Component{
  state = {
    collapsed: true
  };

  onCollapse = (collapsed) => {
      this.setState({ collapsed });
  }
  toggle = () => {
      this.setState({
          collapsed: !this.state.collapsed
      });
  }
  layerApp = () => {

  }
  UNSAFE_componentWillMount(){
    const checkAuth =() =>  {
      const tokenAuth = this.props.LoginReducer.sessionJWTToken;
      if(!tokenAuth){
          console.warn('The JWT does not exists!');
          return false;
      }
      try {
        const token = decode(tokenAuth);
        console.log(token)
        console.log('Validating JWT....')
        if (token.exp < Date.now() / 1000){  
          console.warn('JWT has expired!')
          this.props.userLogOut()
          return false;
        }
        roleInSession = token.AuthoritiesKey
      } catch (e) {
        console.error('An exception has occurred while the JWT was validating!')
        return false;
      }
      console.log('Valid JWT!')
      return true;
    }
    const componentRoleValidation =(componentRole) =>  {
      if(componentRole === ADMIN_ROLE){
        return roleInSession === ADMIN_ROLE
      }
      return true
    }

    AuthRoute = ({ component: Component, ...rest }) => (
      <Route {...rest} render={props => (
          checkAuth() ? 
            componentRoleValidation(rest.role) ?
              <Layout style={{ minHeight: '100vh' }}>
                
                  <ChangePwd /> 

                  <MenuBar collapsed={this.state.collapsed} onCollapse={this.onCollapse} />
                  
                  <Layout>
                    <Spin spinning={this.props.NotificationsReducer.rqInProgress !== undefined && this.props.NotificationsReducer.rqInProgress === true}>
                    
                      <MenuHeader collapsed={this.state.collapsed} toggle={this.toggle}/>

                      <Content style={{ margin: '24px 16px', background: '#fff', minHeight: 280 }}>
                        {
                          (this.props.LoginReducer.sessionInformation!==undefined && Component !== NorificationsLayer) 
                            ? <AlertNotif/> 
                            :null
                        }
                        <Component {...props} />
                      </Content>

                      <Footer style={{ textAlign: 'center' }}>
                          Axss Music ©2018 Portal Created by Softyg Company
                      </Footer>
                    </Spin>
                  </Layout>
                
              </Layout>
            :(<UnauthorizedLayer/>)
          : <Redirect to = {{ pathname: '/login-admin' }} />    
        )} />
    )
  }
  render(){
    return(
      <div className="App">
        
        <Switch>
          <Route      path="/not-available" exact component={NotAvailable} />
          <Route      path="/login-admin" exact component={LoginLayer} />
          <Route      path="/reset-pwd" exact component={ResetPwdLayer} />
          <AuthRoute  role="ANY" path="/home"  exact component={HomeLayer}/> {/*this is authroute*/}
          <AuthRoute  role={ADMIN_ROLE} path="/admin/students"  exact component={StudentsLayer}/> {/*this is authroute*/}
          <AuthRoute  role={ADMIN_ROLE} path="/admin/administration"  exact component={AdministrationLayer}/> {/*this is authroute*/}
          <AuthRoute  role="ANY" path="/courses"  exact component={CoursesLayer}/> {/*this is authroute*/}
          <AuthRoute  role="ANY" path="/notifications"  exact component={NorificationsLayer}/> {/*this is authroute*/}
          <Redirect exact from="/" to="/login-admin"/> 
          <Route component={InvalidLayer} />
        </Switch>
        
      </div>
    )
  }
}
const mapStateToProps=(state)=>{
  return state
}
export default connect (mapStateToProps,LoginActions)(App);
