import React,{ Component } from 'react'
import { Progress, Row, Col, Tooltip } from 'antd';
import { connect } from 'react-redux';
import * as AssistsActions from '../../redux/actions/AssistsActions'
class ProgressBar extends Component{
    openStudenAssistenceDrawer = (student) =>{
        this.props.getStudentAssists({
          studentId:this.props.UsersReducer.userToShow.idUsuario,
          courseId: this.props.courseId,
          jwtToken: this.props.LoginReducer.sessionJWTToken,
          view:"UserDetails",
          lessons: this.props.lessons
        })
    }
    render(){
        return(
            <div>
                <Row>
                    <Col span={4}>
                        <h5><strong>PROGRESO DEL {this.props.progressType}</strong></h5>    
                    </Col>
                    <Col span={19} style={this.props.assistanceTracker === true?{cursor:'pointer'}:{}}>
                        {this.props.assistanceTracker === true ?
                            <Tooltip placement="top" title={'Ver Asistencias'} >
                                <strong onClick={()=>this.openStudenAssistenceDrawer(this.props.UsersReducer.userToShow)}>
                                    <Progress percent={this.props.percent} status={(this.props.percent >= 100)?('success'):('active')} />
                                </strong>
                            </Tooltip>
                        :
                            <strong>
                                <Progress percent={this.props.percent} status={(this.props.percent >= 100)?('success'):('active')} />
                            </strong>
                        }
                    </Col>
                </Row>
            </div>
        )
    }
}
const mapStateToProps=(state)=>{
    return state
}
export default connect(mapStateToProps,AssistsActions)(ProgressBar)