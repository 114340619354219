import React,{Component} from 'react'
import {Modal} from 'antd'
import { connect } from 'react-redux';
import * as CoursesActionsCreators from '../../redux/actions/CoursesActions'
import LessonsList from './../lessons/LessonsList'



class CourseLessons extends Component{
    handleCloseModal = () => {
        this.props.setShowCourseLessons({showCourseLessons:false})
        this.props.setCourseInformation({courseInformation:undefined})
    }
   
    render(){
        return(
            <div>
                {(this.props.course !== undefined)?(
                    <Modal
                    visible={this.props.CoursesReducer.showCourseLessons !== undefined ? this.props.CoursesReducer.showCourseLessons :false}
                    onCancel={this.handleCloseModal}
                    footer={false}
                    width={800}
                    >
                        {'Temario de ' + this.props.course.nombre }
                        <br/>
                        {'Duracion: '}
                        {(this.props.course.duracion === 1) ? '1 Mes' :  this.props.course.duracion +' Meses' }
                        <LessonsList data={this.props.course.lessons} justView={true}/>
                    </Modal>
                ):(null)}
            </div>
        )
    }

}
const mapStateToProps=(state)=>{
    return state
}
export default connect(mapStateToProps,CoursesActionsCreators)(CourseLessons);
 