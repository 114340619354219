import React, {Component} from 'react'
import { Result } from 'antd';

class NotAvailable extends Component{
    render(){
        return(
            <Result
                status="500"
                title="500"
                subTitle="Lo sentimos, la pagina no esta disponible!"
            />
        )
    }
}export default NotAvailable;