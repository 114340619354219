import React, {Component} from 'react'
import {Row, Col} from 'antd'
import CoursesPanelItem from './CoursesPanelItem'
import { connect } from 'react-redux';
import * as CoursesActions from '../../redux/actions/CoursesActions'
import './CoursesPanel.css'
import CourseLessons from './CourseLessons'

class CoursesPanel extends Component{
    UNSAFE_componentWillMount() {
        this.coursesData()
    }
    coursesData = () => {
        console.log('send request to retrieve all courses: ' + this.props.LoginReducer.sessionJWTToken)
        this.props.getAvailableCoursesRequest({
            jwtToken: this.props.LoginReducer.sessionJWTToken,
        })
    }
    render(){
        return(
            <Row type="flex" justify="start" align="top" className='courses-panel'>
                
                <div className='panel-title'>
                    Cursos Disponibles
                </div>

                <Col xs= {24} >
                    <Row type="flex" justify="center" align="top"  className='panel-row'>
                        
                        {
                            this.props.CoursesReducer.availableCourses !== undefined ?
                            (
                                this.props.CoursesReducer.availableCourses.map((course,index) => 
                                    <Col span={24}>
                                        <CoursesPanelItem course={course} index={index}/>
                                    </Col>
                                )
                            )
                            :(
                                <div>No hay cursos disponibles</div>
                            )
                        }
                        
                        

                    </Row>
                </Col>
                <CourseLessons course={this.props.CoursesReducer.courseInformation}/>

            </Row>
        )
    }
}
const mapStateToProps=(state)=>{
    return state
}
export default connect(mapStateToProps,CoursesActions)(CoursesPanel);
