import  axios from 'axios';
import  {SERVER_URL,
        USER,
        NOTIFICATIONS
        } from '../../utility/AppConstants'
import {message} from 'antd'

export function getNotificationsSize(payload){
    let userType = payload.userType
    let userId = payload.userId
    let viewed = payload.viewed

    console.log("VIEWED PARAM IS: " + viewed)    
    const GET_UNVIEWED_NOTIFICATIONS_SIZE_URL = buildUrlToGetNotificationsSize(userType, userId)

    return( dispatch=>{
        dispatch(requestInProgress({inProgress:true}))
        return axios.get(   GET_UNVIEWED_NOTIFICATIONS_SIZE_URL,
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                },
                                params:{
                                    viewed
                                }
                            }
        ).then( response => {
            console.log( 'Status Code: ' + response.status )
            if( response.status === 200 ){
                console.log( 'Success response -> ' + GET_UNVIEWED_NOTIFICATIONS_SIZE_URL )
                console.log(response.data)

                if(viewed){
                    dispatch(setViewedNotificationsSize({viewedNotificationsSize: response.data}))
                }else{
                    dispatch(setUnviewedNotificationsSize({unviewedNotificationsSize: response.data}))
                }
                dispatch(requestInProgress({inProgress:false}))
            }
        }).catch( error => {
            dispatch(requestInProgress({inProgress:false}))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + GET_UNVIEWED_NOTIFICATIONS_SIZE_URL )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + GET_UNVIEWED_NOTIFICATIONS_SIZE_URL )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + GET_UNVIEWED_NOTIFICATIONS_SIZE_URL )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + GET_UNVIEWED_NOTIFICATIONS_SIZE_URL )
                } 
            }else{
                console.error( 'Disabled API service -> ' + GET_UNVIEWED_NOTIFICATIONS_SIZE_URL )
            }
        })  
    })
}

function buildUrlToGetNotificationsSize(userType,userId){
    let unviewedNotificationsSizeUrl = SERVER_URL+NOTIFICATIONS;
    if(userType === 1){
        unviewedNotificationsSizeUrl += "admin"
    }
    else if(userType === 3){
        unviewedNotificationsSizeUrl += USER + userId
    }
    unviewedNotificationsSizeUrl += "/counter"

    return unviewedNotificationsSizeUrl
}

export function getUserNotificationsRequest(payload){
    let userType = payload.userType
    let userId = payload.userId
    let seen = payload.seen
    const GET_NOTIFICATIONS_URL = buildUrlToGetNotifications(userType, userId, seen)

    return( dispatch=>{
        dispatch(requestInProgressForComponent({inProgressForComponent:true}))
        return axios.get(   GET_NOTIFICATIONS_URL,
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }
                            }
        ).then( response => {
            console.log( 'Status Code: ' + response.status )
            if( response.status === 200 ){
                console.log( 'Success response -> ' + GET_NOTIFICATIONS_URL )
                console.log(response.data)
                if(seen === true){
                    dispatch(setSeenNotification({seenNotifications:response.data}))
                }else{
                    dispatch(setNotification({notifications:response.data}))
                }
                
                dispatch(requestInProgressForComponent({inProgressForComponent:false}))
            }
        }).catch( error => {
            if(seen === true){
                dispatch(setSeenNotification({seenNotifications:{}}))
            }else{
                dispatch(setNotification({notifications:{}}))
            }
            dispatch(requestInProgressForComponent({inProgressForComponent:false}))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + GET_NOTIFICATIONS_URL )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + GET_NOTIFICATIONS_URL )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + GET_NOTIFICATIONS_URL )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + GET_NOTIFICATIONS_URL )
                } 
            }else{
                console.error( 'Disabled API service -> ' + GET_NOTIFICATIONS_URL )
            }
        })  
    })
}

function buildUrlToGetNotifications(userType,userId,seen){
    let notificationsUrl = SERVER_URL+NOTIFICATIONS;
    if(userType === 1){
        notificationsUrl += "admin"
    }
    else if(userType === 3){
        notificationsUrl += USER + userId
    }
    notificationsUrl += (seen === true) ? "/viewed" : ""

    return notificationsUrl
}

export function confirmNotificationRequest(payload){
    let userType = payload.userType
    let notification = payload.notificacion
    let idNotif = notification.idNotif
    
    const GET_CONFIRM_NOTIFICATION_URL = buildUrlToConfirmNotification(userType,idNotif)

    return( dispatch=>{
        dispatch(requestInProgress({inProgress:true}))
        return axios.get(   GET_CONFIRM_NOTIFICATION_URL,
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }
                            }
        ).then( response => {
            console.log( 'Status Code: ' + response.status )
            if( response.status === 200 ){
                console.log( 'Success response -> ' + GET_CONFIRM_NOTIFICATION_URL )
                let updateResult = response.data
                console.log("RESULT: " + updateResult)

                message.success('NOTIFICACION CONFIRMADA!');
                dispatch(setNotificationConfirmed({notificationConfirmed:notification}))
                dispatch(requestInProgress({inProgress:false}))
            }
        }).catch( error => {
            console.log('ERRORYGYG: '+error)
            message.error('LA NOTIFICACION NO SE CONFIRMO CORRECTAMENTE, INTENTE MAS TARDE!')
            dispatch(requestInProgress({inProgress:false}))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + GET_CONFIRM_NOTIFICATION_URL )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + GET_CONFIRM_NOTIFICATION_URL )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + GET_CONFIRM_NOTIFICATION_URL )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + GET_CONFIRM_NOTIFICATION_URL )
                } 
            }else{
                console.error( 'Disabled API service -> ' + GET_CONFIRM_NOTIFICATION_URL )
            }
        })  
    })
}

function buildUrlToConfirmNotification(userType, idNotif){
    let notificationsUrl = SERVER_URL+NOTIFICATIONS;
    if(userType === 1){
        notificationsUrl += "admin/"
    }
    else if(userType === 3){
        notificationsUrl += USER
    }
    notificationsUrl += "confirm/"+idNotif

    return notificationsUrl
}

export function requestInProgress(payload){
    return{
        type:'RQ_IN_PROGRESS',
        payload
    }
}

export function requestInProgressForComponent(payload){
    return{
        type:'RQ_IN_PROGRESS_FOR_COMPONENT',
        payload
    }
}

export function setNotification(payload){
    return{
        type:'SET_NOTIFICATIONS',
        payload
    }
}

export function setSeenNotification(payload){
    return{
        type:'SET_SEEN_NOTIFICATIONS',
        payload
    }
}

export function setNotificationConfirmed(payload){
    return{
        type:'SET_NOTIFICATION_CONFIRMED',
        payload
    }
}

export function setViewedNotificationsSize(payload){
    return{
        type:'SET_VIEWED_NOTIFICATIONS_SIZE',
        payload
    }
}

export function setUnviewedNotificationsSize(payload){
    return{
        type:'SET_UNVIEWED_NOTIFICATIONS_SIZE',
        payload
    }
}

export function setUnviewedNotificationsAckAdmin(payload){
    return{
        type:'SET_UNVIEWED_NOTIFICATIONS_ACK_ADMIN',
        payload
    }
}