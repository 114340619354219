import React,{Component} from 'react'
import {Form, Button, Col, Row, Input, Icon, Select,Drawer} from 'antd'
import { connect } from 'react-redux';
import NumericInput from '../input/NumericInput'
import * as CoursesActions from '../../redux/actions/CoursesActions'
import * as DrawerActionsCreators from '../../redux/actions/DrawerActions'
import  {COURSES_COLLORS, COURSES_DURATION} from '../../utility/AppConstants'
import './NewCourse.css'

const { Option } = Select;
const MAX_CAPABILITY = 6

class NewCourse extends Component{
    constructor(props){
        super(props)
        this.state = { 
            courseName:'',
            capability:'',
            tagColor:'',
            duration:'',
            tolerancia: ''
        };
        this.colors = [
            '#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#ffffff', '#000000'
        ];
    }
    
    onOk = () => {
      this.props.form.validateFields(err => {
        if (!err) {
          console.log('sending new course request')
          console.log(this.state)
          this.props.addNewCourse({
            newCourse: this.state,
            jwtToken: this.props.LoginReducer.sessionJWTToken
          })
        }
      });
    }
    onClose = () => {
        this.props.closeDrawer();
        this.props.form.resetFields();
    };
    onChange = (e) => {
      this.setState({
        [e.target.id]: e.target.value
      })
    }
    onChangeSelectInputCourseColor = (value) => {
        this.setState({
            tagColor : value
        })
      }
      onChangeSelectInputCourseDuration = (value) => {
        this.setState({
          duration : value
        })
      }
      onChangeSelectInputCourseTolerancia = (value) => {
        this.setState({
          tolerancia : value
        })
      }
    componentDidUpdate(){
        if(this.props.CoursesReducer.updatedData){
            this.props.form.resetFields();
            this.onClose()
            this.props.setUpdatedDataCourses({
                updatedData:false,
                loading:false
            })
        }
    }
    showDrawer = () => {
        this.props.openDrawer();
    };
    capabilityChecker = (rule, value, callback) => {
      if (value && value > MAX_CAPABILITY) {
        callback('El cupo maximo por grupo es de '+MAX_CAPABILITY+' espacios!');
      } else {
        callback();
      }
  };
    render(){
    const { getFieldDecorator } = this.props.form;
    return (
      <div>

        <Button type="primary" onClick={this.showDrawer} disabled={this.props.loadingFlag}>
          <Icon type="plus" /> Agregar Curso
        </Button>

        <Drawer
          title={"Nuevo Curso"}
          visible={this.props.DrawerReducer.drawerVisibility}
          bodyStyle={{ paddingBottom: 50 }}
          closable={false}
          destroyOnClose={true}
        >
            <Form layout="vertical" hideRequiredMark>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item label="Nombre Curso ">
                            {getFieldDecorator('courseName', {
                            rules: [{ required: true, message: 'Introduzca un nombre' }],
                            })(<Input id='courseName' placeholder="Introduzca el nombre del curso" onChange={this.onChange} />)}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24} >
                    <Form.Item label="Espacios Disponibles">
                        {getFieldDecorator('capability', {
                        rules:[
                                { required: true, message: 'Introduzca los espacios disponibles' },
                                { validator: this.capabilityChecker,}
                              ],
                        })(<NumericInput id='capability' placeholder="Introduzca un cupo" onChange={this.onChange} />)}
                    </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24} >
                        <Form.Item label="Color">
                            {getFieldDecorator('tagColor', {
                            rules: [{ required: true, message: 'Seleccione un color para el curso' }],
                            })(
                            <Select placeholder="Color del curso" onChange={this.onChangeSelectInputCourseColor}>
                                {COURSES_COLLORS !== undefined ? (
                                    COURSES_COLLORS.map((color, id)=> 
                                        <Option key={id} value={color}>
                                            <Icon 
                                                type="tag" 
                                                theme="twoTone" 
                                                twoToneColor={color} 
                                                style={{fontSize:30}} 
                                            />
                                        </Option>
                                    )
                                ) : (
                                    <Option  value='0'>COLORES NO DISPONIBLES</Option>
                                )}
                            </Select>,
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12} >
                        <Form.Item label="Duración (Meses)">
                            {getFieldDecorator('duration', {
                            rules: [{ required: true, message: 'Seleccione la duracion del curso en meses' }],
                            })(
                            <Select id='duration' placeholder="Duración del curso" onChange={this.onChangeSelectInputCourseDuration}>
                                {COURSES_DURATION !== undefined ? (
                                    COURSES_DURATION.map((duration)=> 
                                        <Option key={duration} value={duration}>
                                          {duration}
                                        </Option>
                                    )
                                ) : (
                                    <Option  value='0'>NUMERO DE MESES NO DISPONIBLES</Option>
                                )}
                            </Select>,
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12} >
                        <Form.Item label="Tolerancia (Meses)">
                            {getFieldDecorator('tolerancia', {
                            rules: [{ required: true, message: 'Seleccione la tolerancia del curso en meses' }],
                            })(
                            <Select id='tolerancia' placeholder="Tolerancia del curso" onChange={this.onChangeSelectInputCourseTolerancia}>
                                {COURSES_DURATION !== undefined ? (
                                    COURSES_DURATION.map((duration)=> 
                                        <Option key={duration} value={duration}>
                                          {duration}
                                        </Option>
                                    )
                                ) : (
                                    <Option  value='0'>NUMERO DE MESES NO DISPONIBLES</Option>
                                )}
                            </Select>,
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                
            </Form>
            <div
            style={{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={this.onClose} style={{ marginRight: 8 }} disabled={this.props.UsersReducer.loading}>
              Cancelar
            </Button>
            <Button type="primary" onClick={this.onOk} loading={this.props.CoursesReducer.loading} >
                <Icon type="plus" /> Agregar Curso
            </Button>
          </div>
        </Drawer>
      </div>
    );
    }
}
const mapStateToProps=(state)=>{
  return state
}
const mapDispatchToProps = {
    ...CoursesActions,
    ...DrawerActionsCreators
  }
export default connect (mapStateToProps,mapDispatchToProps)(Form.create()(NewCourse));