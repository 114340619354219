import  axios from 'axios';
import  {SERVER_URL,
        PATH_ADMIN,
        PATH_USER,
        SING_UP_USER,
        USER_UPDATE,
        SEDES,
        USER_TYPES,
        COURSES,
        UPDATE_ASSIGNMENTS,
        STUDENTS,
        TEACHERS,
        CLASSES,
        ADD,
        USER_IMAGE,
        UPDATE_TEACHER,
        USER,
        PHOTO,
        DELETE,
        SEND_INVITATION,
        COURSE_UPDATE,
        DUE_DATE,
        FINISH_COURSE
        } from '../../utility/AppConstants'
import { message } from  'antd'

export function getTeacherRequest(payload){
    const GET_TEACHER_URL = SERVER_URL+PATH_ADMIN+TEACHERS+'/'+payload.teacherId;
    return( dispatch=>{
        dispatch(setLoading({loading:true}))
        return axios.get(   GET_TEACHER_URL,
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }
                            }
        ).then( response => {
            console.log( 'Status Code: ' + response.status )
            if( response.status === 200 ){
                console.log( 'Success response -> ' + GET_TEACHER_URL )
                console.log(response.data)
                dispatch(showUser({
                    showUser : true,
                    userToShow : response.data
                }))
                dispatch(setLoading({loading:false}))
            }
        }).catch( error => {
            dispatch(setLoading({loading:false}))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + GET_TEACHER_URL )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + GET_TEACHER_URL )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + GET_TEACHER_URL )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + GET_TEACHER_URL )
                } 
                message.error( error.response.data.details + ' ('+error.response.status+')' )
            }else{
                console.error( 'Disabled API service -> ' + GET_TEACHER_URL )
            }
        })  
    })
}

export function getStudentRequest(payload){
    const GET_STUDENT_URL = SERVER_URL+PATH_ADMIN+STUDENTS+'/'+payload.studentId;
    return( dispatch=>{
        dispatch(setLoading({loading:true}))
        return axios.get(   GET_STUDENT_URL,
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }
                            }
        ).then( response => {
            console.log( 'Status Code: ' + response.status )
            if( response.status === 200 ){
                console.log( 'Success response -> ' + GET_STUDENT_URL )
                console.log(response.data)
                dispatch(showUser({
                    showUser : true,
                    userToShow : response.data
                }))
                dispatch(setLoading({loading:false}))
            }
        }).catch( error => {
            dispatch(setLoading({loading:false}))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + GET_STUDENT_URL )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + GET_STUDENT_URL )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + GET_STUDENT_URL )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + GET_STUDENT_URL )
                } 
                message.error( error.response.data.details + ' ('+error.response.status+')' )
            }else{
                console.error( 'Disabled API service -> ' + GET_STUDENT_URL )
            }
        })  
    })
}

export function getUserToShowRequest(payload){
    const GET_USER_URL = SERVER_URL+PATH_ADMIN+USER+payload.userId;
    return( dispatch=>{
        dispatch(setLoading({loading:true}))
        return axios.get(   GET_USER_URL,
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }
                            }
        ).then( response => {
            console.log( 'Status Code: ' + response.status )
            if( response.status === 200 ){
                console.log( 'Success response -> ' + GET_USER_URL )
                console.log(response.data)
                dispatch( 
                    showUserUpdate({
                        showUserUpdate:response.data,
                        loading:false,
                        updatedData:true
                    })
                )
            }
        }).catch( error => {
        
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + GET_USER_URL )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + GET_USER_URL )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + GET_USER_URL )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + GET_USER_URL )
                } 
                message.error( error.response.data.details + ' ('+error.response.status+')' )
            }else{
                console.error( 'Disabled API service -> ' + GET_USER_URL )
            }
        })  
    })
}



export function signUpUserRequest(payload){
    let SIGN_UP_URL = SERVER_URL+PATH_ADMIN+SING_UP_USER;
    let payloadRequest = {
        nombre: payload.newUser.name,
        apellido: payload.newUser.lastname,
        username: payload.newUser.email,
        password: payload.newUser.name,
        telefono: payload.newUser.telefono,
        fechaNacimiento: payload.newUser.nacimiento,
        sede: { idSede: payload.newUser.sede },
        tipoUsuario: { idTipoUsuario: payload.newUser.userType },
        comments: payload.newUser.comments
    }
    if(payload.newUser.userType === 2){
        SIGN_UP_URL += '/'+TEACHERS
        payloadRequest = {
                            ...payloadRequest,
                            maestroCursos:payload.cursos,
                         }
    }else if(payload.newUser.userType === 3){
        SIGN_UP_URL += '/'+STUDENTS
        payloadRequest = {
                            ...payloadRequest,
                            alumnoCursos:payload.cursos,
                            intensiveMode:(payload.newUser.modalidadIntensiva)?('1'):('0')
                         }
    }

    return( dispatch=>{
        dispatch(setLoading({loading:true}))
        return  axios.post( SIGN_UP_URL, 
                            payloadRequest,
                            {
                                headers: { 'Authorization': payload.jwtToken }  
                            }
        ).then( response=>{
            console.log( 'Status Code: ' + response.status )
            if(response.status === 201){
                console.log( 'Success response -> ' + SIGN_UP_URL )
                if(payload.formData === undefined){
                    message.success('EL USUARIO SE HA CREADO CORRECTAMENTE');
                    dispatch(setUpdatedData({
                        updatedData:true,
                        loading:false
                    }))
                }else{
                    dispatch(uploadUserImage({
                        idUser:response.data.object.idUsuario,
                        formData:payload.formData,
                        jwtToken: payload.jwtToken})
                    )
                }
                
            }
        }).catch( error => {
            dispatch(setUpdatedData({
                updatedData:false,
                loading:false
            }))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 400 ){
                    console.error( 'Bad request ->  ' + SIGN_UP_URL )
                    message.error( 'FORMATO DE DATOS INCORRECTO!' )
                }
                else if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + SIGN_UP_URL )
                    message.error( 'TU SESION HA EXPIRADO!' )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + SIGN_UP_URL )
                    message.error( 'NO ESTAS AUTORIZADO PARA REALIZAR ESTA ACCION!' )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + SIGN_UP_URL )
                    message.error( 'ERROR INTERNO, POR FAVOR INTENTE MAS TARDE Y COMUNIQUESE CON SOPORTE!' )
                }
                else{
                    console.error( 'Error: ' + error.response.data.details + ' -> -> ' + SIGN_UP_URL )
                    message.error( error.response.data.details )
                } 
            }else{
                console.log( 'Disabled API service -> ' + SIGN_UP_URL )
            }
        })
    })
}
export function uploadUserImage(payload){
    const ADD_USER_IMAGE_URL = SERVER_URL+PATH_ADMIN+USER_IMAGE+ADD+'/'+payload.idUser;
    return( dispatch=>{ 
        dispatch(setLoading({loading:true}))
            axios.post( ADD_USER_IMAGE_URL, 
                        payload.formData, 
                        {
                            headers: { 
                                'Content-Type': 'multipart/form-data',
                                'Authorization': payload.jwtToken
                            }
                        }
            ).then( response => {
                console.log( 'Status Code: ' + response.status )
                if(response.status === 201){
                    console.log( 'Success response -> ' + ADD_USER_IMAGE_URL )
                    message.success('EL USUARIO SE HA CREADO CORRECTAMENTE');
                    dispatch(setUpdatedData({
                        updatedData:true,
                        loading:false
                    }))
                }
            })  
            .catch(error => {
                dispatch(setLoading({loading:false}))
                message.error('Error al cargar imagen!');
                if(error.response !== undefined){
                    console.error( 'Status Code: ' + error.response.status )
                    if( error.response.status === 401 ){
                        console.error( 'Unauthenticated to access -> ' + ADD_USER_IMAGE_URL )
                    }
                    else if(error.response.status === 403){
                        console.error( 'Unauthorized to access -> ' + ADD_USER_IMAGE_URL )
                    }
                    else if( error.response.status === 500 ) {
                        console.error( 'Internal error -> ' + ADD_USER_IMAGE_URL )
                    }
                    else{
                        console.error( 'Unidentified error -> -> ' + ADD_USER_IMAGE_URL )
                    }
                    message.error(error.response.data.details + ' ('+error.response.status+')')
                }else{
                    console.error( 'Disabled API service -> ' + ADD_USER_IMAGE_URL )
                }
            });
    })
}
export function updateCoursesRequest(payload){
    let UPDATE_COURSES_URL;
    let payloadRequest;

    if(payload.tipoUsuario === 2){
        UPDATE_COURSES_URL = SERVER_URL+PATH_ADMIN+COURSES+UPDATE_ASSIGNMENTS+'/'+TEACHERS;
        payloadRequest = {
                            idUsuario:payload.idUsuario,
                            maestroCursos:payload.cursos,
                            tipoUsuario: { idTipoUsuario: payload.tipoUsuario }
                         }
    }else if(payload.tipoUsuario === 3){
        UPDATE_COURSES_URL = SERVER_URL+PATH_ADMIN+COURSES+UPDATE_ASSIGNMENTS+'/'+STUDENTS;
        payloadRequest = {
                            idUsuario:payload.idUsuario,
                            alumnoCursos:payload.cursos,
                            tipoUsuario: { idTipoUsuario: payload.tipoUsuario }
                         }
    }



    return( dispatch=>{
        dispatch(setLoading({loading:true}))
        return  axios.post( UPDATE_COURSES_URL, 
                            payloadRequest,
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }  
                            }
        ).then( response=>{
            console.log( 'Status Code: ' + response.status )
            if(response.status === 200){
                message.success('SE HAN ACTUALIZADO LOS CURSOS CORRECTAMENTE');
                dispatch(showUser({
                    showUser : true,
                    userToShow : response.data
                }))
                console.log( 'Success response -> ' + UPDATE_COURSES_URL )
                dispatch(setUpdatedData({
                    updatedData:true,
                    loading:false
                }))
                dispatch(refreshCourses({
                    refreshCourses:true,
                }))
                
            }
        }).catch( error => {
            dispatch(setUpdatedData({
                updatedData:false,
                loading:false
            }))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 400 ){
                    console.error( 'Bad request ->  ' + UPDATE_COURSES_URL )
                    message.error( 'FORMATO DE DATOS INCORRECTO!' )
                }
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + UPDATE_COURSES_URL )
                    message.error( 'TU SESION HA EXPIRADO!' )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + UPDATE_COURSES_URL )
                    message.error( 'NO ESTAS AUTORIZADO PARA REALIZAR ESTA ACCION!' )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + UPDATE_COURSES_URL )
                    message.error( 'ERROR INTERNO, POR FAVOR INTENTE MAS TARDE Y COMUNIQUESE CON SOPORTE!' )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + UPDATE_COURSES_URL )
                } 
                message.error( error.response.data.details + ' ('+error.response.status+')' )
            }else{
                console.log( 'Disabled API service -> ' + UPDATE_COURSES_URL )
            }
        })
    })
}
export function userUpdateRequest(payload){
    let USER_UPDATE_URL = SERVER_URL+PATH_USER+USER_UPDATE;
    if(payload.userType === 3){
        USER_UPDATE_URL += '/'+STUDENTS
        console.log('callint to: '+USER_UPDATE_URL)
        payload.user.intensiveMode = payload.user.intensiveMode ? '1' : '0'
        payload.user.userEnabled = payload.user.userEnabled ? '1' : '0'
        console.log('with this user: ')
        console.log(payload.user)
    }
    return( dispatch=>{
        dispatch(setLoading({loading:true}))
        
        return  axios.post( USER_UPDATE_URL, payload.user,
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }  
                            }
        ).then( response=>{
            console.log( 'Status Code: ' + response.status )
            if(response.status === 201){
                message.success('EL USUARIO SE HA ACTUALIZADO');
                console.log( 'Success response -> ' + USER_UPDATE_URL )
                dispatch(setUpdatedData({
                    updatedData:true,
                    loading:false
                }))
                if(payload.userType === 3){
                    dispatch(updateIntensiveMode({
                        intensiveMode:payload.user.intensiveMode,
                        userEnabled:payload.user.userEnabled
                    }))
                }
            }
        }).catch( error => {
            dispatch(setUpdatedData({
                updatedData:false,
                loading:false
            }))
            dispatch(setUpdateFail({
                updateFail: true
            }))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 400 ){
                    console.error( 'Bad request ->  ' + USER_UPDATE_URL )
                    message.error( 'FORMATO DE DATOS INCORRECTO!' )
                }
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + USER_UPDATE_URL )
                    message.error( 'TU SESION HA EXPIRADO!' )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + USER_UPDATE_URL )
                    message.error( 'NO ESTAS AUTORIZADO PARA REALIZAR ESTA ACCION!' )
                }
                else if( error.response.status === 404 ){
                    console.error( 'Not found -> ' + USER_UPDATE_URL )
                    message.error( 'EL USUARIO QUE DESEA ACTUALIZAR NO EXISTE EN LA BASE DE DATOS!' )
                }
                else if( error.response.status === 422 ){
                    console.error( 'Uprocessable -> ' + USER_UPDATE_URL )
                    message.error( 'ERROR AL ACTUALIZAR INFORMACION, EL EMAIL YA EXISTE!' )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + USER_UPDATE_URL )
                    message.error( 'ERROR INTERNO, POR FAVOR INTENTE MAS TARDE Y COMUNIQUESE CON SOPORTE!' )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + USER_UPDATE_URL )
                } 
            }else{
                console.log( 'Disabled API service -> ' + USER_UPDATE_URL )
            }
        })
    })
}
export function deleteUserRequest(payload){
    let DELETE_USER_URL = SERVER_URL+STUDENTS+'/'+DELETE+'/'+payload.userId;
    
        
        console.log('calling to: '+DELETE_USER_URL)
    
    return( dispatch=>{
        dispatch(setLoading({loading:true}))
        
        return  axios.get(   DELETE_USER_URL,
            {
                headers:{
                    'Authorization': payload.jwtToken
                }
            }
        ).then( response=>{
            console.log( 'Status Code: ' + response.status )
            if(response.status === 200){
                message.success('EL USUARIO SE HA ELIMINADO');
                console.log( 'Success response -> ' + DELETE_USER_URL )
                dispatch(setUpdatedData({
                    updatedData:true,
                    loading:false
                }))
                dispatch(showUser({
                    showUser : false,
                    userToShow : undefined
                }))
            }
        }).catch( error => {
            dispatch(setUpdatedData({
                updatedData:false,
                loading:false
            }))
            dispatch(setUpdateFail({
                updateFail: true
            }))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 400 ){
                    console.error( 'Bad request ->  ' + DELETE_USER_URL )
                    message.error( 'FORMATO DE DATOS INCORRECTO!' )
                }
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + DELETE_USER_URL )
                    message.error( 'TU SESION HA EXPIRADO!' )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + DELETE_USER_URL )
                    message.error( 'NO ESTAS AUTORIZADO PARA REALIZAR ESTA ACCION!' )
                }
                else if( error.response.status === 404 ){
                    console.error( 'Not found -> ' + DELETE_USER_URL )
                    message.error( 'EL USUARIO QUE DESEA ELIMINAR NO EXISTE EN LA BASE DE DATOS!' )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + DELETE_USER_URL )
                    message.error( 'ERROR INTERNO, POR FAVOR INTENTE MAS TARDE Y COMUNIQUESE CON SOPORTE!' )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + DELETE_USER_URL )
                } 
            }else{
                console.log( 'Disabled API service -> ' + DELETE_USER_URL )
            }
        })
    })
}
export function deleteTeacherRequest(payload){
    let DELETE_TEACHERS_URL = SERVER_URL+TEACHERS+'/'+DELETE+'/'+payload.userId;
    
        
        console.log('calling to: '+DELETE_TEACHERS_URL)
    
    return( dispatch=>{
        dispatch(setLoading({loading:true}))
        
        return  axios.get(   DELETE_TEACHERS_URL,
            {
                headers:{
                    'Authorization': payload.jwtToken
                }
            }
        ).then( response=>{
            console.log( 'Status Code: ' + response.status )
            if(response.status === 200){
                message.success('EL INSTRUCTOR SE HA ELIMINADO');
                console.log( 'Success response -> ' + DELETE_TEACHERS_URL )
                dispatch(setUpdatedData({
                    updatedData:true,
                    loading:false
                }))
                dispatch(showUser({
                    showUser : false,
                    userToShow : undefined
                }))
            }
        }).catch( error => {
            dispatch(setUpdatedData({
                updatedData:false,
                loading:false
            }))
            dispatch(setUpdateFail({
                updateFail: true
            }))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 400 ){
                    console.error( 'Bad request ->  ' + DELETE_TEACHERS_URL )
                    message.error( 'FORMATO DE DATOS INCORRECTO!' )
                }
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + DELETE_TEACHERS_URL )
                    message.error( 'TU SESION HA EXPIRADO!' )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + DELETE_TEACHERS_URL )
                    message.error( 'NO ESTAS AUTORIZADO PARA REALIZAR ESTA ACCION!' )
                }
                else if( error.response.status === 404 ){
                    console.error( 'Not found -> ' + DELETE_TEACHERS_URL )
                    message.error( 'EL USUARIO QUE DESEA ELIMINAR NO EXISTE EN LA BASE DE DATOS!' )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + DELETE_TEACHERS_URL )
                    message.error( 'ERROR INTERNO, POR FAVOR INTENTE MAS TARDE Y COMUNIQUESE CON SOPORTE!' )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + DELETE_TEACHERS_URL )
                } 
            }else{
                console.log( 'Disabled API service -> ' + DELETE_TEACHERS_URL )
            }
        })
    })
}
export function updateIntensiveMode(payload){
    return{
        type:'UPDATE_INTENSIVE_MODE',
        payload
    }
}
export function setUpdatedData(payload){
    return{
        type:'SET_UPDATED_DATA_USER',
        payload
    }
}
export function setUpdateFail(payload){
    return{
        type:'UPDATE_FAIL',
        payload
    }
}
export function getAllSedes(payload){
    const GET_SEDES_URL = SERVER_URL+PATH_ADMIN+SEDES;
    return( dispatch=>{
        dispatch(setLoading({loading:true}))
        return axios.get(   GET_SEDES_URL,
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }
                            }
        ).then( response => {
            console.log( 'Status Code: ' + response.status )
            if( response.status === 200 ){
                console.log( 'Success response -> ' + GET_SEDES_URL )
                console.log(response.data)
                dispatch( 
                    setSedes({
                        sedes:response.data,
                        loading:false
                    })
                )
            }
        }).catch( error => {
            dispatch( 
                setSedes({
                    sedes:{},
                    loading:false
                })
            )
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + GET_SEDES_URL )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + GET_SEDES_URL )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + GET_SEDES_URL )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + GET_SEDES_URL )
                } 
                message.error( error.response.data.details + ' ('+error.response.status+')' )
            }else{
                console.error( 'Disabled API service -> ' + GET_SEDES_URL )
            }
        })  
    })
}
export function setSedes(payload){
    return{
        type:'SET_SEDES',
        payload
    }
}
export function getAllUserTypes(payload){
    const GET_USER_TYPES_URL = SERVER_URL+PATH_ADMIN+USER_TYPES;
    return( dispatch=>{
        dispatch(setLoading({loading:true}))
        return axios.get(   GET_USER_TYPES_URL,
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }
                            }
        ).then( response => {
            console.log( 'Status Code: ' + response.status )
            if( response.status === 200 ){
                console.log( 'Success response -> ' + GET_USER_TYPES_URL )
                console.log(response.data)
                dispatch( 
                    setUserTypes({
                        userType:response.data,
                        loading:false
                    })
                )
            }
        }).catch( error => {
            dispatch( 
                setUserTypes({
                    userType:{},
                    loading:false
                })
            )
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + GET_USER_TYPES_URL )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + GET_USER_TYPES_URL )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + GET_USER_TYPES_URL )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + GET_USER_TYPES_URL )
                } 
                message.error( error.response.data.details + ' ('+error.response.status+')' )
            }else{
                console.error( 'Disabled API service -> ' + GET_USER_TYPES_URL )
            }
        })  
    })
}
export function setUserTypes(payload){
    return{
        type:'SET_USER_TYPES',
        payload
    }
}

export function classEnrollmentRequestByAdmin(payload){ //Changes here
    const CLASS_ENROLLMENT_URL = SERVER_URL+PATH_USER+STUDENTS+CLASSES+'/'+payload.action+'/'+payload.userType;
    return( dispatch=>{
        dispatch(setLoading({loading:true}))
        return  axios.post( CLASS_ENROLLMENT_URL, 
                            {
                                alumno: {
                                    idUsuario: payload.idUsuarioAlumno
                                },
                                idClase: payload.idClase
                            },
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }  
                            }
        ).then( response=>{
            console.log( 'Status Code: ' + response.status )
            if(response.status === 201){
                if(payload.action === 'add'){
                    message.success('EL USUARIO SE HA INSCRITO A LA CLASE CORRECTAMENTE!');
                }else{
                    message.success('EL USUARIO SE HA DADO DE BAJA DE LA CLASE CORRECTAMENTE!');
                }
                console.log( 'Success response -> ' + CLASS_ENROLLMENT_URL )
                dispatch(setUserEnrolledUpdate({
                    idCourse: payload.idCourse,
                    idClase: payload.idClase,
                    action: payload.action ,
                    refreshCourses:true,
                    loading:false
                }))
            }
        }).catch( error => {
            dispatch(setUpdatedData({
                updatedData:false,
                loading:false
            }))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 400 ){
                    console.error( 'Bad request ->  ' + CLASS_ENROLLMENT_URL )
                    message.error( 'FORMATO DE DATOS INCORRECTO!' )
                }
                else if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + CLASS_ENROLLMENT_URL )
                    message.error( 'TU SESION HA EXPIRADO!' )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + CLASS_ENROLLMENT_URL )
                    message.error( 'NO ESTAS AUTORIZADO PARA REALIZAR ESTA ACCION!' )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + CLASS_ENROLLMENT_URL )
                    message.error( 'ERROR INTERNO, POR FAVOR INTENTE MAS TARDE Y COMUNIQUESE CON SOPORTE!' )
                }
                else if(error.response.status === 507){
                    message.error('EL CUPO DE ESTA CLASE HA LLEGADO A SU LIMITE!');
                }
                else if(error.response.status === 510){
                    message.error('RECUERDA: PARA ASIGNARTEA UN HORARIO, DEBES HACERLO CON 24 HORAS DE ANTICIPACION!');
                }
                else{
                    console.error( 'Unidentified error -> -> ' + CLASS_ENROLLMENT_URL )
                } 
            }else{
                console.log( 'Disabled API service -> ' + CLASS_ENROLLMENT_URL )
            }
        })
    })
}

export function classTeacherUpdateRequest(payload){
    const CLASS_TEACHER_UPDATE_URL = SERVER_URL+PATH_ADMIN+'classes/'+payload.idClass+'/'+UPDATE_TEACHER+payload.idUsuario+'/'+payload.action;
    return( dispatch=>{
        dispatch(setLoading({loading:true}))
        return  axios.post( CLASS_TEACHER_UPDATE_URL, 
                            {},
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }  
                            }
        ).then( response=>{
            console.log( 'Status Code: ' + response.status )
            if(response.status === 200){
                if(payload.action ===  DELETE){
                    message.success('EL INSTRUCTOR SE HA DADO DE BAJA DE LA CLASE CORRECTAMENTE!');
                }else{
                    message.success('EL INSTRUCTOR SE HA INSCRITO A LA CLASE CORRECTAMENTE!');
                }
                console.log( 'Success response -> ' + CLASS_TEACHER_UPDATE_URL )
                dispatch(showUser({
                    showUser : true,
                    userToShow : response.data
                }))
                /*dispatch(setUpdatedData({
                    updatedData:true,
                    loading:false
                }))*/
                dispatch(setLoading({loading:false}))
                dispatch(refreshCourses({
                    refreshCourses:true,
                }))
            }
        }).catch( error => {
            dispatch(setUpdatedData({
                updatedData:false,
                loading:false
            }))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 400 ){
                    console.error( 'Bad request ->  ' + CLASS_TEACHER_UPDATE_URL )
                    message.error( 'FORMATO DE DATOS INCORRECTO!' )
                }
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + CLASS_TEACHER_UPDATE_URL )
                    message.error( 'TU SESION HA EXPIRADO!' )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + CLASS_TEACHER_UPDATE_URL )
                    message.error( 'NO ESTAS AUTORIZADO PARA REALIZAR ESTA ACCION!' )
                }
                else if( error.response.status === 404 ){
                    console.error( 'Unauthorized to access -> ' + CLASS_TEACHER_UPDATE_URL )
                    message.error( 'ID DE CLASE INVALIDO!' )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + CLASS_TEACHER_UPDATE_URL )
                    message.error( 'ERROR INTERNO, POR FAVOR INTENTE MAS TARDE Y COMUNIQUESE CON SOPORTE!' )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + CLASS_TEACHER_UPDATE_URL )
                } 
            }else{
                console.log( 'Disabled API service -> ' + CLASS_TEACHER_UPDATE_URL )
            }
        })
    })
}

export function deletePhotoRequest(payload){
    const DELETE_PHOTO_URL = SERVER_URL+PATH_ADMIN+PHOTO+DELETE+'/'+payload.userId;
    return( dispatch=>{
        dispatch(setLoading({loading:true}))
        return axios.get(   DELETE_PHOTO_URL,
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }
                            }
        ).then( response => {
            console.log( 'Status Code: ' + response.status )
            if( response.status === 200 ){
                console.log( 'Success response -> ' + DELETE_PHOTO_URL )
                console.log(response.data)
                dispatch( 
                    showUserUpdate({
                        showUserUpdate:response.data,
                        loading:false,
                        updatedData:true
                    })
                )
                message.success('LA IMAGEN SE HA ELIMINADO CORRECTAMENTE!');
            }
        }).catch( error => {
        
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + DELETE_PHOTO_URL )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + DELETE_PHOTO_URL )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + DELETE_PHOTO_URL )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + DELETE_PHOTO_URL )
                } 
                message.error( error.response.data.details + ' ('+error.response.status+')' )
            }else{
                console.error( 'Disabled API service -> ' + DELETE_PHOTO_URL )
            }
        })  
    })
}

export function getStudentCoursesRequest(payload){
    const GET_STUDENT_COURSES_URL = SERVER_URL+PATH_ADMIN+USER+payload.userId+'/'+COURSES;
    return( dispatch=>{
        dispatch(setLoading({loading:true}))
        return axios.get(   GET_STUDENT_COURSES_URL,
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }
                            }
        ).then( response => {
            console.log( 'Status Code: ' + response.status )
            if( response.status === 200 ){
                console.log( 'Success response -> ' + GET_STUDENT_COURSES_URL )
                console.log(response.data)
                dispatch(studentCourses({
                    loading : false,
                    userCourses: response.data,
                    updatedData:true
                }))
            }
        }).catch( error => {
            dispatch(setLoading({loading:false}))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + GET_STUDENT_COURSES_URL )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + GET_STUDENT_COURSES_URL )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + GET_STUDENT_COURSES_URL )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + GET_STUDENT_COURSES_URL )
                } 
                message.error( error.response.data.details + ' ('+error.response.status+')' )
            }else{
                console.error( 'Disabled API service -> ' + GET_STUDENT_COURSES_URL )
            }
        })  
    })
}

export function getCoursesClassesRequest(payload){
    const GET_COURSES_CLASSES_URL = SERVER_URL+PATH_ADMIN+STUDENTS+'/'+payload.studentId+'/'+ COURSES+'/'+payload.courseId+CLASSES;
    return( dispatch=>{
        dispatch(setLoading({loading:true}))
        return axios.get(   GET_COURSES_CLASSES_URL,
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }
                            }
        ).then( response => {
            console.log( 'Status Code: ' + response.status )
            if( response.status === 200 ){
                console.log( 'Success response -> ' + GET_COURSES_CLASSES_URL )
                console.log(response.data)
                dispatch(setCourseClasses({
                    loading : false,
                    courseId:payload.courseId,
                    courseClases: response.data,
                    coursesUpdated: true
                }))
            }
        }).catch( error => {
            dispatch(setLoading({loading:false}))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + GET_COURSES_CLASSES_URL )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + GET_COURSES_CLASSES_URL )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + GET_COURSES_CLASSES_URL )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + GET_COURSES_CLASSES_URL )
                } 
                message.error( error.response.data.details + ' ('+error.response.status+')' )
            }else{
                console.error( 'Disabled API service -> ' + GET_COURSES_CLASSES_URL )
            }
        })  
    })
}
export function sendInvitationRequest(payload){
    const SEND_INVITATION_REQUEST = SERVER_URL+PATH_ADMIN+SEND_INVITATION;
    return( dispatch=>{
        dispatch(setLoading({loading:true}))
        return  axios.post( SEND_INVITATION_REQUEST, 
                            {
                                idUsuario: payload.idUsuario
                            },
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }  
                            }
        ).then( response=>{
            dispatch(setLoading({loading:false}))
            console.log( 'Status Code: ' + response.status )
            if(response.status === 200){
                console.log( 'Success response -> ' + SEND_INVITATION_REQUEST )
                message.success(response.data.details);
            }
        }).catch( error => {
            console.error('FAILURE WHEN SEND INVITATION')
            dispatch(setLoading({loading:false}))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                message.error(error.response.data.details+' ('+error.response.status+')');
            }else{
                message.error('Servicio inhabilitado, contacte a soporte!');
            }
        })
    })
    
}

export function setUserEnrolledUpdate(payload){
    return{
        type:'USER_ENROLLED_UPDATE',
        payload
    }
}
export function setCoursesUpdated(payload){
    return{
        type:'SET_COURSES_UPDATED',
        payload
    }
}
export function setCourseClasses(payload){
    return{
        type:'COURSES_CLASSES',
        payload
    }
}
export function studentCourses(payload){
    return{
        type:'STUDENT_COURSES',
        payload
    }
}
export function updateChangeState(payload){
    return{
        type:'UPDATE_CHANGE_STATE',
        payload
    }
}
export function showUser(payload){
    return{
        type:'SHOW_USER',
        payload
    }
}
export function showUserUpdate(payload){
    return{
        type:'SHOW_USER_UPDATE',
        payload
    }
}
export function refreshCourses(payload){
    return{
        type:'REFRESH_COURSES',
        payload
    }
}
export function setLoading(payload){
    return{
        type:'SET_LOADING_USERS',
        payload
    }
}


export function setNewDueDateCourseForStudent(payload){
    let NEW_DUE_DATE_REQ = SERVER_URL+COURSES+'/'+COURSE_UPDATE+'/'+DUE_DATE+'/'+payload.courseId+'/'+payload.studentId;    
    console.log(NEW_DUE_DATE_REQ)
    return( dispatch=>{
        dispatch(setLoadingDueDateModal({loadingDueDateModal:true}))
        return axios.post(   NEW_DUE_DATE_REQ,
                            {
                                vencimiento : payload.newDueDate
                            },
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }
                            }
        ).then( response => {
            dispatch(setLoadingDueDateModal({loadingDueDateModal:false}))
            console.log( 'Status Code: ' + response.status )
            if( response.status === 200 ){
                console.log( 'Success response -> ' + NEW_DUE_DATE_REQ )
                dispatch( 
                    dueDateUpdated({
                        courseId:payload.courseId,
                        userId : payload.userId,
                        vencimiento : payload.newDueDate,
                        bloqueo: false
                    })
                )
                dispatch(dueDateModalVisibility({dueDateModalVisibility: false}))
            }
        }).catch( error => {
            dispatch(setLoadingDueDateModal({loadingDueDateModal:false}))
            dispatch(dueDateModalVisibility({dueDateModalVisibility: true}))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 400 ){
                    console.error( 'Bad request ->  ' + NEW_DUE_DATE_REQ )
                    message.error( 'FORMATO DE DATOS INCORRECTO!' )
                }
                else if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + NEW_DUE_DATE_REQ )
                    message.error( 'TU SESION HA EXPIRADO!' )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + NEW_DUE_DATE_REQ )
                    message.error( 'NO ESTAS AUTORIZADO PARA REALIZAR ESTA ACCION!' )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + NEW_DUE_DATE_REQ )
                    message.error( 'ERROR INTERNO, POR FAVOR INTENTE MAS TARDE Y COMUNIQUESE CON SOPORTE!' )
                }
                else{
                    console.error( 'Error: ' + error.response.data.details + ' -> -> ' + NEW_DUE_DATE_REQ )
                    message.error( error.response.data.details )
                }  
            }else{
                console.error( 'Disabled API service -> ' + NEW_DUE_DATE_REQ )
            }
        })  
    })
}

export function finishCourseForStudent(payload){
    let FINISH_STUDENT_COURSE_REQ = SERVER_URL+STUDENTS+'/'+payload.studentId+'/'+FINISH_COURSE+payload.courseId;    
    console.log(FINISH_STUDENT_COURSE_REQ)
    return( dispatch=>{
        dispatch(setLoadingFinishCourseModal({loadingFinishCourseModal:true}))
        return axios.get(   FINISH_STUDENT_COURSE_REQ,
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }
                            }
        ).then( response => {
            dispatch(setLoadingFinishCourseModal({loadingFinishCourseModal:false}))
            console.log( 'Status Code: ' + response.status )
            if( response.status === 200 ){
                console.log( 'Success response -> ' + FINISH_STUDENT_COURSE_REQ )
                dispatch( 
                    studentCourseFinishedSuccessfully({
                        courseId:payload.courseId
                    })
                )
                dispatch(dueDateModalVisibility({dueDateModalVisibility: false}))
            }
        }).catch( error => {
            dispatch(setLoadingFinishCourseModal({loadingFinishCourseModal:false}))
            dispatch(dueDateModalVisibility({dueDateModalVisibility: true}))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 400 ){
                    console.error( 'Bad request ->  ' + FINISH_STUDENT_COURSE_REQ )
                    message.error( 'FORMATO DE DATOS INCORRECTO!' )
                }
                else if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + FINISH_STUDENT_COURSE_REQ )
                    message.error( 'TU SESION HA EXPIRADO!' )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + FINISH_STUDENT_COURSE_REQ )
                    message.error( 'NO ESTAS AUTORIZADO PARA REALIZAR ESTA ACCION!' )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + FINISH_STUDENT_COURSE_REQ )
                    message.error( 'ERROR INTERNO, POR FAVOR INTENTE MAS TARDE Y COMUNIQUESE CON SOPORTE!' )
                }
                else{
                    console.error( 'Error: ' + error.response.data.details + ' -> -> ' + FINISH_STUDENT_COURSE_REQ )
                    message.error( error.response.data.details )
                }  
            }else{
                console.error( 'Disabled API service -> ' + FINISH_STUDENT_COURSE_REQ )
            }
        })  
    })
}


export function dueDateUpdated(payload){
    return{
        type:'DUE_DATE_UPDATED',
        payload
    }
}

export function setLoadingDueDateModal(payload){
    return{
        type:'SET_LOADING_DUE_DATE_MODAL',
        payload
    }
}

export function dueDateModalVisibility(payload){
    return{
        type:'DUE_DATE_MODAL_VISIBILITY',
        payload
    }
}

export function setLoadingFinishCourseModal(payload){
    return{
        type:'SET_LOADING_FINISH_COURSE_MODAL',
        payload
    }
}

export function studentCourseFinishedSuccessfully(payload){
    return{
        type:'STUDENT_COURSE_FINISHED_SUCCESSFULLY',
        payload
    }
}