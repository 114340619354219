const DrawerReducer = (state={}, action) => {
    if(action.type === 'OPEN_DRAWER'){
        return{
            ...state,
            drawerVisibility: true
        }
    }
    else if(action.type === 'CLOSE_DRAWER'){
        return{
            ...state,
            drawerVisibility: false
        }
    }
    else if(action.type === 'SET_RESET_FORM'){
        return{
            ...state,
            resetForm: action.payload.resetForm
        }
    }
    else {
        return state
    }
}
export default DrawerReducer;

