const TeacherReducer=(state={}, action)=>{
    if(action.type === 'SET_TEACHERS'){
        return{
            ...state,
            teachers: action.payload.teachers,
        }
    }
    else if(action.type === 'SET_TEACHER_CLASSES_IDS'){
        return{
            ...state,
            teacherClassesIds: action.payload.teacherClassesIds,
        }
    }
    else if(action.type === 'SET_LOADING_TEACHERS'){
        return{
            ...state,
            loading: action.payload.loading,
        }
    }
    else {
        return state
    }
}
export default TeacherReducer;