import React, {Component} from 'react'
import { Result, Button } from 'antd';
import {Link } from 'react-router-dom';

class InvalidLayer extends Component{
    render(){
        return(
            <Result
                status="404"
                title="404"
                subTitle="Lo sentimos, la pagina que deseas visitar no existe!"
                extra={<Button type="primary"><Link to="/home" >Pagina Principal</Link></Button>}
            />
        )
    }
}export default InvalidLayer;