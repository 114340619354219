import React, {Component} from 'react'
import './NewDetails.css'
import {Row, Modal, Button, Tag, Spin} from 'antd'
import { connect } from 'react-redux';
import * as NewsActions from '../../redux/actions/NewsActions'
import { CloseOutlined, ClockCircleOutlined, DeleteTwoTone, CloseCircleTwoTone } from '@ant-design/icons';

const { confirm } = Modal;

class NewDetails extends Component{
    UNSAFE_componentWillUpdate(){
        if(this.props.NewsReducer.newsDeletedSuccess !== undefined){
            this.props.setDeletedSuccess({
                newsDeletedSuccess:undefined
            })
            this.closeModal()
        }
    }
    closeModal=()=>{
        this.props.newsDetailsModal({
            visible:false
        })
    }
    showDeleteConfirm = (props) => {
        confirm({
            title: '¿Está seguro que desea eliminar esta noticia?',
            icon: <CloseCircleTwoTone twoToneColor="#FF0000" style={{ fontSize:'35px'}} />,
            content: 'La noticia se eliminara de forma permanente y ya no podra ser vista por ningun usuario en el portal.',
            okText: 'Eliminar',
            okType: 'danger',
            cancelText: 'Cancelar',
            onOk() {
              console.log('OK');
              console.log('send request to retrieve delete new: ' + props.LoginReducer.sessionJWTToken)
              props.deleteNews({
                jwtToken: props.LoginReducer.sessionJWTToken,
                idToDelete: props.details.id
            })
            },
            onCancel() {
              console.log('Cancel');
            },
          });
    }
    render(){
        let details = this.props.details
        return(
            details !== undefined ? 
                <Modal
                    onCancel={()=>this.closeModal()}
                    visible={details.visible}
                    footer={null}
                    width={800}
                    className='modal'
                    bodyStyle={{backgroundColor:'white', padding:'0px'}}
                    closeIcon={<CloseOutlined className='close-icon'/>}
                >
                    <Spin spinning={this.props.NewsReducer.deletingNewInProgress !== undefined ? this.props.NewsReducer.deletingNewInProgress : false}>
                        <Row className='image-section' type='flex' justify='center' align='middle'>
                            <img alt="new" className='responsive-image' src={details.image} />
                        </Row>

                        <Row className='title-section'>
                            <Tag color="#000000"> 
                                <ClockCircleOutlined /> {details.date}
                            </Tag>
                            <Row type='flex' justify='space-between' align='middle'>
                                {details.title}
                                
                                {(this.props.LoginReducer.sessionInformation.user.userType===1 ) ? 
                                    <DeleteTwoTone twoToneColor="#FF0000" className='delete-icon' onClick={()=>this.showDeleteConfirm(this.props)}/>
                                : null}
                            </Row>
                        </Row>

                        <Row className='content-section'>
                            <p>{details.content}</p>
                        </Row>

                        <Row className='footer-section' type='flex' justify='end' align='middle'>
                            <Button type="danger" onClick={()=>this.closeModal()}>
                                CERRAR
                            </Button>
                        </Row>
                    </Spin>
                </Modal>
            : null
        )
    }
}
const mapStateToProps=(state)=>{
    return state
}
export default connect(mapStateToProps,NewsActions)(NewDetails);