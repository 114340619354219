import React,{Component} from 'react'
import {Modal} from 'antd'
import { connect } from 'react-redux';
import * as CoursesActions from '../../redux/actions/CoursesActions'
import * as AssistsActions from '../../redux/actions/AssistsActions'
import AssistanceTable from './AssistanceTable'

class AssistanceManagement extends Component{
    closeModal = () => {
        this.props.assistanceManagementModal({
            assistanceManagementModal: false,
            assistanceIdClass: 0
        })
        this.props.setStudentsList({idsRegistredToClass :undefined})
        this.props.setAssists({assistsList:undefined})
    }
    render(){
        return(
            
                <Modal
                visible={this.props.CoursesReducer.assistanceManagementModal}
                title={'Lista de asistencia'}
                onCancel={(this.props.AssistsReducer.showTrackerAssists!==undefined)?((this.props.AssistsReducer.showTrackerAssists===true)?null:this.closeModal):this.closeModal}
                footer={false}
                width={500}
                style={{position:'relative', overflow:'hidden'}}>
                    {this.props.CoursesReducer.assistanceManagementModal
                    ? <AssistanceTable/>
                    : null
                    }
                </Modal>
            
        )
    }
}
const mapStateToProps=(state)=>{
    return state
}
const mapDispatchToProps = {
    ...CoursesActions,
    ...AssistsActions
};
export default connect(mapStateToProps,mapDispatchToProps)(AssistanceManagement);

