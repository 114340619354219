import React,{Component} from 'react'
import {Row,Col,Input,Icon, Form, Button, Avatar} from 'antd'
import { connect } from 'react-redux';
import * as LoginActions from '../../redux/actions/LoginActions'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import ChangeReqPwd from './ChangeReqPws.js'
import './Form.css'


const FormItem = Form.Item;

class LoginForm extends Component{
    state = {
        username: '',
        password: ''
    }
    UNSAFE_componentWillMount(){
        if(this.props.LoginReducer.requestInProgress){
            console.log('setting inProgress flag to false')
            this.props.setRequestInProgress ({requestInProgress:false});
        }
    }
    setupBeforeUnloadListener = () => {
        window.addEventListener("beforeunload", (ev) => {
            if(this.props.LoginReducer.requestInProgress){
                this.props.setRequestInProgress ({requestInProgress:false});
            }
        });
    };
    componentDidMount(){
        this.setupBeforeUnloadListener();
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id] : e.target.value
        })
    }
    login = async () =>{
        await this.props.form.validateFields(err => {
            if (!err) {
                console.log('sending login request')
                this.props.loginRequest({
                    username:   this.state.username,
                    password:   this.state.password,
                    module  :   'admin'
                })
            }
        });
    }
    openPwdChgReqModal = () => {
        console.log( 'Opening pwd change request modal' )
        this.props.pwdChgReqModal({
            pwdChgReqVisible: true
        })
    }
    render(){
        const { getFieldDecorator } = this.props.form;
        return(
            <div className='backGround'>
                <div className='icon' >
                    <Row>
                        <Row type="flex" justify="center">
                            <Avatar 
                                size={90} 
                                src={require('../../images/axss-login.png')}
                                style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }} 
                            />
                        </Row>
                    </Row>
                </div>
                <div className='form'>
                    
                    <Row className='contentRow'>
                        <Row>
                            <Col span={24}>
                                <FormItem>
                                    {getFieldDecorator('username', {
                                        rules: [{ required: true, message: 'El correo electronico es requerido' }],
                                    })(
                                        <Input
                                            id="username"
                                            prefix={<Icon type="user" style={{ fontSize: 13 }} />}
                                            placeholder="Correo Electronico"
                                            onChange={this.handleChange}
                                        />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem>
                                    {getFieldDecorator('password', {
                                        rules: [{ required: true, message: 'La contraseña es requerida' }],
                                    })(
                                        <Input.Password
                                            id="password"
                                            prefix={<Icon type="lock" style={{ fontSize: 13 }} />}
                                            placeholder="Contraseña"
                                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                            onChange={this.handleChange}
                                        />
                                    )}
                                </FormItem>
                            </Col>
                            
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="login-form-button"
                                onClick={this.login}
                                loading={this.props.LoginReducer!== undefined ?  this.props.LoginReducer.requestInProgress : false}
                            >
                                Iniciar Sesión
                            </Button>
                            <a className="login-form-forgot" href=" #" onClick={this.openPwdChgReqModal}>
                                ¿Olvidaste tu contraseña?
                            </a>
                            <ChangeReqPwd/>
                        </Row>
                    </Row>
                </div>
            </div>
        )
    }
}
const mapStateToProps=(state)=>{
    return state
}
export default connect (mapStateToProps,LoginActions)(Form.create()(LoginForm));
