export function openDrawer(){
    return{
        type:'OPEN_DRAWER'
    }
}
export function closeDrawer(){
    return{
        type:'CLOSE_DRAWER'
    }
}
export function setResetForm(payload){
    return{
        type:'SET_RESET_FORM',
        payload
    }
}

