import React,{Component} from 'react'
import {Modal, Form, Row, Input, Button, Icon, Spin} from 'antd'
import { connect } from 'react-redux';
import * as CoursesActionsCreators from '../../redux/actions/CoursesActions'

class UpdateLesson extends Component{
    state = { 
        title      : this.props.tituloTema,
        description: this.props.descripcionTema
    }
    onChange = (e) => {
        this.setState({
          [e.target.id]: e.target.value
        })
    }
    onModify = () =>{
        console.log(this.state)
        if(this.state.title===this.props.tituloTema && this.state.description===this.props.descripcionTema){
            this.closeModal()
        }else{
            console.log('sending edit lesson request')
            this.props.editLessonRequest({
                idTema: this.props.CoursesReducer.lesson.idTema,
                tituloTema: this.state.title,
                descripcionTema: this.state.description,
                jwtToken: this.props.LoginReducer.sessionJWTToken
            })
        }
    }
    closeModal = () => {
        this.props.updateLessonModal({
            updateLessonModal:false,
            lesson:undefined
          })
    }
    render(){
        const { getFieldDecorator } = this.props.form;
        return(
            (this.props.CoursesReducer.updateLessonModal !== undefined)
            ?(
                <Modal
                visible={this.props.CoursesReducer.updateLessonModal}
                title={this.props.CoursesReducer.courseDetails.nombre}
                onCancel={this.closeModal}
                footer={false}
                width={500}>
                    <Spin spinning={this.props.CoursesReducer.loading}>
                        <Form>
                            <Row >
                                <h3 style={{float:'left'}}>EDITAR TEMA</h3>
                            </Row>
                            <Row >
                                <Form.Item>
                                    {getFieldDecorator('title', {
                                        initialValue: this.state.title,
                                        rules: [{ required: true, message: 'Introduzca un titulo' }],
                                    })(<Input id='title' placeholder="TITULO" maxLength={70} onChange={this.onChange} />)}
                                </Form.Item>
                            </Row>
                            <Row>
                                <Form.Item>
                                    {getFieldDecorator('description', {
                                        initialValue: this.state.description,
                                        rules: [{ required: false, message: '' }],
                                    })(<Input id='description' placeholder="DESCRIPCION" maxLength={210} onChange={this.onChange} />)}
                                </Form.Item>
                            </Row>
                            <Row>
                                <Form.Item >
                                    <Button type="primary" onClick={this.onModify} >
                                        <Icon type="reload" /> Modificar
                                    </Button>
                                </Form.Item>
                            </Row>
                        </Form>
                    </Spin>
                </Modal>
            )
            :(
                null
            )   
        )
    }
}
const mapStateToProps=(state)=>{
    return state
}
export default connect(mapStateToProps,CoursesActionsCreators)(Form.create()(UpdateLesson));