import React,{Component} from 'react'
import {Modal,Row,Col,Input,Form,Button,Spin} from 'antd'
import { connect } from 'react-redux';
import * as actionCreator from '../../redux/actions/LoginActions'

class ChangePwd extends Component{
    state = { pwd: '',
              pwdR: ''
            };

    onChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    checkPwds = (rule, value, callback) => {
        if (value !== this.state.pwd ) {
          callback('Las contraseñas no coinciden!');
        } else {
          callback();
        }
    };
    onChangePwd = () => {
        this.props.form.validateFields(err => {
          if (!err) {
            console.log(this.state)
            console.log('sending request to update pwd')
            this.props.updatePwdRequest({
                idUsuario:this.props.LoginReducer.sessionInformation.user.userId,
                password:this.state.pwd,
                jwtToken: this.props.LoginReducer.sessionJWTToken
            })
          }
        });
    }
    logOut = () => {
        localStorage.clear();
        this.props.userLogOut()
    }
  
    render(){
        const { getFieldDecorator } = this.props.form;
        return(
            this.props.LoginReducer.sessionInformation !== undefined ?
                <Modal
                title="Cambio De Contraseña"
                visible={this.props.LoginReducer.sessionInformation.user.resetPwd === "1"}
                closable={false}
                footer={null}
                >
                    <Spin spinning={this.props.LoginReducer.loadingLogin}>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item label="Nueva Contraseña">
                                    {
                                        getFieldDecorator('pwd', {
                                                rules: [{ required: true, message: 'Introduzca una contraseña' }],
                                        })
                                        (
                                            <Input.Password 
                                                id='pwd'
                                                placeholder={'Introduzca una contraseña'} 
                                                onChange={this.onChange} 
                                                autocomplete="new-password"/>
                                        )
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item label="Repetir Contraseña">
                                    {
                                        getFieldDecorator('pwdR', {
                                            rules: [
                                                { required: true, message: 'Repita la contraseña' },
                                                { validator: this.checkPwds,}
                                            ],
                                        })
                                        (
                                            <Input.Password 
                                                id='pwdR' 
                                                placeholder={'Repita la contraseña'} 
                                                onChange={this.onChange} 
                                                autocomplete="new-password"/>
                                        )
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Button type="primary" onClick={this.onChangePwd}>
                                    Cambiar contraseña
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button type="danger" onClick={this.logOut}>
                                    Cerrar sesion
                                </Button>
                            </Col>
                        </Row>
                    </Spin>
                </Modal>
            :null
        )
    }
}
const mapStateToProps=(state)=>{
    return state
}
export default connect (mapStateToProps,actionCreator)(Form.create()(ChangePwd));