import React,{Component} from 'react'
import {Button, Table, Row, Select, Col, Avatar, Spin, Tooltip, Icon, Modal } from 'antd'
import { connect } from 'react-redux';
import * as AssistsActions from '../../redux/actions/AssistsActions'
import * as CoursesActions from '../../redux/actions/CoursesActions'
import { UserOutlined, CloseCircleTwoTone } from '@ant-design/icons';
import AssistanceTracker from './AssistanceTracker'
const { Option } = Select;
const { confirm } = Modal;


// eslint-disable-next-line 
Array.prototype.diff = function(a) {
  return this.filter(function(i) {return a.indexOf(i) < 0;});
};

class AssistanceTable extends Component{
    constructor(props){
      super(props);
      this.state = {
        selectedRowKeys: [], // Check here to configure the default column
        idTema: 0,
        drawerStudent: null,
        password:'',
        updateCourse: false
      };
    }

    unenrollStudent = (student) => {
      let t = this;
      confirm({
          title: '¿Desvincular a "' + student.nombre + '" del curso "'+this.props.CoursesReducer.courseDetails.nombre+'"?',
          icon: <CloseCircleTwoTone twoToneColor="#FF0000" style={{ fontSize:'35px'}} />,
          content: '¡El alumno sera dado de baja de todas las clases en las cuales este inscrito del curso!',
          okText: 'Eliminar',
          okType: 'danger',
          cancelText: 'Cancelar',
          onOk() {
                console.log('calling endpoint to unenroll student from classes')
                t.props.studentClassesUnenrollment({
                  jwtToken: t.props.LoginReducer.sessionJWTToken,
                  studentId: student.idUsuario,
                  courseId: t.props.CoursesReducer.courseDetails.idCurso,
                })
          },
          onCancel() {
            console.log('Cancel');
          },
        });
    }

    unenrollOption = {
      title: '',
        render: (text, record) => 
            <Tooltip placement="top" title={'Desvincular'}>
              <Icon type="minus-circle" style={{ fontSize: 20, color: 'red' }} onClick={()=>{this.unenrollStudent(record)}}/>
            </Tooltip>
    }
    columns = [{
        title: 'Nombre',
        dataIndex: 'nombre',
        render: (text,record ) => <div style={record.userEnabled === '0' ? {color:'rgb(206, 205, 205)',fontWeight: 'bold'} : {fontWeight: 'bold'}} >{text}</div>,
      },{
        title: 'Apellido',
        dataIndex: 'apellido',
        render: (text,record ) => <div style={record.userEnabled === '0' ? {color:'rgb(206, 205, 205)',fontWeight: 'bold'} : {fontWeight: 'bold'}} >{text}</div>,
      }, {
        title: '',
        key: 'fotoUsuario',
        render: (text, record) => 
            <Tooltip placement="top" title={'Ver Asistencias'}>
              <div  onClick={()=>this.openStudenAssistenceDrawer(record)} style={{cursor: 'pointer'}}>
                          <Avatar 
                            size={60} 
                            icon={ record.fotoUsuario === null ? <UserOutlined /> : null} 
                            src ={ record.fotoUsuario === null ? null :`data:image/jpeg;base64,${record.fotoUsuario.archivo}`}
                            style={record.userEnabled === '0'  ? {filter:'opacity(0.2)'} : null } 
                          />
              </div>
            </Tooltip>
    }];
    
    openStudenAssistenceDrawer = (student) =>{
      this.setState({drawerStudent:student})
      this.props.getStudentAssists({
        studentId:student.idUsuario,
        courseId:this.props.CoursesReducer.courseDetails.idCurso,
        jwtToken: this.props.LoginReducer.sessionJWTToken,
        view:"AssistanceList"
      })
    }
    onSave = () => {
      if(this.props.AssistsReducer.assistsList!==undefined){
        let idsToDeleteOfLesson = this.props.AssistsReducer.assistsList.diff(this.state.selectedRowKeys)
        console.log('a eliminar: ')
        console.log(idsToDeleteOfLesson)
        let idsToAddInLesson = this.state.selectedRowKeys.diff(this.props.AssistsReducer.assistsList)
        console.log('a agregar: ')
        console.log(idsToAddInLesson)
        console.log('lista final: ')
        console.log(this.state.selectedRowKeys)
        if(idsToDeleteOfLesson.length > 0 || idsToAddInLesson.length > 0){
          console.log('calling endpoint to update assists list')
          this.props.updateAssistsList({
            idClase:  this.props.CoursesReducer.assistanceIdClass,
            idLesson: this.state.idTema,
            toDelete: idsToDeleteOfLesson,
            toAdd: idsToAddInLesson,
            assistsList: this.state.selectedRowKeys,
            jwtToken: this.props.LoginReducer.sessionJWTToken,
            updatedById: this.props.LoginReducer.sessionInformation.user.userId
          })
        }
      }
    }

    onSelectChange = (selectedRowKeys) => {
      console.log('selectedRowKeys changed: ', selectedRowKeys);
      if(this.state.idTema > 0 ){
        this.setState({ selectedRowKeys });
      }
    }

    onChangeLesson=(value)=> {
      console.log(`selected ${value}`);
      this.setState({idTema : value})
      console.log('llamar lista de alumnos con asistencia en clase id: '+this.props.CoursesReducer.assistanceIdClass + 'en tema: ' + this.state.idTema)
      this.props.getClassAssistsByLesson({
        idClase:  this.props.CoursesReducer.assistanceIdClass,
        idLesson: value,
        jwtToken: this.props.LoginReducer.sessionJWTToken
      })
    }
    
    componentDidUpdate(){
      if(this.props.AssistsReducer.assistsUpdated !== undefined && this.props.AssistsReducer.assistsUpdated){
        this.props.assistsUpdated({assistsUpdated:false})
        console.log('actualiza lista de asistencias')
        this.setState({
          selectedRowKeys: this.props.AssistsReducer.assistsList
        })
      }
      if(this.props.AssistsReducer.successUnenrollemnt !== undefined && this.props.AssistsReducer.successUnenrollemnt === true){
        console.log('calling endpoint to update course information')
          this.props.getCourseRequest({
              jwtToken: this.props.LoginReducer.sessionJWTToken,
              courseId: this.props.CoursesReducer.courseDetails.idCurso,
          })
          this.props.setSuccessUnenrollemnt({successUnenrollemnt:false})
          this.setState({updateCourse:true})
      }   
    }
    componentWillUnmount(){
      if(this.state.updateCourse){
        this.props.refreshCoursesInCoursesActions({refreshCoursesInCourses:true})
        this.setState({
          updateCourse:false
        })
      }
      
    }
    render(){
      const { selectedRowKeys } = this.state;
      
      const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
        getCheckboxProps : (record) => ({
          disabled: record.userEnabled === '0' 
        })
      };
      const hasSelected = selectedRowKeys.length > 0;
      return(
        <div>
            <AssistanceTracker student={this.state.drawerStudent} lessons={this.props.CoursesReducer.courseDetails.temario === undefined ? [] : this.props.CoursesReducer.courseDetails.temario} view="AssistanceList"/>
            <div style={{ marginBottom: 16 }}>
                <Row>
                  <Col span={15}>
                    <Select
                      showSearch
                      style={{ width: 200 }}
                      placeholder="Seleccione un tema"
                      optionFilterProp="children"
                      onChange={this.onChangeLesson}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >

                      {this.props.CoursesReducer.courseDetails.temario.length>0? (
                          this.props.CoursesReducer.courseDetails.temario.map((tema, index)=> 
                              <Option key={index} value={tema.idTema}>
                                {'Tema '+(index+1)+': '+tema.tituloTema}
                              </Option>
                          )
                      ) : (
                          <Option  value='0'>NO EXISTEN TEMAS DE ESTE CURSO</Option>
                      )}
                
                    </Select>
                  </Col>
                  <Col span={9}>
                    <Button
                      type="primary"
                      onClick={this.onSave}
                      disabled={this.state.idTema === 0} //!hasSelected
                    >
                        Guardar Asistencias
                    </Button>
                  </Col>
                </Row>
                
                <Row>
                    <span style={{ marginLeft: 8 }}>
                        {hasSelected ? `Alumnos con asistencia: ${selectedRowKeys.length}` : ''}
                    </span>
                </Row>
            </div>
            <Spin spinning={this.props.AssistsReducer.loading || this.props.CoursesReducer.loading}>
              <Table 
                rowSelection={rowSelection} 
                columns={this.props.LoginReducer.sessionInformation.user.userType===1  ? this.columns.concat([this.unenrollOption]) : this.columns} 
                dataSource= { this.props.AssistsReducer.studentsRegistredInClass !== undefined
                              ? this.props.AssistsReducer.studentsRegistredInClass
                              : []
                            } />
            </Spin>
        </div>
      )
    }
}
const mapStateToProps=(state)=>{
  return state
}
const mapDispatchToProps = {
  ...CoursesActions,
  ...AssistsActions
};
export default connect(mapStateToProps,mapDispatchToProps)(AssistanceTable);