import { combineReducers } from 'redux'
import {persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import LoginReducer from './reducers/LoginReducer'
import UsersReducer from './reducers/UsersReducer'
import StudentsReducer from './reducers/StudentsReducer'
import AdministratorsReducer from './reducers/AdministratorsReducer'
import SedeReducer from './reducers/SedeReducer'
import DrawerReducer from './reducers/DrawerReducer'
import CoursesReducer from './reducers/CoursesReducer'
import TeacherReducer from './reducers/TeacherReducer'
import AssistsReducer from './reducers/AssistsReducer'
import NewsReducer from './reducers/NewsReducer'
import NotificationsReducer from './reducers/NotificationsReducer'


const persistConfig = {
    key: 'root', 
    storage
}
const appReducer = combineReducers({
    LoginReducer,
    UsersReducer,
    StudentsReducer,
    AdministratorsReducer,
    SedeReducer,
    DrawerReducer,
    CoursesReducer,
    TeacherReducer,
    AssistsReducer,
    NewsReducer,
    NotificationsReducer
})
const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
      state = undefined
    }
    return appReducer(state, action)
}
export default persistReducer(persistConfig,rootReducer)