import React,{Component} from 'react'
import HomeCarousel from '../../components/carousel/Carousel'
import CoursesPanel from '../../components/courses/CoursesPanel'
import NewsPanel from '../../components/news/NewsPanel'

import {Row, Col} from 'antd'
import '../layers.css'
import './home.css'
import { connect } from 'react-redux';

class HomeLayer extends Component{
    render(){
        return( 
                (this.props.LoginReducer.sessionInformation!==undefined) 
                ?   <div>
                        <Row>
                            <HomeCarousel/>
                        </Row>
                
                        <Row type="flex" justify="center" align="top" >
                            <Col xs={24} lg={14} xl={15} xxl={17} className='home-section-col'>  
                                <NewsPanel/>
                            </Col>
                            <Col xs={24} lg={10} xl={9} xxl={7} className='home-section-col'> 
                                <CoursesPanel/>
                            </Col>
                        </Row>
                        
                    </div>
                :   null
        )
    }
}
const mapStateToProps=(state)=>{
    return state
}
export default connect(mapStateToProps)(HomeLayer); 



