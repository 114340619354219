import React,{Component} from 'react'
import { connect } from 'react-redux';
import NewUser from '../../components/users/NewUser'
import StudentsTable from '../../components/table/InfoTable'
import * as StudentsActions from '../../redux/actions/StudentsActions'
import * as UserActionsCreators from '../../redux/actions/UsersActions'
import ShowUserModal from '../../components/users/ShowUserModal'
import { Input,Button, Icon, message } from 'antd'
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import '../layers.css'

class StudentsLayer extends Component{

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
    };
    
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    constructor(props){
        let getColumnSearchProps = dataIndex => ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
              <div style={{ padding: 8 }}>
                <Input
                  ref={node => {
                    this.searchInput = node;
                  }}
                  placeholder={`Buscar por ${(dataIndex==='username')?'correo':dataIndex}`}
                  value={selectedKeys[0]}
                  onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                  onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                  style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                
                  <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                  >
                    Buscar
                  </Button>
                  <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Limpiar
                  </Button>
                
              </div>
            ),
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilter: (value, record) =>{
              let res = false;
              (dataIndex==='nombre' && record.apellido !== null)?(
                res = (record.nombre.trim() +' '+record.apellido.trim()).toLowerCase().includes(value.trim().toLowerCase())
              ):(
                res = record[dataIndex].toString().toLowerCase().includes(value.trim().toLowerCase())
              )
              return res
            },
            onFilterDropdownVisibleChange: visible => {
              if (visible) {
                setTimeout(() => this.searchInput.select());
              }
            },
            render: text =>
              this.state.searchedColumn === dataIndex ? (
                <Highlighter
                  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                  searchWords={[this.state.searchText]}
                  autoEscape
                  textToHighlight={text.toString()}
                />
              ) : (
                text
              ),
        });

        super(props)
        this.state = {
            searchText: '',
            searchedColumn: '',
        };
        this.columns = [
            {title: 'NOMBRE', dataIndex: 'nombre', key: 'nombre', ...getColumnSearchProps('nombre'),
              render: (text, record) => (
                (record.apellido === null) ? (text) : (text + ' ' + record.apellido)
              )
            },
            {title: 'CORREO', dataIndex: 'username',key: 'username',...getColumnSearchProps('username')},
            {title: 'FECHA INGRESO', dataIndex: 'fechaIngreso',key: 'fechaIngreso'},
            {title: 'SUCURSAL', dataIndex: 'sede.nombre',key: 'idUsuario'},
            {title: '',key: 'OPC',
                render: (text, record) => (
                        <a href='# ' key={record.idUsuario} onClick={()=>this.showStudent(record)}>Mostrar</a>
                ),
            },
        ];
    }
    showStudent = (record) => {
        console.log('showing student..')
        console.log(record)
        this.props.showUser({
            showUser : true,
            userToShow : record
        })
        console.log('retrieving student information {student id: '+record.idUsuario+'}.....')
        this.props.getStudentRequest({
          jwtToken: this.props.LoginReducer.sessionJWTToken,
          studentId: record.idUsuario
        })
    }
    studentsData = () => {
        console.log('send request to retrieve all students: ' + this.props.LoginReducer.sessionJWTToken)
        this.props.getStudentsRequest({
            jwtToken: this.props.LoginReducer.sessionJWTToken
        })
    }
    UNSAFE_componentWillMount(){
        console.log('send request to retrieve all sedes: ' + this.props.LoginReducer.sessionJWTToken)
        this.props.getAllSedes({
            jwtToken: this.props.LoginReducer.sessionJWTToken
        })
    }
    downloadStudentReport = () => {
      message.loading('Descargando Reporte De Estudiantes...');
      console.log('action trigger to download student report')
      this.props.getStudentsReportRequest({
        jwtToken: this.props.LoginReducer.sessionJWTToken
    })
    };
    render(){
        return(
            <div className='layer'>
              
                <ShowUserModal/>
                <h1>Alumnos</h1>
                <div className='background-new-user-btn'>
                    <div className='new-user-btn'>
                        <NewUser 
                            userType='Alumno'
                            loadingFlag = {this.props.StudentsReducer.loading}
                        />
                    </div>
                    <div hidden="true" className='new-user-btn'>
                      <Button onClick={this.downloadStudentReport} disabled={this.props.StudentsReducer.loading} /*</div>loading={true}*/>
                        <Icon type="file-excel" />
                        Descargar Reporte 
                      </Button>
                    </div>
                </div>
                <div className='users-table'>
                    <StudentsTable 
                        columns={this.columns} 
                        dataSource={this.props.StudentsReducer.students} 
                        rowKey = "idUsuario"
                        mountFunction={(this.props.StudentsReducer.students!==undefined)
                                        ?(()=>{})
                                        :this.studentsData} 
                        refreshFunction={this.studentsData} 
                        loadingFlag = {this.props.StudentsReducer.loading}
                    />
                </div>

            </div>
        )
    }
}
const mapStateToProps=(state)=>{
    return state
}
const mapDispatchToProps = {
    ...StudentsActions,
    ...UserActionsCreators
};
export default connect(mapStateToProps,mapDispatchToProps)(StudentsLayer);
