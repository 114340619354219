import  axios from 'axios';
import  {SERVER_URL,
        PATH_ADMIN,
        STUDENTS,
        REPORTS,
        XLSX,
        GENERAL_STUDENTS_REPORT
        } from '../../utility/AppConstants'
import { message } from  'antd'

export function getStudentsRequest(payload){
    const GET_STUDENTS_REQ_URL = SERVER_URL+PATH_ADMIN+STUDENTS;
    return( dispatch=>{
        dispatch(setLoading({loading:true}))
        return axios.get(   GET_STUDENTS_REQ_URL,
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }
                            }
        ).then( response => {
            console.log( 'Status Code: ' + response.status )
            if( response.status === 200 ){
                console.log( 'Success response -> ' + GET_STUDENTS_REQ_URL )
                console.log(response.data)
                dispatch( 
                    setStudents({
                        students:response.data,
                        loading:false
                    })
                )
            }
        }).catch( error => {
            dispatch( 
                setStudents({
                    students:{},
                    loading:false
                })
            )
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + GET_STUDENTS_REQ_URL )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + GET_STUDENTS_REQ_URL )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + GET_STUDENTS_REQ_URL )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + GET_STUDENTS_REQ_URL )
                } 
                message.error( error.response.data.details + ' ('+error.response.status+')' )
            }else{
                console.error( 'Disabled API service -> ' + GET_STUDENTS_REQ_URL )
            }
        })  
    })
}
export function getStudentsReportRequest(payload){
    const GET_STUDENTS_REPORT_REQ_URL = SERVER_URL+REPORTS+XLSX+GENERAL_STUDENTS_REPORT;
    return( dispatch=>{
        dispatch(setLoading({loading:true}))
        return axios.get(   GET_STUDENTS_REPORT_REQ_URL,
                            {
                                responseType:'blob',
                                headers:{
                                    'Authorization': payload.jwtToken
                                }
                            }
        ).then( response => {
            console.log( 'Status Code: ' + response.status )
            if( response.status === 200 ){
                // create file link in browser's memory
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                const fileName = (response.request.getResponseHeader('Content-Disposition')).split("filename=")[1]
                
                link.setAttribute('download', fileName); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
                
                console.log( 'Success response -> ' + GET_STUDENTS_REPORT_REQ_URL )
                message.success('Se Descargo Correctamente!')
                dispatch(setLoading({loading:false}))
            }
        }).catch( error => {
            dispatch(setLoading({loading:false}))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + GET_STUDENTS_REPORT_REQ_URL )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + GET_STUDENTS_REPORT_REQ_URL )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + GET_STUDENTS_REPORT_REQ_URL )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + GET_STUDENTS_REPORT_REQ_URL )
                } 
                message.error( error.response.data.details + ' ('+error.response.status+')' )
            }else{
                console.error( 'Disabled API service -> ' + GET_STUDENTS_REPORT_REQ_URL )
            }
        })  
    })
}

export function setLoading(payload){
    return{
        type:'SET_LOADING_STUDENTS',
        payload
    }
}
export function setStudents(payload){
    return{
        type:'SET_STUDENTS',
        payload
    }
}
