import React,{Component} from 'react'
import {Drawer, Form, Button, Col, Row, Input, Select, DatePicker, Icon, Transfer, Upload,Modal,Switch } from 'antd'
import NumericInput from '../input/NumericInput'
import { connect } from 'react-redux';
import * as UserActionsCreators from '../../redux/actions/UsersActions'
import * as DrawerActionsCreators from '../../redux/actions/DrawerActions'
import * as CoursesActionsCreators from '../../redux/actions/CoursesActions'
import './NewUser.css'

const { Option } = Select;
const { TextArea } = Input;
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

class NewUser extends Component{
    state = { userType: (this.props.userType === 'Alumno')?(3):(''),
              name: '',
              lastname: '',
              email: '',
              telefono: '',
              sede: '',
              nacimiento: '',
              addedCourses: [],
              newImage: [],
              previewImage:'',
              previewVisible:false,
              modalidadIntensiva:false,
              comments:''
            };

    componentDidMount() {
      console.log('send request to retrieve all courses: ' + this.props.LoginReducer.sessionJWTToken)
        this.props.getCoursesRequest({
            jwtToken: this.props.LoginReducer.sessionJWTToken,
            userType: this.props.LoginReducer.sessionInformation.user.userType,
            userId: this.props.LoginReducer.sessionInformation.user.userId,
        })
    }

    filterOption = (inputValue, option) => option.nombre.indexOf(inputValue) > -1;

    handleChange = addedCourses => {
      this.setState({ addedCourses: addedCourses});
    };

    showDrawer = () => {
        this.props.openDrawer();
    };

    onClose = () => {
      this.setState({
        newImage: []    
      })  
      this.props.closeDrawer();
        this.props.form.resetFields();
        this.setState({
          addedCourses: []    
        })
    };

    onOk = () => {
      
      this.props.form.validateFields(err => {
        if (!err) {
          let cursos = this.state.addedCourses.map(value=>{return{idCurso:value}})

          let formData = undefined
          if(this.state.newImage.length === 1){
              formData = new FormData();
              formData.append('file', this.state.newImage[0]); 
          }

          console.log('sending user signup request')
          this.props.signUpUserRequest({
            newUser: this.state,
            cursos,
            jwtToken: this.props.LoginReducer.sessionJWTToken,
            formData

          })
        }
      });
    }

    onChange = (e) => {
      this.setState({
        [e.target.id]: e.target.value
      })
    }

    onChangeDate = (date, dateString) =>{
        this.setState({
          nacimiento: dateString
        })
    }

    onChangeSelectInput = (value) => {
      this.setState({
          sede : value
      })
    }

    onChangeSelectInputUserType = (value) => {
      this.setState({
          userType : value
      })
    }

    compareToFirstPassword = (rule, value, callback) => {
      const { form } = this.props;
      if (value && value !== form.getFieldValue('password')) {
        callback('Las contraseñas no coinciden!');
      } else {
        callback();
      }
    };

    componentDidUpdate(){
      if(this.props.DrawerReducer.resetForm){
          this.setState({
            addedCourses: []
          })
          this.props.form.resetFields();
          this.props.setResetForm({
            resetForm: false
          })
          
      }
    }
    handleCloseModal = () => this.setState({ previewVisible: false });
    handlePreview = async file => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
  
      this.setState({
        previewImage: file.url || file.preview,
        previewVisible: true,
      });
    };
    onChangeSwitchMode = (value) => {
      this.setState({
        modalidadIntensiva: value
      })
    }

    render(){
    const { getFieldDecorator } = this.props.form;
    const { newImage, previewVisible, previewImage } = this.state;
    const uploadProps = {
      onRemove:       ()   => { this.setState({ newImage: [] }) },
      beforeUpload:   file => { this.setState({ newImage: [file] });
                                return false;
                              },
      onPreview: this.handlePreview,
      newImage,
      accept:'.png,.jpg,.jpeg',
      listType:'picture-card'
    };
    return (
      <div>
        <Modal visible={previewVisible} footer={null} onCancel={this.handleCloseModal}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>

        <Button type="primary" onClick={this.showDrawer} disabled={this.props.loadingFlag}>
          <Icon type="plus" /> Nuevo {(this.props.userType !== undefined)?(this.props.userType):('Usuario')}
        </Button>
        
        <Drawer
          title={"Registro "+((this.props.userType !== undefined)?((this.props.userType)):("Usuario"))}
          visible={this.props.DrawerReducer.drawerVisibility}
          bodyStyle={{ paddingBottom: 50 }}
          closable={false}
          destroyOnClose={true}
        >
          <Form layout="vertical" hideRequiredMark>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Imagen De Perfil"  style={{marginBottom:'0px'}} >
                    <Upload {...uploadProps}>
                    {newImage.length === 1 ? null : <div><Icon type="upload" />CARGAR</div>}
                  </Upload>
                </Form.Item>  
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Nombre">
                  {getFieldDecorator('name', {
                    rules: [{ required: true, message: 'Introduzca su nombre' }],
                  })(<Input id='name' placeholder="Introduzca su nombre" onChange={this.onChange} maxLength={40}/>)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Apellido">
                  {getFieldDecorator('lastname', {
                    rules: [{ required: false}],
                  })(<Input id='lastname' placeholder="Introduzca su apellido" onChange={this.onChange} maxLength={40}/>)}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Email">
                  {getFieldDecorator('email', {
                    rules: [{type: 'email', message: 'E-mail invalido!'},
                            {required: true, message: 'Introduzca su email' }],
                  })(<Input placeholder="Introduzca su email" onChange={this.onChange} maxLength={60}/>)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Telefono">
                  {getFieldDecorator('telefono', {
                    rules: [{ required: false}],
                  })(<NumericInput id='telefono' placeholder="Introduzca su telefono" onChange={this.onChange} maxLength={20}/>)}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Sede">
                  {getFieldDecorator('sede', {
                    rules: [{ required: true, message: 'Seleccione una sede' }],
                  })(
                    <Select placeholder="Sede" onChange={this.onChangeSelectInput}>
                      {this.props.UsersReducer.sedes !== undefined ? (
                          this.props.UsersReducer.sedes.map((sede, id)=> (
                              <Option key={id} value={sede.idSede}>{sede.nombre + ' - ' + sede.ciudad}</Option>
                          ))
                      ) : (
                          <Option  value='0'>NO DISPONIBLES</Option>
                      )}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Fecha de nacimiento">
                  {getFieldDecorator('nacimiento', {
                    rules: [{ required: true, message: 'Ingrese una fecha de nacimiento' }],
                  })(
                    <DatePicker placeholder="Seleccione una fecha" onChange={this.onChangeDate} style={{width:'100%'}} />
                  )}
                </Form.Item>
              </Col>
            </Row>

            {(this.props.userType ==='Usuario')?(
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Tipo De Usuario">
                    {getFieldDecorator('adminType', {
                      rules: [{ required: true, message: 'Seleccione un tipo de administrador' }],
                    })(
                      <Select placeholder="Tipo Usuario" onChange={this.onChangeSelectInputUserType}>
                        {this.props.UsersReducer.userType !== undefined ? (
                            this.props.UsersReducer.userType.map((tipo, id)=> (tipo.idTipoUsuario !== 3)?(
                              <Option key={id} value={tipo.idTipoUsuario}>{tipo.tipoUsuario}</Option>
                              ):(null)
                            )
                        ) : (
                            <Option  value='0'>NO DISPONIBLES</Option>
                        )}
                      </Select>,
                    )}
                  </Form.Item>
                </Col>
              </Row>
            ):(
              null
            )}
            {(this.props.userType === 'Alumno' || this.state.userType === 2)?(
              <div>
                {(this.props.userType === 'Alumno')
                ?(
                  <div>
                    <Row>
                      <Col span={24}>
                        <Form.Item label="Comentarios">
                          <TextArea id='comments' placeholder="Introduzca un comentario" onChange={this.onChange} rows={2} maxLength={250} />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={24}>
                        <Form.Item label="Modalidad Intensiva" >
                          <Switch style={{float:"left"}} onChange={this.onChangeSwitchMode} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                )
                :(null)}
                <Row>
                  <Col span={24}>
                    <Form.Item label="AGREGAR CURSOS">
                        <Transfer 
                          style={{textAlign:"start"}}
                          dataSource={this.props.CoursesReducer.courses}
                          targetKeys={this.state.addedCourses}
                          rowKey={record => record.idCurso}
                          listStyle={{
                            width: '45%',
                          }}
                          titles={['Sin agregar', 'Agregados']}
                          showSearch
                          filterOption={this.filterOption}
                          onChange={this.handleChange}
                          render={item => (
                                            <span>
                                              {item.nombre+' '}
                                              <Icon 
                                                type="tag" 
                                                theme="twoTone" 
                                                twoToneColor={item.etiqueta} 
                                              />
                                            </span>
                                          )}
                          locale={{
                            itemUnit: "",
                            itemsUnit: "",
                            notFoundContent: "Lista vacia",
                            searchPlaceholder: "Buscar Curso",
                          }}
                        />
                    </Form.Item>    
                  </Col>
                </Row>
              </div>
            ):(
              null
            )}
          </Form>
          <div
            style={{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={this.onClose} style={{ marginRight: 8 }} disabled={this.props.UsersReducer.loading}>
              Cancelar
            </Button>
            <Button onClick={this.onOk} type="primary" loading={this.props.UsersReducer.loading} >
              Registrar
            </Button>
          </div>
        </Drawer>
      </div>
    );
    }
}

const mapStateToProps=(state)=>{
  return state
}

const mapDispatchToProps = {
  ...UserActionsCreators,
  ...DrawerActionsCreators,
  ...CoursesActionsCreators
}

export default connect (mapStateToProps,mapDispatchToProps)(Form.create()(NewUser));