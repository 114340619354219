import React,{Component} from 'react'
import {Drawer,Avatar,Spin,List} from 'antd'
import { connect } from 'react-redux';
import * as AssistsActions from '../../redux/actions/AssistsActions'
import { UserOutlined } from '@ant-design/icons';

class AssistanceTracker extends Component{
    state={
        assistList:null
    }
    componentDidUpdate(){
        if(this.props.student!==null && this.props.AssistsReducer.correctRetrieve===true){
            if(this.state.assistList === null){
                this.props.setCorrectRetrieve({correctRetrieve:null})
                if(this.props.view === "AssistanceList"){
                    for (let student of this.props.AssistsReducer.studentsRegistredInClass) {
                        if(student.idUsuario === this.props.student.idUsuario) {
                            if(student.assists!==undefined){
                                this.setState({
                                    assistList:student.assists
                                })
                            }
                            break;
                        }
                    }
                }
                else if(this.props.view === "UserDetails"){
                    console.log(this.props.AssistsReducer.assistancesInClass)
                    this.setState({
                        assistList:this.props.AssistsReducer.assistancesInClass
                    })
                }
            }
        }
    }
    closeFunction=()=>{
        this.props.closeAssistancesInClass()
        this.props.trackerAssists({showTrackerAssists:false})
        this.props.setCorrectRetrieve({correctRetrieve:null})
        this.setState({assistList:null})
    }
    dateFormatter(date){
        let dateRegex = date.match(/^(\d{4})[-./](\d{1,2})[-./](\d{1,2})$/),year,month,day;
        if(dateRegex){
            year = dateRegex[1]
            month= dateRegex[2]
            day  = dateRegex[3]
            return day + '/' + month + '/' + year.toString().substr(2,2) // Format: DD/MM/YY
        }
        return 'Invalid Date'
    }
    listItem(item, index){
        let studentAssistance = null
        let date

        if(this.state.assistList !== null){
            for (let assistance of this.state.assistList) {
                if(assistance.tema.idTema === item.idTema){
                    studentAssistance = assistance;
                    date = this.dateFormatter(assistance.fecha)
                    break;
                }
            }
        }

        return (
            <List.Item actions={
                studentAssistance != null
                ? [<div >{studentAssistance.updatedByName}</div>, <div>{date}</div>] //DD/MM/AA
                : null }
            >
                <List.Item.Meta
                    avatar={
                        <Avatar style={
                            studentAssistance != null
                            ? { backgroundColor: 'green' }
                            : null}
                        >
                            {index}
                        </Avatar> }
                    title={item.tituloTema}
                    description={item.descripcionTema}
                />
            </List.Item>
        )
    }
    render(){
        return(
            <Drawer
              key={this.props.keyComponent}
              title={
                (this.props.student!==undefined && this.props.student!==null)?(
                    <div>
                        {(this.props.student.fotoUsuario === null)
                        ?<Avatar style={{marginRight:'10px'}} icon={<UserOutlined />}/>
                        :<Avatar style={{marginRight:'10px'}} src={`data:image/jpeg;base64,${this.props.student.fotoUsuario.archivo}`} />}
                        {this.props.student.nombre + 
                            (this.props.student.apellido === null?'':(' '+this.props.student.apellido))
                        }
                    </div>
                )
                :(null)
              }
              placement="right"
              closable={(this.props.AssistsReducer.loading!==undefined)?(!this.props.AssistsReducer.loading):false}
              onClose={(this.props.AssistsReducer.loading===true)?null:()=>this.closeFunction()}
              visible={this.props.AssistsReducer.showTrackerAssists}
              getContainer={false}
              style={{ position: 'absolute'}}>
                <Spin spinning={this.props.AssistsReducer.loading}>
                {
                    (this.props.student!==null)
                    ?(
                        <List
                            itemLayout="horizontal"
                            dataSource={this.props.view === "UserDetails" ? this.props.AssistsReducer.lessons :this.props.lessons}
                            renderItem={(item,index) => (
                                this.listItem(item,index+1)
                            )}
                        />
                    )
                    :(null)
                }
                </Spin>
            </Drawer>
        )
    }
}
const mapStateToProps=(state)=>{
  return state
}
export default connect(mapStateToProps,AssistsActions)(AssistanceTracker);