import React,{Component} from 'react'
import {Modal,Spin} from 'antd'

import UserInfoForm from './UserInfoForm'
import CoursesList from '../courses/CoursesList'
import ClassAssignments from '../classes/ClassAssignments'
import {USUARIO} from '../../utility/AppConstants'

import { connect } from 'react-redux';
import * as UserActionsCreators from '../../redux/actions/UsersActions'

import { Tabs } from 'antd'
const { TabPane } = Tabs;

class ShowUserModal extends Component{
    
    handleClose = () => {
        this.props.showUser({
            showUser : false,
            userToShow : undefined
        })
    }
    
    render(){
        return(
            <div>
                {(this.props.UsersReducer.userToShow !== undefined)?(
                    <Modal
                    visible={this.props.UsersReducer.showUser}
                    title={(this.props.UsersReducer.userToShow !== undefined)
                            ?   (   (this.props.UsersReducer.userToShow.nombre).toUpperCase() 
                                    +   ' ' 
                                    +   (   (this.props.UsersReducer.userToShow.apellido !== null) 
                                            ?   ((this.props.UsersReducer.userToShow.apellido).toUpperCase()) 
                                            :   ('')
                                        )  
                                )
                            :   ('')}
                    closable={!this.props.UsersReducer.loading}
                    onCancel={!this.props.UsersReducer.loading?this.handleClose:null}
                    footer={false}
                    width={800}
                    style={{position:'relative', overflow:'hidden'}}
                    >
                        <Spin spinning={this.props.UsersReducer.loading === true}>
                            {(this.props.UsersReducer.userToShow.tipoUsuario.idTipoUsuario === 1)?(
                                <Tabs defaultActiveKey="1">
                                    <TabPane tab="INFORMACION PERSONAL" key="1">
                                        <UserInfoForm />
                                    </TabPane>
                                </Tabs>
                            ):(
                                <Tabs defaultActiveKey="1">
                                    <TabPane tab="INFORMACION PERSONAL" key="1">
                                        <UserInfoForm />
                                    </TabPane>
                                    <TabPane tab="CURSOS" key="2">
                                        <CoursesList />
                                    </TabPane>
                                    <TabPane tab="CLASES" key="3">
                                        <ClassAssignments 
                                            view={USUARIO}
                                            loadingFlag={this.props.UsersReducer.loading} 
                                            visibilityFlag={this.props.UsersReducer.userToShow} 
                                            courses={this.props.UsersReducer.userToShow.tipoUsuario.idTipoUsuario === 2 
                                                    ?this.props.UsersReducer.userToShow.maestroCursos 
                                                    : (this.props.UsersReducer.userToShow.courses!== undefined) ? this.props.UsersReducer.userToShow.courses : null} 
                                            idUserType={this.props.UsersReducer.userToShow.tipoUsuario.idTipoUsuario} 
                                            idUser={this.props.UsersReducer.userToShow.idUsuario} 
                                            studentLimitHrs ={this.props.UsersReducer.userToShow.intensiveMode==="1" ? 8 : 4}
                                            userEnable ={this.props.UsersReducer.userToShow.userEnabled==="1" ? true : false}
                                        />
                                    </TabPane>
                                </Tabs>
                            )}
                        </Spin>
                    </Modal>
                ):(null)}
            </div>
        )
    }
}
const mapStateToProps=(state)=>{
    return state
}
export default connect(mapStateToProps,UserActionsCreators)(ShowUserModal);