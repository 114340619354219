import React,{Component} from 'react'
import { connect } from 'react-redux';
import NewUser from '../../components/users/NewUser'
import UsersTable from '../../components/table/InfoTable'
import SedesTable from '../../components/table/InfoTable'
import NewSede from '../../components/sede/NewSede'
import * as AdministratorsActions from '../../redux/actions/AdministratorsActions'
import * as TeacherActions from '../../redux/actions/TeacherActions'
import * as UserActionsCreators from '../../redux/actions/UsersActions'
import { Tabs, Divider,Input,Button } from 'antd'
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import ShowUserModal from '../../components/users/ShowUserModal'
import '../layers.css'

const { TabPane } = Tabs;

class AdministrationLayer extends Component{

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
    };
    
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    
    constructor(props){
        let getColumnSearchProps = dataIndex => ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
              <div style={{ padding: 8 }}>
                <Input
                  ref={node => {
                    this.searchInput = node;
                  }}
                  placeholder={`Buscar por ${(dataIndex==='username')?'correo':dataIndex}`}
                  value={selectedKeys[0]}
                  onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                  onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                  style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                
                  <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                  >
                    Buscar
                  </Button>
                  <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Limpiar
                  </Button>
                
              </div>
            ),
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilter: (value, record) =>{
                let res = false;
                (dataIndex==='nombre' && record.apellido !== null)?(
                  res = (record.nombre.trim() +' '+record.apellido.trim()).toLowerCase().includes(value.trim().toLowerCase())
                ):(
                  res = record[dataIndex].toString().toLowerCase().includes(value.trim().toLowerCase())
                )
                return res
            },
            onFilterDropdownVisibleChange: visible => {
              if (visible) {
                setTimeout(() => this.searchInput.select());
              }
            },
            render: text =>
              this.state.searchedColumn === dataIndex ? (
                <Highlighter
                  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                  searchWords={[this.state.searchText]}
                  autoEscape
                  textToHighlight={text.toString()}
                />
              ) : (
                text
              ),
        });

        super(props)
        this.state = {
            searchText: '',
            searchedColumn: '',
        };
        this.usersColumns = [
            {title: 'NOMBRE', dataIndex: 'nombre',key: 'nombre',...getColumnSearchProps('nombre'),
                render: (text, record) => (
                    (record.apellido === null) ? (text) : (text + ' ' + record.apellido)
                )
            },
            {title: 'CORREO', dataIndex: 'username',key: 'username',...getColumnSearchProps('username')},
            {title: 'TIPO DE USUARIO', dataIndex: 'tipoUsuario.tipoUsuario',key: 'idUsuario'+1},
            {title: 'SUCURSAL', dataIndex: 'sede.nombre',key: 'idUsuario'},
            {title: '',key: 'OPC',
                render: (text, record) => (
                    <span>
                        <a href='# ' key={record.idCurso} onClick={()=>this.showUser(record)}>Mostrar</a>
                    </span>
                ),
            },
        ];
        this.sedesColumns = [
            {title: 'NOMBRE', dataIndex: 'nombre',key: 'nombre',},
            {title: 'CIUDAD', dataIndex: 'ciudad',key: 'ciudad'}
        ];
    }
    showUser = (record) => {
        console.log('showing user..')
        console.log(record)
        this.props.showUser({
            showUser : true,
            userToShow : record
        })
        if(record.tipoUsuario.idTipoUsuario === 2){
            console.log('retrieving Teachers information {teacher id: '+record.idUsuario+'}.....')
            this.props.getTeacherRequest({
                jwtToken: this.props.LoginReducer.sessionJWTToken,
                teacherId: record.idUsuario
            })
        }
    }
    administratorsData = () => {
        console.log('send request to retrieve all administrators: ' + this.props.LoginReducer.sessionJWTToken)
        this.props.getAdministratorsRequest({
            jwtToken: this.props.LoginReducer.sessionJWTToken
        })
    }
    sedesData = () => {
        console.log('send request to retrieve all sedes: ' + this.props.LoginReducer.sessionJWTToken)
        this.props.getAllSedes({
            jwtToken: this.props.LoginReducer.sessionJWTToken
        })
    }
    userTypeData  = () => {
        console.log('send request to retrieve all user types: ' + this.props.LoginReducer.sessionJWTToken)
        this.props.getAllUserTypes({
            jwtToken: this.props.LoginReducer.sessionJWTToken
        })
    }
    UNSAFE_componentWillMount(){
        this.sedesData();
        this.userTypeData();
    }
    
    render(){
        return(
            <div className='layer'>
                <ShowUserModal/>
                <h1>Administracion</h1>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="USUARIOS" key="1">
                    <div className='background-new-user-btn'>
                        <div className='new-user-btn'>
                            <NewUser 
                                userType='Usuario'
                                loadingFlag = {this.props.AdministratorsReducer.loading}
                            />
                        </div>
                    </div>
                    <div className='users-table'>
                        <UsersTable 
                            columns={this.usersColumns} 
                            dataSource={this.props.AdministratorsReducer.administrators} 
                            rowKey = "idUsuario"
                            mountFunction={(this.props.AdministratorsReducer.administrators!==undefined)
                                            ?(()=>{})
                                            :this.administratorsData} 
                            refreshFunction={this.administratorsData} 
                            loadingFlag = {this.props.AdministratorsReducer.loading}
                        />
                    </div>
                    </TabPane>
                    <TabPane tab="SEDES" key="2">
                        <NewSede/>
                        <Divider/>
                        <SedesTable
                            columns={this.sedesColumns} 
                            dataSource={this.props.UsersReducer.sedes} 
                            rowKey = "idSede"
                            mountFunction={(this.props.UsersReducer.sedes!==undefined)
                                            ?(()=>{})
                                            :this.sedesData} 
                            refreshFunction={this.sedesData} 
                            loadingFlag = {this.props.SedeReducer.loading}
                        />
                    </TabPane>
                </Tabs>
            </div>  
        )
    }
}
const mapStateToProps=(state)=>{
    return state
}
const mapDispatchToProps = {
    ...AdministratorsActions,
    ...UserActionsCreators,
    ...TeacherActions
};
export default connect(mapStateToProps,mapDispatchToProps)(AdministrationLayer);