import React,{Component} from 'react'
import { Layout, Icon, Row, Col, Popconfirm } from 'antd';
import { connect } from 'react-redux';
import * as actionCreator from '../../redux/actions/LoginActions'

const { Header } = Layout;

class MenuHeader extends Component{
  logOut = () => {
    localStorage.clear();
    this.props.userLogOut()
  }
  render(){
      return(
          <Header style={{ background: '#fff', padding: '0 10px' }}>
              <Row type="flex" justify="start">
                <Col span={1}>
                  <Icon
                      className="trigger"
                      type={this.props.collapsed ? 'menu-unfold' : 'menu-fold'}
                      style={{ cursor: 'pointer' }}
                      onClick={this.props.toggle}
                  />
                </Col>
                <Col span={22}>
                  <img src={require('../../images/axss-navbar.png')} style={{width:'85px'}} alt='Axss' />
                </Col>
                <Col span={1}>
                <Popconfirm
                            placement="bottomRight"
                            title="¿CERRAR SESION?"
                            icon={<Icon type="question-circle-o" style={{ color: 'red' }}/>}
                            okText="Cerrar"
                            okType="danger"
                            onConfirm={this.logOut}
                            cancelText="Cancelar"
                        >
                            <Icon
                              type="poweroff"
                              style={{ cursor: 'pointer', color:'red'}}
                            />
                        </Popconfirm>
                  
                </Col>
                
              </Row>
          </Header>
      )
  }
}
const mapStateToProps=(state)=>{
  return state
}
export default connect(mapStateToProps,actionCreator)(MenuHeader);