import  axios from 'axios';
import  {SERVER_URL,
        NEWS,
        ADD
        } from '../../utility/AppConstants'
import { message } from  'antd'

export function getNews(payload){
    const GET_NEWS_URL = SERVER_URL+NEWS;
    return( dispatch=>{
        dispatch(newsRequest({inProgress:true}))
        return axios.get(   GET_NEWS_URL,
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }
                            }
        ).then( response => {
            console.log( 'Status Code: ' + response.status )
            if( response.status === 200 ){
                console.log( 'Success response -> ' + GET_NEWS_URL )
                console.log(response.data)
                dispatch(setNews({newsList:response.data}))
                dispatch(newsRequest({inProgress:false}))
            }
        }).catch( error => {
            dispatch(setNews({newsList:{}}))
            dispatch(newsRequest({inProgress:false}))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + GET_NEWS_URL )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + GET_NEWS_URL )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + GET_NEWS_URL )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + GET_NEWS_URL )
                } 
            }else{
                console.error( 'Disabled API service -> ' + GET_NEWS_URL )
            }
        })  
    })
}
export function addNews (payload){
    const ADD_NEWS_REQ = SERVER_URL+NEWS+ADD;
    return( dispatch=>{
        dispatch(addNewsRequest({addingNewInProgress:true}))
        return  axios.post( ADD_NEWS_REQ, 
                            {
                                titulo: payload.title,
                                contenido: payload.content,
                                fechaVencimiento: payload.dueDate
                            },
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }  
                            }
        ).then( response=>{
            console.log( 'Status Code: ' + response.status )
            if(response.status === 201){
                message.success(response.data.details);
                console.log( 'Success response -> ' + ADD_NEWS_REQ )
                dispatch(newCreated({ newCreated: response.data.object }))
                dispatch(addNewsRequest({addingNewInProgress:false}))
            }
        }).catch( error => {
            dispatch(addNewsRequest({addingNewInProgress:false}))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + ADD_NEWS_REQ )
                    message.error( 'TU SESION HA EXPIRADO!' )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + ADD_NEWS_REQ )
                    message.error( 'NO ESTAS AUTORIZADO PARA REALIZAR ESTA ACCION!' )
                }
                else{
                    message.error( error.response.data.details + ' ('+error.response.status+')' )
                } 
                
            }else{
                console.log( 'Disabled API service -> ' + ADD_NEWS_REQ )
            }
        })
    })
}
export function deleteNews (payload){
    const DELETE_NEWS_REQ = SERVER_URL+NEWS+payload.idToDelete;
    return( dispatch=>{
        dispatch(deleteNewsRequest({deletingNewInProgress:true}))
        return  axios.delete( DELETE_NEWS_REQ, 
                                {
                                    headers:{
                                        'Authorization': payload.jwtToken,
                                    
                                        'Content-Type': "application/x-www-form-urlencoded;"
                                    }  
                                }
        ).then( response=>{
            console.log( 'Status Code: ' + response.status )
            if(response.status === 200){
                message.success(response.data.details);
                console.log( 'Success response -> ' + DELETE_NEWS_REQ )
                dispatch(newDeleted({idDeleted:response.data.object.idNoticia}))
                dispatch(deleteNewsRequest({deletingNewInProgress:false}))
            }
        }).catch( error => {
            dispatch(deleteNewsRequest({deletingNewInProgress:false}))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + DELETE_NEWS_REQ )
                    message.error( 'TU SESION HA EXPIRADO!' )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + DELETE_NEWS_REQ )
                    message.error( 'NO ESTAS AUTORIZADO PARA REALIZAR ESTA ACCION!' )
                }
                else{
                    message.error( error.response.data.details + ' ('+error.response.status+')' )
                } 
                
            }else{
                console.log( 'Disabled API service -> ' + DELETE_NEWS_REQ )
            }
        })
    })
}
export function newsDetailsModal(payload){
    return{
        type:'NEWS_DETAILS_MODAL',
        payload
    }
}
export function addNewsModal(payload){
    return{
        type:'ADD_NEWS_MODAL',
        payload
    }
}
export function newsRequest(payload){
    return{
        type:'NEWS_REQ',
        payload
    }
}
export function setNews(payload){
    return{
        type:'SET_NEWS',
        payload
    }
}
export function addNewsRequest(payload){
    return{
        type:'ADD_NEWS_REQ',
        payload
    }
}
export function newCreated(payload){
    return{
        type:'NEW_CREATED',
        payload
    }
}
export function setCreationSuccess(payload){
    return{
        type:'SET_CREATION_SUCCESS',
        payload
    }
}
export function deleteNewsRequest(payload){
    return{
        type:'DELETE_NEWS_REQ',
        payload
    }
}
export function newDeleted(payload){
    return{
        type:'NEW_DELETED',
        payload
    }
}
export function setDeletedSuccess(payload){
    return{
        type:'SET_DELETED_SUCCESS',
        payload
    }
}

