import React,{Component} from 'react'
import {Alert, Button, Spin } from 'antd'
import { connect } from 'react-redux';
import * as NotificationsActions from '../../redux/actions/NotificationsActions'

class NotificationList extends Component{


    confirmAndCloseNotification  =  async(notificacion) =>  {
        if(this.props.LoginReducer.sessionInformation !== undefined){
            let userType = this.props.LoginReducer.sessionInformation.user.userType
            let jwtToken = this.props.LoginReducer.sessionJWTToken

            console.log('send request to confirm notification with ID: ' +notificacion.idNotif+' with jwtToken: '+jwtToken)
            await this.props.confirmNotificationRequest({
                userType,
                notificacion,
                jwtToken
            })
            this.setState({
                newNotifications: this.props.NotificationsReducer.notifications,
                history: this.props.NotificationsReducer.seenNotifications
            })
        }
    };
    buildNotificationTitle = (notification) => {
        if(this.props.LoginReducer.sessionInformation !== undefined){
            let courseName = this.getCourseName(notification.idCourse)
            let notificationType = this.getNotificationType(notification.notiType)

            return (<><b>{notificationType.concat(' (').concat(courseName).concat(')')}</b></>)
        }
        return 'unable to get notification title'
    }
    getCourseName = (courseId) => {
        let coursesFiltered = this.props.CoursesReducer.availableCourses.filter( course =>  course.idCurso === courseId)
        let courseName = (coursesFiltered !== undefined && coursesFiltered !== null && coursesFiltered.length > 0) ? coursesFiltered[0].nombre : 'unknowed'
        return courseName
    }
    getNotificationType = (notiType) => {
        let notiTypeId = 'unknowed'
        if(notiType === 1){
            notiTypeId = 'BAJA DE CURSO'
        }else if(notiType === 2){
            notiTypeId = 'CURSO COMPLETADO'
        }
        return notiTypeId
    }
    buildNotificationDescription = (notification) => {
        let specificDes = ""
        if(notification.notiType === 1){
            specificDes = "sido dado de baja del curso"
        }else if(notification.notiType === 2){
            specificDes = "finalizado correctamente el curso"
        }

        if(this.props.LoginReducer.sessionInformation !== undefined){
            let userType = this.props.LoginReducer.sessionInformation.user.userType //1 admin & 3 student
            let courseName = this.getCourseName(notification.idCourse)
            let description = (userType === 1) ? this.getDescriptionForAdmin(notification.user) : 'Has '
            
            return description.concat(specificDes).concat(' "').concat(courseName).concat('"')
        }
        return 'unable to get description'
    }
    getDescriptionForAdmin = (user) => {
        let userName = this.getUserName(user)
        return 'El Alumno '.concat(userName).concat(' ha ') 
    }
    getUserName = (user) => {
        let firstName = (user.nombre !== undefined && user.nombre !== null) ? user.nombre : ''
        let lastName = (user.apellido !== undefined && user.apellido !== null) ? user.apellido : ''
        return firstName.concat(' ').concat(lastName)
    }
    getNotificationSendDate = (notification) => {
        return notification.sendDate
    }
    getAlertContent = (notification) => {
        return (
            <>
                <b>{"Fecha: " + this.getNotificationSendDate(notification)}</b><br/>
                {this.buildNotificationDescription(notification)}
            </>
        )
    }
    getNotiType = (notification) => {
        let notiType = notification.notiType
        let type = ''
        if(notiType === 1){
            type = 'error'
        }else if(notiType === 2){
            type = 'success'
        }
        return type
    }
    buildNotificationAlertItem = (notification) => {
        let viewedNotificationsFlag = this.props.viewedNotifications
        return (
            <Alert 
                key={notification.idNotif}
                message={this.buildNotificationTitle(notification)}
                description={this.getAlertContent(notification)}
                type={this.getNotiType(notification)}
                showIcon
                style={{maxWidth:"1000px", margin: "0 auto", marginBottom:"20px"}}
                closeText={
                    (viewedNotificationsFlag === false)
                    ?
                        <Button size="small" type="text" onClick={()=>this.confirmAndCloseNotification(notification)} style={{border:'1px solid'}}>
                            <b>Confirmar</b>
                        </Button>
                    : null}
            />
        )
    }
    callNotificationsCounterService = async(jwtToken, userId, userType, viewedNotificationsFlag) => {
        console.log('Send request to retrieve the amount of notifications for userId: ' +userId+' of userType: '+userType+' with jwtToken: '+jwtToken)
        console.log('Viewed notifications flag : ' + viewedNotificationsFlag)
        await this.props.getNotificationsSize({
            jwtToken,
            userId,
            userType,
            viewed: viewedNotificationsFlag
        })
    }
    callNotificationListService = async(jwtToken, userId, userType, viewedNotificationsFlag) => {
        console.log('There is new notifications to display.')
        console.log('Send request to retrieve new notifications for userId: ' +userId+' of userType: '+userType+' with jwtToken: '+jwtToken)
        console.log('Viewed notifications flag : ' + viewedNotificationsFlag)
        await this.props.getUserNotificationsRequest({
            jwtToken,
            userId,
            userType,
            seen: viewedNotificationsFlag
        })
    }
    loadNotificationList = async () => {
        console.log("Loading Notifications.......")
        if(this.props.LoginReducer.sessionInformation !== undefined){
            let userType = this.props.LoginReducer.sessionInformation.user.userType
            if(userType === 1 || userType === 3){
                let viewedNotificationsFlag = this.props.viewedNotifications
                let jwtToken = this.props.LoginReducer.sessionJWTToken
                let userId = this.props.LoginReducer.sessionInformation.user.userId
                let notificationList = (viewedNotificationsFlag) ? this.props.NotificationsReducer.seenNotifications : this.props.NotificationsReducer.notifications

                await this.callNotificationsCounterService(jwtToken, userId, userType, viewedNotificationsFlag);

                let latestNotificationsSize = (viewedNotificationsFlag) ? this.props.NotificationsReducer.viewedNotificationsSize : this.props.NotificationsReducer.unviewedNotificationsSize
                console.log("latest notification list size" + latestNotificationsSize)

                let previousNotifSize = (notificationList !== undefined) ?  notificationList.length : undefined
                console.log("previous notification list size:" + previousNotifSize)

                if(previousNotifSize === undefined || previousNotifSize !== latestNotificationsSize){
                    if(this.props.NotificationsReducer.rqInProgressForComponent !== true){
                        await this.callNotificationListService(jwtToken, userId, userType, viewedNotificationsFlag)    
                    }
                }else{
                    console.log('There is not new notifications to display.')
                }
            }
        }
    }

    componentDidMount() {
        console.log("NotificationList component is now mounted!")
        this.loadNotificationList()
    }

    render(){
        let notifList = undefined
        if(this.props.viewedNotifications !== undefined){
            notifList = (this.props.viewedNotifications) ? this.props.NotificationsReducer.seenNotifications : this.props.NotificationsReducer.notifications
        }
        return(
                <Spin spinning={this.props.NotificationsReducer.rqInProgressForComponent !== undefined && this.props.NotificationsReducer.rqInProgressForComponent === true} style={{paddingTop:'30px'}}>
                    {
                        (notifList === undefined || notifList.length<=0) 
                            ?   <h2>Sin notificaciones</h2>
                            :   notifList.map(noti => this.buildNotificationAlertItem(noti))
                    }
                </Spin>
        )
    }
}
const mapStateToProps=(state)=>{
    return state
}
export default connect(mapStateToProps, NotificationsActions)(NotificationList);