export const LOCAL_SERVER = 'http://localhost:8080/'
export const PROD_SERVER = 'https://axssapi.herokuapp.com/'
export const SERVER_URL = PROD_SERVER

export const PATH_ADMIN = 'api/admin/'
export const PATH_USER = 'api/user/'
export const PATH_PWD = 'pwd/change/'

export const LOGIN_URL = 'login'
export const UPDATE = 'update/'
export const SING_UP_USER = 'sign-up'
export const USER_UPDATE = 'update'
export const COURSE_UPDATE = 'update'
export const STUDENTS = 'students'
export const TEACHERS = 'teachers'
export const ADMINISTRATORS = 'administrators'
export const SEDES = 'sedes'
export const ADD = 'add'
export const DELETE = 'delete'
export const EDIT = 'edit'
export const USER_TYPES = 'user-types'
export const COURSES = 'courses'
export const CLASSES = '/classes'
export const CLASSES_II = 'classes/'
export const UPDATE_ASSIGNMENTS = '/updateAssignments'
export const USER_IMAGE = 'user-image/'
export const UPDATE_TEACHER = 'updateTeacher/'
export const USER = 'user/'
export const PHOTO = 'photo/'
export const ADD_TEACHER = 'addTeacher/'
export const LESSON = 'lesson/'
export const PASSWORD = 'pwd'
export const REQUEST = 'request'
export const SEND_INVITATION = 'send-invitation'
export const NEWS = 'news/'
export const CLASSES_UNENROLLMENT = 'classes-unenrollment/'
export const REPORTS = 'reports/'
export const XLSX = 'xlsx/'
export const GENERAL_STUDENTS_REPORT = 'ReporteGeneralAlumnos'
export const DUE_DATE = 'due-date'
export const NOTIFICATIONS = 'notifications/'
export const FINISH_COURSE = 'finish-course/'

export const COURSES_COLLORS = [
    '#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#ffffff', '#000000'
]
export const COURSES_DURATION = [
    '0','1', '2', '3', '4', '5', '6'
]



export const USUARIO = 'usuario'
export const ADMINISTRADOR = 'administrador'
export const INSTRUCTOR = 'instructor'