import React, {Component} from 'react'
import {Row, Col, Icon, Spin} from 'antd'
import { connect } from 'react-redux';
import * as  NewsActions from '../../redux/actions/NewsActions'
import New from './New'
import AddNews from './AddNews'
import NewDetails from './NewDetails'
import './NewsPanel.css'

class NewsPanel extends Component{
    state={
        addNewsVisible:false
    }
    openAddNews = () => {
        console.log('click on open AddNews modal')
        this.setState({
            addNewsVisible:true
        })
    }
    closeAddNews = (form) => {
        console.log('click on close AddNews modal')
        this.setState({
            addNewsVisible:false
        })
    } 
    newsData = () => {
        console.log('send request to retrieve latest news: ' + this.props.LoginReducer.sessionJWTToken)
        this.props.getNews({
            jwtToken: this.props.LoginReducer.sessionJWTToken
        })
    }

    UNSAFE_componentWillMount() {
        this.newsData()
    }
    render(){
        let sessionInformation= this.props.LoginReducer.sessionInformation 
        return(
            <Spin spinning={this.props.NewsReducer.inProgress !== undefined ? this.props.NewsReducer.inProgress : false}>

            
                <Row type="flex" justify="start" align="top" className='news-panel'>
                    <Row type="flex" justify="space-between" style={{width:'100%'}} >
                        <div className='panel-title-news' >
                            Noticias
                        </div>
                        {
                            (sessionInformation !== undefined ? sessionInformation.user.userType === 1 : false ) ? 
                                <div className='add-icon' style={{float:'right'}}>
                                    <Icon type="plus" onClick={() => this.openAddNews()} />
                                    <AddNews visible={this.state.addNewsVisible} closeFunction={this.closeAddNews}/>
                                </div>
                            : null
                        }
                    </Row>
                    
                    <Col xs= {24} >
                        <Row type="flex" justify="center" align="top"  className='panel-row-news'>
                            {
                                this.props.NewsReducer.newsList !== undefined ?
                                (
                                    this.props.NewsReducer.newsList.length === 0 
                                    ? <div>No hay noticias disponibles</div>
                                    : this.props.NewsReducer.newsList.map((news) => 
                                        <Col xs={24}  md={20} lg={24} xl={12} xxl={10} >
                                            <New new={news}/>
                                        </Col>
                                    )
                                )
                                :(
                                    this.props.NewsReducer.inProgress !== undefined ? (this.props.NewsReducer.inProgress ? <div>CARGANDO NOTICIAS</div>:<div>No hay noticias disponibles</div>) : <div>No hay noticias disponibles</div>
                                )
                            }
                            <NewDetails details={this.props.NewsReducer.newsDetails}/>
                        </Row>
                    </Col>
                </Row>
            </Spin>
        )
    }
}
const mapStateToProps=(state)=>{
    return state
}
export default connect(mapStateToProps,NewsActions)(NewsPanel);
