import  { ADD } from '../../utility/AppConstants'
const UsersReducer=(state={}, action)=>{
    if(action.type === 'SET_LOADING_USERS'){
        return{
            ...state,
            loading: action.payload.loading,
        }
    }
    else if(action.type === 'SET_UPDATED_DATA_USER'){
        return{
            ...state,
            updatedData: action.payload.updatedData,
            loading: action.payload.loading,
        }
    }
    else if(action.type === 'UPDATE_INTENSIVE_MODE'){
        let toShow = {...state.userToShow}
        toShow.intensiveMode = action.payload.intensiveMode
        toShow.userEnabled = action.payload.userEnabled
        return{
            ...state,
            userToShow: toShow
        }
    }
    else if(action.type === 'UPDATE_FAIL'){
        return{
            ...state,
            updateFail: action.payload.updateFail
        }
    }
    else if(action.type === 'SET_SEDES'){
        return{
            ...state,
            sedes: action.payload.sedes,
            loading: action.payload.loading,
        }
    }
    else if(action.type === 'SET_USER_TYPES'){
        return{
            ...state,
            userType: action.payload.userType,
            loading: action.payload.loading,
        }
    }
    else if(action.type === 'SHOW_USER'){
        return{
            ...state,
            showUser: action.payload.showUser,
            userToShow: action.payload.userToShow,
        }
    }else if(action.type === 'SHOW_USER_UPDATE'){
        return{
            ...state,
            userToShow: {...state.userToShow,...action.payload.showUserUpdate},
            loading: action.payload.loading,
            updatedData: action.payload.updatedData,
        }
    }else if(action.type === 'REFRESH_COURSES'){
        return{
            ...state,
            refreshCourses: action.payload.refreshCourses,
        }
    }
    else if(action.type === 'STUDENT_COURSES'){
        let userToShow = {...state.userToShow}
        userToShow.courses = action.payload.userCourses
        return{
            ...state,
            updated:true,
            userToShow: userToShow,
            loading: action.payload.loading,
            
        }
    }else if(action.type === 'UPDATE_CHANGE_STATE'){
        return{
            ...state,
            updated: action.payload.updated,
        }
    }else if(action.type === 'COURSES_CLASSES'){
        let clases = action.payload.courseClases.curso.clases
        let vencimiento = action.payload.courseClases.vencimiento
        let bloqueo = action.payload.courseClases.bloqueo
        let cursoFinalizado = action.payload.courseClases.completedCourse

        for (let course of state.userToShow.courses) {
            if(course.idCurso === action.payload.courseId) {
                course.progressPercentage = action.payload.courseClases.curso.progressPercentage
                course.clases = clases
                course.vencimiento = vencimiento
                course.bloqueo = bloqueo
                course.cursoFinalizado = cursoFinalizado
                break;
            }
        }
        return{
            ...state,
            loading: action.payload.loading,
            coursesUpdated: action.payload.coursesUpdated
        }
    }else if(action.type === 'SET_COURSES_UPDATED'){
        return{
            ...state,
            coursesUpdated: action.payload.coursesUpdated
        }
    }else if(action.type === 'USER_ENROLLED_UPDATE'){
        let registered = (action.payload.action===ADD)?true:false
        for (let course of state.userToShow.courses) {
            if(course.idCurso === action.payload.idCourse) {
                for (let courseClass of course.clases) {
                    if(courseClass.idClase === action.payload.idClase) {
                        courseClass.availability = (action.payload.action===ADD) ? courseClass.availability-1 : courseClass.availability+1
                        courseClass.registred = registered
                        break;
                    }
                }
                break;
            }
        }
        return{
            ...state,
            loading: action.payload.loading,
            refreshCourses: action.payload.refreshCourses
        }
    }
    else if(action.type === 'DUE_DATE_UPDATED'){
        let idCourse = action.payload.courseId
        let vencimiento = action.payload.vencimiento
        let bloqueo = action.payload.bloqueo

        for (let course of state.userToShow.courses) {
            if(course.idCurso === idCourse) {
                course.vencimiento = vencimiento
                course.bloqueo = bloqueo
                break;
            }
        }

        return{
            ...state,
            coursesUpdated: true,
        }
    }
    else if(action.type === 'SET_LOADING_DUE_DATE_MODAL'){
        return{
            ...state,
            loadingDueDateModal: action.payload.loadingDueDateModal,
        }
    }
    else if(action.type === 'DUE_DATE_MODAL_VISIBILITY'){
        return{
            ...state,
            dueDateModalVisibility: action.payload.dueDateModalVisibility
        }
    }
    else if(action.type === 'SET_LOADING_FINISH_COURSE_MODAL'){
        return{
            ...state,
            loadingFinishCourseModal: action.payload.loadingFinishCourseModal
        }
    }
    else if(action.type === 'STUDENT_COURSE_FINISHED_SUCCESSFULLY'){
        let idCourse = action.payload.courseId
        for (let course of state.userToShow.courses) {
            if(course.idCurso === idCourse) {
                course.progressPercentage = 100
                course.cursoFinalizado = true
                break;
            }
        }
        return{
            ...state,
            coursesUpdated: true
        }
    }
    else {
        return state
    }
}
export default UsersReducer;