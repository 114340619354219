import  axios from 'axios';
import  {SERVER_URL,
        PATH_ADMIN,
        ADMINISTRATORS
        } from '../../utility/AppConstants'
import { message } from  'antd'

export function getAdministratorsRequest(payload){
    const GET_ADMINISTRATORS_REQ_URL = SERVER_URL+PATH_ADMIN+ADMINISTRATORS;
    return( dispatch=>{
        dispatch(setLoading({loading:true}))
        return axios.get(   GET_ADMINISTRATORS_REQ_URL,
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }
                            }
        ).then( response => {
            console.log( 'Status Code: ' + response.status )
            if( response.status === 200 ){
                console.log( 'Success response -> ' + GET_ADMINISTRATORS_REQ_URL )
                console.log(response.data)
                dispatch( 
                    setAdministrators({
                        administrators:response.data,
                        loading:false
                    })
                )
            }
        }).catch( error => {
            dispatch( 
                setAdministrators({
                    administrators:{},
                    loading:false
                })
            )
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + GET_ADMINISTRATORS_REQ_URL )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + GET_ADMINISTRATORS_REQ_URL )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + GET_ADMINISTRATORS_REQ_URL )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + GET_ADMINISTRATORS_REQ_URL )
                } 
                message.error( error.response.data.details + ' ('+error.response.status+')' )
            }else{
                console.error( 'Disabled API service -> ' + GET_ADMINISTRATORS_REQ_URL )
            }
        })  
    })
}
export function setLoading(payload){
    return{
        type:'SET_LOADING_ADMINISTRATORS',
        payload
    }
}
export function setAdministrators(payload){
    return{
        type:'SET_ADMINISTRATORS',
        payload
    }
}