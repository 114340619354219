import React, { Component } from 'react'
import { Spin,Button, Modal } from 'antd'
import { connect } from 'react-redux';
import * as TeacherActions from '../../../redux/actions/TeacherActions'
import * as UsersActions from '../../../redux/actions/UsersActions'
import * as CoursesActions from '../../../redux/actions/CoursesActions'

const { confirm } = Modal;

class FinishCourseBtn extends Component{
    state={
        alertModalVisible: false
    }

    setAlertModalVisibility = (value) => {
        this.setState({
            alertModalVisible: value 
        });
    }

    confirmationModal = (estudianteId, cursoId, contextThis) => {
        confirm({
            title: 'Al completar y finalizar el curso, se le asignaran automaticamente todas las asistencias al alumno, por lo tanto contara con un 100% de progreso en este curso....',
            async onOk() {
                Modal.destroyAll()
                //call to update on system
                console.log("Compleate and finishing course...... ");
                console.log("estudiante ID:" + estudianteId)
                console.log("curso ID:" + cursoId)
                await contextThis.props.finishCourseForStudent({
                    jwtToken: contextThis.props.LoginReducer.sessionJWTToken,
                    courseId: cursoId,
                    studentId: estudianteId
                })
                contextThis.setAlertModalVisibility(false)
            },
            onCancel(){
                contextThis.setAlertModalVisibility(false)
            }
        });
    };

    buildStudentFullName = (estudiante) =>{
        let nombreEstudiante = (estudiante !== null) ? estudiante.nombre : null
        let apellidoEstudiante = (estudiante !== null) ? estudiante.apellido : null
        let nombreCompletoEstudiante = (nombreEstudiante !== null) ? nombreEstudiante : ''
        return  (nombreCompletoEstudiante + ((apellidoEstudiante !== null) ? ' '+apellidoEstudiante : '' ))
    }

    getAlertModal(estudiante, curso){
        return  <Modal title="Finalizar curso"
                    visible={this.state.alertModalVisible}
                    onOk={()=>this.confirmationModal(estudiante.idUsuario, curso.idCurso, this)}
                    okText="Finalizar curso"
                    onCancel={!this.props.UsersReducer.loadingFinishCourseModal? ()=>this.setAlertModalVisibility(false) : null}
                    cancelText="Cancelar"
                    closable={false}
                    okButtonProps={{ loading: this.props.UsersReducer.loadingFinishCourseModal }}
                    cancelButtonProps={{ disabled: this.props.UsersReducer.loadingFinishCourseModal }}
                >
                    <Spin spinning={this.props.UsersReducer.loadingFinishCourseModal === true}>
                        <p>¿Esta seguro que desea completar y finalizar el progreso del curso <b>"{curso.nombre}"</b> al alumno <b>"{this.buildStudentFullName(estudiante)}"</b></p>
                    </Spin>
                </Modal>
    }

    render(){
        return(
            <div>
                <Button type="primary" onClick={()=>this.setAlertModalVisibility(true)}>Completar y Finalizar Curso</Button>
                {this.getAlertModal(this.props.estudiante, this.props.curso)}
            </div>
        )
    }
}
const mapStateToProps=(state)=>{
    return state
}
const mapDispatchToProps = {
    ...TeacherActions,
    ...UsersActions,
    ...CoursesActions
};
export default connect (mapStateToProps,mapDispatchToProps)(FinishCourseBtn);