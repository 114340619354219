import React, {Component} from 'react'
import {Row,Col,Input,Button,Icon,Avatar,Form, message, Spin} from 'antd'
import { connect } from 'react-redux';
import * as LoginActions from '../../redux/actions/LoginActions'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

const FormItem = Form.Item;

class NewPwdForm extends Component{
    state={
        username:'',
        password:'',
        passwordValidator: ''
    }
    componentDidUpdate(){
        if(this.props.LoginReducer.pwdChanged !== undefined && this.props.LoginReducer.pwdChanged===true){
            this.props.pwdChanged({
                pwdChanged:null
            })
            //GO TO LOGIN
            console.log('enviando a login')
            this.props.history.push(`/login-admin`)
        }
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id] : e.target.value
        })
    }
    handleSubmit = () => {
        let id = this.props.id
        let tk = this.props.tk
        console.log(id)   
        console.log(tk) 
        
        if(id === null || isNaN(id) || this.props.tk === null){
            message.error('El formato de los datos del token de peticion son invalidos, vuelva a intentar!')
        }else{
            this.props.form.validateFields(err => {
                if (!err) {
                    console.log('sending request')
                    console.log(this.state)   
                    this.props.changePwdRequest({
                        idToken: id,
                        token: tk,
                        username: this.state.username,
                        password: this.state.password
                    })
                }
            });
        }
    }
    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
          callback('Las contraseñas no coinciden!');
        } else {
          callback();
        }
    };
    render(){
        const { getFieldDecorator } = this.props.form;
        return(
            <div className='backGround'>
                <Spin spinning={this.props.LoginReducer.loadingLogin !== undefined ? this.props.LoginReducer.loadingLogin : false}>
                    <div className='form'>
                        <Row >
                            <Col span={24}>
                                <h1 style={{color:'white'}}>
                                    CAMBIO DE CONTRASEÑA
                                </h1>
                            </Col>
                            <Col span={24}>
                                <Avatar size={100} icon='lock' style={{ color: 'white', backgroundColor: 'rgba(255, 255, 255, 0.3)'}} />
                            </Col>
                        </Row>

                        <Row className='contentRow'>
                            <h3 style={{color:'white'}}>
                                Ingresa la informacion solicitada para cambiar la contraseña de tu cuenta en el portal Axss.
                            </h3>
                        </Row>

                        <Row className='contentRow'>
                            <Row>
                                <Col span={24}>
                                    <FormItem>
                                        {getFieldDecorator('username', {
                                            rules: [{type: 'email', message: 'Correo electronico invalido'},
                                                    {required: true, message: 'El correo electronico es requerido' }],
                                        })(
                                            <Input
                                                id="username"
                                                prefix={<Icon type="user" style={{ fontSize: 13 }} />}
                                                placeholder="Correo Electronico"
                                                onChange={this.handleChange}
                                            />
                                        )}
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem>
                                        {getFieldDecorator('password', {
                                            rules: [{ required: true, message: 'La contraseña es requerida' }],
                                        })(
                                            <Input.Password
                                                id="password"
                                                prefix={<Icon type="lock" style={{ fontSize: 13 }} />}
                                                placeholder="Nueva Contraseña"
                                                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                                onChange={this.handleChange}
                                            />
                                        )}
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem>
                                        {getFieldDecorator('passwordValidator', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'La contraseña es requerida'
                                            },{
                                                validator: this.compareToFirstPassword,
                                            },
                                        ],
                                    })(
                                            <Input.Password
                                                id="passwordValidator"
                                                prefix={<Icon type="lock" style={{ fontSize: 13 }} />}
                                                placeholder="Repetir Contraseña"
                                                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                                onChange={this.handleChange}
                                            />
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>

                            <Row style={{display:'flex',justifyContent:'space-between', alignItems:'center'}}>
                                <Col span={8}>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="login-form-button"
                                        onClick={this.handleSubmit}
                                        //loading={this.props.LoginReducer.loadingLogin}
                                    >
                                        Cambiar Contraseña
                                    </Button>
                                    
                                </Col>
                                <Col span={8}>
                                    <Button
                                        type="danger"
                                        htmlType="submit"
                                        className="login-form-button"
                                        onClick={()=>this.props.history.push(`/login-admin`)}
                                        //loading={this.props.LoginReducer.loadingLogin}
                                    >
                                        Salir
                                    </Button>
                                </Col>
                            </Row> 
                        </Row>
                    </div>
                </Spin>
            </div>
        )
    }
}
const mapStateToProps=(state)=>{
    return state
}
export default connect (mapStateToProps,LoginActions)(((Form.create())(NewPwdForm)));
