import  axios from 'axios';
import  {SERVER_URL,
        PATH_ADMIN,
        TEACHERS,
        CLASSES,
        COURSES
        } from '../../utility/AppConstants'

export function getTeachersByCourseRequest(payload){
    const GET_TEACHERS_REQ_URL = SERVER_URL+PATH_ADMIN+TEACHERS+'/'+COURSES+'/'+payload.courseId;
    return( dispatch=>{
        dispatch(setLoading({loading:true}))
        return axios.get(   GET_TEACHERS_REQ_URL,
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }
                            }
        ).then( response => {
            console.log( 'Status Code: ' + response.status )
            if( response.status === 200 ){
                console.log( 'Success response -> ' + GET_TEACHERS_REQ_URL )
                console.log(response.data)
                dispatch(setLoading({loading:false}))
                dispatch( 
                    setTeachers({
                        teachers:response.data
                    })
                )
            }
        }).catch( error => {
            dispatch(setLoading({loading:false}))
            dispatch( 
                setTeachers({
                    teachers:{}
                })
            )
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + GET_TEACHERS_REQ_URL )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + GET_TEACHERS_REQ_URL )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + GET_TEACHERS_REQ_URL )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + GET_TEACHERS_REQ_URL )
                } 
            }else{
                console.error( 'Disabled API service -> ' + GET_TEACHERS_REQ_URL )
            }
        })  
    })
}
export function setTeachers(payload){
    return{
        type:'SET_TEACHERS',
        payload
    }
}
export function setLoading(payload){
    return{
        type:'SET_LOADING_TEACHERS',
        payload
    }
}
export function getTeacherClassesIdsRequest(payload){
    const GET_TEACHER_CLASSES_ID_REQ_URL = SERVER_URL+PATH_ADMIN+TEACHERS+CLASSES+'/'+payload.idTeacher;
    return( dispatch=>{
        return axios.get(   GET_TEACHER_CLASSES_ID_REQ_URL,
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }
                            }
        ).then( response => {
            console.log( 'Status Code: ' + response.status )
            if( response.status === 200 ){
                console.log( 'Success response -> ' + GET_TEACHER_CLASSES_ID_REQ_URL )
                console.log(response.data)
                dispatch( 
                    setTeacherClassesIds({teacherClassesIds:response.data})
                )
            }
        }).catch( error => {
            dispatch( 
                setTeacherClassesIds({teacherClassesIds:{}})
            )
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + GET_TEACHER_CLASSES_ID_REQ_URL )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + GET_TEACHER_CLASSES_ID_REQ_URL )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + GET_TEACHER_CLASSES_ID_REQ_URL )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + GET_TEACHER_CLASSES_ID_REQ_URL )
                } 
            }else{
                console.error( 'Disabled API service -> ' + GET_TEACHER_CLASSES_ID_REQ_URL )
            }
        })  
    })
}
export function setTeacherClassesIds(payload){
    return{
        type:'SET_TEACHER_CLASSES_IDS',
        payload
    }
}