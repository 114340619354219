import React, {Component} from 'react'
import './AddNews.css'
import {Row, Modal, Button, Col, Form, Input, DatePicker, Spin} from 'antd'
import { connect } from 'react-redux';
import * as NewsActions from '../../redux/actions/NewsActions'

import { CloseOutlined } from '@ant-design/icons';
const FormItem = Form.Item;
const { TextArea } = Input;

class AddNews extends Component{
    state = {
        title:'',
        dueDate:'',
        content:''
    }
    UNSAFE_componentWillUpdate(){
        if(this.props.NewsReducer.newsCreationSuccess !== undefined){
            this.props.setCreationSuccess({
                newsCreationSuccess:undefined
            })
            this.props.closeFunction()
        }
    }
    onChange = (e) => {
        this.setState({
          [e.target.id]: e.target.value
        })
    }
    onChangeDate = (date, dateString) =>{
        this.setState({
            dueDate: dateString
        })
    }
    addNewsForm = (getFieldDecorator) => {
        return(
            <div>
            <FormItem className='text-add-news' label='TITULO DE NOTICIA'>
            {getFieldDecorator('title', {
                            rules: [{ required: true, message: 'Introduce un titulo para la noticia!' }],
            })(
                <Input
                    id="title"
                    style={{overflow: 'hidden',borderRadius:'10px',boxShadow:'0px 8px 10px 0px rgba(0,0,0,.3)',border:'1px solid rgba(0,0,0,.7)'}}
                    onChange={this.onChange}
                />
            )}
            </FormItem>
            <FormItem className='text-add-news' label='FECHA DE VENCIMIENTO'>
                {getFieldDecorator('dueDate', {
                    rules: [{ required: true, message: 'Ingrese una fecha de vencimiento para la noticia' }],
                })(
                    <DatePicker style={{overflow: 'hidden', width:'100%', borderRadius:'10px',boxShadow:'0px 8px 10px 0px rgba(0,0,0,.3)',border:'1px solid rgba(0,0,0,.7)'}} placeholder='' onChange={this.onChangeDate} />
                    
                )}
            </FormItem>
            <FormItem className='text-add-news' label='CONTENIDO DE NOTICIA'>
                    <TextArea
                        id="content"
                        rows={5}
                        style={{overflow: 'hidden',borderRadius:'10px',boxShadow:'0px 8px 10px 0px rgba(0,0,0,.3)',border:'1px solid rgba(0,0,0,.7)'}}
                        onChange={this.onChange}
                    />
            </FormItem>
            </div>
        )
    }
    addNewsClick = () => {
        this.props.form.validateFields(err => {
            if (!err) {
                console.log('send request to add a news: ' + this.props.LoginReducer.sessionJWTToken)
                this.props.addNews({
                    jwtToken: this.props.LoginReducer.sessionJWTToken,
                    title: this.state.title,
                    content: this.state.content,
                    dueDate: this.state.dueDate,
                })
            }
        });
    }
   
    render(){
        const { getFieldDecorator } = this.props.form;
        return(
            <Modal
                onCancel={()=>this.props.closeFunction()}
                visible={this.props.visible}
                footer={null}
                width={800}
                className='modal-add-news'
                bodyStyle={{backgroundColor:'white', padding:'0px'}}
                closeIcon={<CloseOutlined className='close-icon-add-news'/>}
                destroyOnClose={true}
            >
                <Spin spinning={this.props.NewsReducer.addingNewInProgress !== undefined ? this.props.NewsReducer.addingNewInProgress : false}>
                    <Row className='title-section-add-news'>
                        {'AGREGAR NOTICIA'}
                    </Row>

                    <Row className='content-section-add-news' type='flex' justify='center' align='middle'>
                        <Col xs={24} md={11}>
                            <Row className='text-add-news' style={{padding:'10px'}} type='flex' justify='center' align='middle' >
                                {'Imagen De Noticia'}
                            </Row>
                            <Row style={{padding:'10px'}} type='flex' justify='center' align='middle' >
                                <img alt="new" src={require('../../images/axss-news.png')} />
                            </Row>
                        </Col>

                        <Col xs={24} md={13} style={{padding:'20px'}}>
                            {this.addNewsForm(getFieldDecorator)}
                        </Col>
                    </Row>

                    <Row className='footer-section-add-news' type='flex' justify='end' align='middle'>
                        <Col xs={12} md={4}>
                            <Button className='btn-cancel' type="danger" onClick={()=>this.props.closeFunction()}> CANCELAR </Button>                        
                        </Col>

                        <Col xs={12} md={4}>
                            <Button className='btn-save' onClick={()=>this.addNewsClick()}> AGREGAR </Button>
                        </Col>
                    </Row>
                </Spin>
            </Modal>
        
        )
    }
}
const mapStateToProps=(state)=>{
    return state
}
export default connect(mapStateToProps,NewsActions)(((Form.create())(AddNews)));