import React,{Component} from 'react'
import { Form, Col, Row, Input, DatePicker, Avatar, Button, Upload, Icon, message, Popconfirm, Modal, Switch, Tooltip} from 'antd'
import { connect } from 'react-redux';
import * as UserActionsCreators from '../../redux/actions/UsersActions'
import moment from 'moment';
import  {SERVER_URL,
    PATH_ADMIN,
    ADD,
    USER_IMAGE
    } from '../../utility/AppConstants'

const REENVIAR_INVITACION = 'Reenviar invitación'

const { TextArea } = Input;
class UserInfoForm extends Component{
    state={
        idUsuario: this.props.UsersReducer.userToShow.idUsuario,
        nombre: this.props.UsersReducer.userToShow.nombre,
        apellido: this.props.UsersReducer.userToShow.apellido,
        fechaNacimiento: this.props.UsersReducer.userToShow.fechaNacimiento,
        telefono: this.props.UsersReducer.userToShow.telefono,
        username: this.props.UsersReducer.userToShow.username,
        previewImage:'',
        previewVisible:false,
        intensiveMode :this.props.UsersReducer.userToShow.intensiveMode=== '1' ? true :false,
        userEnabled :  this.props.UsersReducer.userToShow.userEnabled  === '1' ? true :false,
        comments: this.props.UsersReducer.userToShow.comments
        
    }
    onChangeDate = (date, dateString) =>{
        this.setState({
            fechaNacimiento: dateString
        })
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id] : e.target.value
        })
    }
    saveUser =() =>{
        console.log(this.state)
        this.props.form.validateFields(err => {
            if (!err) {
                console.log('sending user update request')
                this.props.userUpdateRequest({
                    user: this.state,
                    jwtToken: this.props.LoginReducer.sessionJWTToken,
                    userType: this.props.UsersReducer.userToShow.tipoUsuario.idTipoUsuario
                })
            }
          });
    } 
    deleteUser = () => {
        console.log("Deleting user with id: " + this.state.idUsuario)
        console.log("Token auth: " + this.props.LoginReducer.sessionJWTToken)
        this.props.deleteUserRequest({
            userId: this.state.idUsuario,
            jwtToken: this.props.LoginReducer.sessionJWTToken
        })
    }
    deleteTeacher = () => {
        console.log("Deleting user with id: " + this.state.idUsuario)
        console.log("Token auth: " + this.props.LoginReducer.sessionJWTToken)
        this.props.deleteTeacherRequest({
            userId: this.state.idUsuario,
            jwtToken: this.props.LoginReducer.sessionJWTToken
        })
    }
    updateImage= (info) => {
        console.log('entroooo'+this.props.LoginReducer.sessionJWTToken)
        if (info.file.status === 'done') {
            message.success('La imagen se ha cargado correctamente!');
            this.props.getUserToShowRequest({
                userId:this.props.UsersReducer.userToShow.idUsuario,
                jwtToken: this.props.LoginReducer.sessionJWTToken
            })
        }
        else if (info.file.status === 'error') {
            message.error('Error al cargar imagen, intente mas tarde!');
        }
    }

    componentDidUpdate(){   
        if(this.props.UsersReducer.showUser === false && this.state.idUsuario !== null){ //if close the information modal, set as null the id of user to show
            this.setState({idUsuario: null})
        }else{//if the modal is open
            if(this.props.UsersReducer.userToShow !== undefined){//if there is a user available to show
                if(this.props.UsersReducer.updateFail){ //if the data update fails, reset the fields with the initial values;
                    this.props.form.resetFields();
                    this.setState({idUsuario: null})
                    this.props.setUpdateFail({updateFail: null})
                }
                if(this.state.idUsuario !== this.props.UsersReducer.userToShow.idUsuario){//if the user id in the state is different to the id of the user to show, ww load the user to show information
                    this.setState({
                    idUsuario: this.props.UsersReducer.userToShow.idUsuario,
                    nombre: this.props.UsersReducer.userToShow.nombre,
                    apellido: this.props.UsersReducer.userToShow.apellido,
                    fechaNacimiento: this.props.UsersReducer.userToShow.fechaNacimiento,
                    telefono: this.props.UsersReducer.userToShow.telefono,
                    username: this.props.UsersReducer.userToShow.username,
                    })
                }
            }
        }
    }
    deletePhoto=()=>{
        this.props.deletePhotoRequest({
            userId:this.props.UsersReducer.userToShow.idUsuario,
            jwtToken: this.props.LoginReducer.sessionJWTToken
        })
    }
    openPhoto=()=>{
        this.setState({
            previewImage: 'data:image/jpeg;base64,'+this.props.UsersReducer.userToShow.fotoUsuario.archivo,
            previewVisible: true,
        });
    }
    closePhoto=()=>{
        this.setState({
            previewImage: '',
            previewVisible: false,
        });
    }
    onChangeSwitchMode = (value) => {
        this.setState({
            intensiveMode: value
        })
    }
    
    onChangeSwitchModeEnabled = (value) => {
        this.setState({
            userEnabled: value
        })
    }
    userAlreadyConnected = (props) => {
        if(props.UsersReducer.userToShow.resetPwd === '1'){
            return false
        }
        return true;
    }
    resendInvitationClick = (userId) =>{
        console.log('resend Invitation: userid '+userId)
        this.props.sendInvitationRequest({
            idUsuario: userId,
            jwtToken: this.props.LoginReducer.sessionJWTToken
        })
    }
    resendInvitationComponent = (userId) =>{
        return  <Tooltip placement="top" title={REENVIAR_INVITACION}> 
                    <Icon type="mail" style={{ color: '#08c',cursor: 'pointer'}} onClick={()=>this.resendInvitationClick(userId)}/>
                </Tooltip>
    }


    render(){
        const uploadProps = {
            action: SERVER_URL+PATH_ADMIN+USER_IMAGE+ADD+'/'+this.props.UsersReducer.userToShow.idUsuario,
            headers: {
                authorization: this.props.LoginReducer.sessionJWTToken
            },
            onChange:this.updateImage,
            accept:'.png,.jpg,.jpeg',

            
        };
          
        const { getFieldDecorator } = this.props.form;
        return(
            <div>
                {(this.props.UsersReducer.userToShow !== undefined)?(
                    <div>
                        <Modal visible={this.state.previewVisible} footer={null} onCancel={this.closePhoto}>
                            <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                        </Modal>
                            <Form style={{paddingBottom:'30px'}}>  
                                <Row type="flex"  justify="space-around" align="middle">
                                
                                    <Col xs={24} sm={12}>           
                                        {(this.props.UsersReducer.userToShow.fotoUsuario !== null && this.props.UsersReducer.userToShow.fotoUsuario !== undefined)?
                                        (
                                            <div>
                                                <Row>
                                                    <Avatar size={200} src={`data:image/jpeg;base64,${this.props.UsersReducer.userToShow.fotoUsuario.archivo}`}/>
                                                </Row>
                                                <Row style={{marginTop:'5px'}}>
                                                    <Icon type="eye-o" onClick={this.openPhoto} style={{ fontSize: 20, color: 'blue',marginRight:'5px' }} />
                                                    <Popconfirm 
                                                        icon={<Icon type="question-circle-o" style={{ color: 'red' }}/>} 
                                                        placement="bottom" 
                                                        title={'¿Eliminar foto?'} 
                                                        okType="danger" 
                                                        okText="Eliminar" 
                                                        onConfirm={this.deletePhoto}
                                                        cancelText="Cancelar">
                                                            <Icon type="delete" style={{ fontSize: 20, color: 'red' }} />
                                                    </Popconfirm>

                                                </Row>
                                            </div>
                                        ):(
                                            <Form.Item label="Imagen De Perfil"  style={{marginBottom:'0px'}} >
                                                <Row type="flex" justify="center" style={{alignItems: 'center'}}>
                                                    <Col>
                                                        <Upload {...uploadProps} >
                                                            {
                                                                <Row style={{width: '180px',height: '180px',border:' 1px dashed #949494',borderRadius: '6px',cursor: 'pointer',}} type="flex" justify="center" align="middle"  >
                                                                    <Col >
                                                                        <Icon type="upload" />CARGAR
                                                                    </Col>
                                                                </Row>
                                                            }
                                                        </Upload>
                                                    </Col>
                                                </Row>
                                            </Form.Item>
                                        )}
                                    </Col>

                                    <Col xs={24} sm={12}>           

                                        <Row gutter={16}>
                                            <Col xs={24} sm={12}>
                                                <Form.Item label="Nombre">
                                                {getFieldDecorator('nombre', {
                                                    initialValue: this.state.nombre,
                                                    rules: [{ required: true, message: 'Introduzca su nombre' }],
                                                })(<Input id='nombre' placeholder="Introduzca su nombre" onChange={this.handleChange} maxLength={40}/>)}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={12}>
                                                <Form.Item label="Apellido">
                                                {getFieldDecorator('apellido', {
                                                    initialValue: this.state.apellido,
                                                    rules: [{ required: false}],
                                                })(<Input id='apellido' placeholder="Introduzca su apellido" onChange={this.handleChange} maxLength={40}/>)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}> 
                                            <Col xs={24} sm={12}>
                                                <Form.Item label="Fecha de nacimiento">
                                                {getFieldDecorator('fechaNacimiento', {
                                                    initialValue: this.state.fechaNacimiento === null ? '' : moment(this.state.fechaNacimiento, 'YYYY-MM-DD'),
                                                    rules: [{ required: true, message: 'Ingrese una fecha de nacimiento' }],
                                                })(
                                                    <DatePicker placeholder="Seleccione una fecha" onChange={this.onChangeDate} style={{width:'100%'}} />
                                                )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={12}>
                                                <Form.Item label="Telefono">
                                                {getFieldDecorator('telefono', {
                                                    initialValue: this.state.telefono,
                                                    rules: [{ required: false}],
                                                })(<Input id='telefono' placeholder="Introduzca su telefono" onChange={this.handleChange} maxLength={20}/>)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                        {(this.props.UsersReducer.userToShow.tipoUsuario.idTipoUsuario === 3)
                                        ?(
                                            <div>

                                                <div>
                                                    <Col xs={24}>
                                                        <Form.Item label="Email">
                                                            <Col span={!this.userAlreadyConnected(this.props)?21:24}>
                                                                {getFieldDecorator('username', {
                                                                    initialValue: this.state.username,
                                                                    rules: [{type: 'email', message: 'E-mail invalido!'},
                                                                            {required: true, message: 'Introduzca su email' }],
                                                                })(
                                                                        <Input id='username' placeholder="Introduzca su email" onChange={this.handleChange} maxLength={60}/>,<p>sss</p>
                                                                )}
                                                            </Col>
                                                            {!this.userAlreadyConnected(this.props)?
                                                                <Col span={3} >
                                                                    {this.resendInvitationComponent(this.props.UsersReducer.userToShow.idUsuario)}
                                                                </Col>
                                                            :null
                                                            }
                                                        </Form.Item>
                                                    </Col>
                                                    
                                                </div>
                                                <div>
                                                    <Col xs={24}>
                                                        <Form.Item label="Comentarios">
                                                            {getFieldDecorator('comments', {
                                                                initialValue: this.state.comments,
                                                                rules: [{ required: false}],
                                                            })(<TextArea id='comments' placeholder="Introduzca un comentario" onChange={this.handleChange} rows={2} maxLength={250} />)}
                                                        </Form.Item>
                                                    </Col>
                                                    
                                                </div>
                                                <div>
                                                    <Col xs={24} sm={12}>
                                                        <Form.Item label="Modalidad Intensiva" >
                                                            <Switch defaultChecked={this.state.intensiveMode} onChange={this.onChangeSwitchMode}/>
                                                        </Form.Item>
                                                    </Col> 
                                                    <Col xs={24} sm={12}>
                                                        <Form.Item label="Usuario Activo" >
                                                            <Switch defaultChecked={this.state.userEnabled} onChange={this.onChangeSwitchModeEnabled}/>
                                                        </Form.Item>
                                                    </Col> 
                                                    
                                                </div>
                                            </div>
                                            
                                        )
                                        :(
                                                <Col span={24}>
                                                    <Form.Item label="Email">
                                                        <Col span={!this.userAlreadyConnected(this.props)?22:24}>
                                                            {getFieldDecorator('username', {
                                                                initialValue: this.state.username,
                                                                rules: [{type: 'email', message: 'E-mail invalido!'},
                                                                        {required: true, message: 'Introduzca su email' }],
                                                            })(  
                                                                <Input id='username' placeholder="Introduzca su email" onChange={this.handleChange} maxLength={60}/>
                                                            )}
                                                        </Col>
                                                        {!this.userAlreadyConnected(this.props)?
                                                            <Col span={2}>
                                                                {this.resendInvitationComponent(this.props.UsersReducer.userToShow.idUsuario)}
                                                            </Col>
                                                        :null
                                                        }
                                                    </Form.Item>
                                                </Col>
                                        )}
                                        </Row>
                                    </Col>
                                        
                                </Row>
                            </Form>
                            
                            {(this.props.UsersReducer.userToShow.tipoUsuario.idTipoUsuario === 3 || this.props.UsersReducer.userToShow.tipoUsuario.idTipoUsuario === 2)
                                ?(
                                    (this.props.UsersReducer.userToShow.tipoUsuario.idTipoUsuario === 3)?(
                                        <Row justify="space-around" align="middle" >
                                            <Col span={12} >
                                                <Button type="primary" size="large" onClick={this.saveUser}>GUARDAR CAMBIOS</Button>
                                            </Col>
                                            <Col span={12} >
                                            <Popconfirm
                                                title     = { '¿Desea eliminiar este alumno?'} 
                                                icon      = { <Icon type='close-circle' style={{ color:'red' }}/> }
                                                okText    = "ELIMINAR"
                                                okType    = "danger"
                                                cancelText= "Cancelar"
                                                onConfirm = { ()=>this.deleteUser()} >
                                                    <Button type="danger" size="large">ELIMINAR ALUMNO</Button>
                                            </Popconfirm>
                                            </Col>
                                        </Row>
                                    ):(
                                        <Row justify="space-around" align="middle" >
                                            <Col span={12} >
                                                <Button type="primary" size="large" onClick={this.saveUser}>GUARDAR CAMBIOS</Button>
                                            </Col>
                                            <Col span={12} >
                                            <Popconfirm
                                                title     = { '¿Desea eliminiar este instructor?'} 
                                                icon      = { <Icon type='close-circle' style={{ color:'red' }}/> }
                                                okText    = "ELIMINAR"
                                                okType    = "danger"
                                                cancelText= "Cancelar"
                                                onConfirm = { ()=>this.deleteTeacher()} >
                                                    <Button type="danger" size="large">ELIMINAR INSTRUCTOR</Button>
                                            </Popconfirm>
                                            </Col>
                                        </Row>
                                    )
                                ):(
                                    <Row justify="space-around" align="middle" >
                                        <Col span={24} >
                                            <Button type="primary" size="large" onClick={this.saveUser}>GUARDAR CAMBIOS</Button>
                                        </Col>
                                    </Row>
                                )
                            }
                            
                    </div>
                ):(
                    null
                )}
            </div>
        )
    }
    
}
const mapStateToProps=(state)=>{
    return state
}
export default connect (mapStateToProps,UserActionsCreators)(Form.create()(UserInfoForm));