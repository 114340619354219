const AdministratorsReducer=(state={}, action)=>{
        if(action.type === 'SET_LOADING_ADMINISTRATORS'){
            return{
                ...state,
                loading: action.payload.loading,
            }
        }
        else if(action.type === 'SET_ADMINISTRATORS'){
            return{
                ...state,
                administrators: action.payload.administrators,
                loading: action.payload.loading,
            }
        }
        else {
            return state
        }
    }
    export default AdministratorsReducer;