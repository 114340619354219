import React,{Component} from 'react'
import {Modal,Row,Col,Input,Form,Button,Spin} from 'antd'
import { connect } from 'react-redux';
import * as actionCreator from '../../redux/actions/LoginActions'

class ChangeReqPwd extends Component{
    state = { 
                username: ''
            };

    onChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    sendMail = () => {
        this.props.form.validateFields(err => {
            if (!err) {
                console.log(this.state)
                console.log('sending request to pwd chg req')
                this.props.pwdChgRequest({
                    username: this.state.username
                })
            }
        });
    }
    closeModal = () => {
        console.log( 'Closing pwd change request modal' )
        this.props.pwdChgReqModal({
            pwdChgReqVisible: false
        })
    }

    render(){
        const { getFieldDecorator } = this.props.form;
        return(
            
            <Modal
             title="¿Olvidaste tu contraseña?"
             visible={this.props.LoginReducer.pwdChgReqVisible===true}
             closable={false}
             footer={null}>

                <Spin spinning={this.props.LoginReducer.loadingCh===true}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <h4>Ingresa el correo electronico con el cual estas registrado en el portal y recibiras un correo para recuperar tu contraseña.</h4>
                        </Col>
                        <br/>
                        <br/>
                        <br/>
                        <Col span={24}>
                            <Form.Item>
                                {getFieldDecorator('username', {
                                    rules: [{type: 'email', message: 'Correo electronico invalido'},
                                            {required: true, message: 'Introduce un correo electronico' }],
                                })
                                (
                                    <Input 
                                        id='username'
                                        placeholder={'CORRECTO ELECTRONICO'} 
                                        onChange={this.onChange} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Button type="primary" onClick={this.sendMail}>
                                Enviar Correo
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Button type="danger" onClick={this.closeModal}>
                                Cancelar
                            </Button>
                        </Col>
                    </Row>
                </Spin>

            </Modal>
        )
    }
}
const mapStateToProps=(state)=>{
    return state
}
export default connect (mapStateToProps,actionCreator)(Form.create()(ChangeReqPwd));