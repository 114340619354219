import React,{Component} from 'react'
import {Modal,Row,Col,Button} from 'antd'
import {Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as NotificationsActions from '../../redux/actions/NotificationsActions'

class AlertNotif extends Component{
    getUnviewedNotificationsSize = async () => {
        console.log("Calling notifications service from client side......")
        if(this.props.LoginReducer.sessionInformation !== undefined){
            let userType = this.props.LoginReducer.sessionInformation.user.userType
            let jwtToken = this.props.LoginReducer.sessionJWTToken
            let userId = this.props.LoginReducer.sessionInformation.user.userId

            if(userType === 1 || userType === 3){
                console.log('send request to retrieve the amount of unviewed notifications for userId: ' +userId+' of userType: '+userType+' with jwtToken: '+jwtToken)
                await this.props.getNotificationsSize({
                    jwtToken,
                    userId,
                    userType, 
                    viewed:false
                })
            }
            
        }
    }

    componentDidMount() {
        this.getUnviewedNotificationsSize()
    }

    defineVisibilityValueForNotificationsAlert = () =>{
        let userType = this.props.LoginReducer.sessionInformation.user.userType
        if(userType !== undefined){
            if(userType === 1){
                return (this.props.NotificationsReducer.unviewedNotificationsSize !== undefined && this.props.NotificationsReducer.unviewedNotificationsSize > 0 && this.props.NotificationsReducer.unviewedNotificationsAckAdmin !== true)
            }else if(userType === 3){
                return (this.props.NotificationsReducer.unviewedNotificationsSize !== undefined && this.props.NotificationsReducer.unviewedNotificationsSize > 0)
            }
        }
        return false
    }

    unviewedNotificationsAckAdmin = () =>{
        this.props.setUnviewedNotificationsAckAdmin({
            unviewedNotificationsAckAdmin : true
        })
    } 

    render(){
        return(
            <Modal 
             title="Alerta de notificaciones"
             visible={this.defineVisibilityValueForNotificationsAlert()}
             closable={false}
             footer={null}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <h2>Tienes nuevas notificaciones sin leer que necesitas confirmar!</h2>
                        </Col>
                        <br/>
                        <br/>
                        <br/>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Button type="primary" onClick={this.unviewedNotificationsAckAdmin}>
                                <Link to="/notifications" >
                                Ver Notificaciones
                                </Link>
                            </Button>
                        </Col>
                    </Row>
            </Modal>
        )
    }

}
const mapStateToProps=(state)=>{
    return state
}
export default connect(mapStateToProps, NotificationsActions)(AlertNotif);