import React,{Component} from 'react'
import { List, Avatar, Divider, Icon, Popconfirm } from 'antd';
import { EditFilled,DeleteFilled } from '@ant-design/icons';
import { connect } from 'react-redux';
import * as CoursesActionsCreators from '../../redux/actions/CoursesActions'
import UpdateLesson from './UpdateLesson'

class LessonsList extends Component{
    onEdit = (item) => {
      this.props.updateLessonModal({
        updateLessonModal:true,
        lesson:item
      })
    }
    onDelete = (item) => {
      console.log('delete the follow id item: ')
      console.log(item.idTema)
      console.log('sending delete lesson request')
      this.props.deleteLessonRequest({
        idLesson: item.idTema,
        jwtToken: this.props.LoginReducer.sessionJWTToken
      })
    }
    render(){
        return(
            <div>
                <Divider orientation="left">{this.props.data.length + ' Lecciones'}</Divider>
                {
                  this.props.CoursesReducer.lesson !== undefined
                  ?<UpdateLesson
                    tituloTema={this.props.CoursesReducer.lesson.tituloTema}
                    descripcionTema={this.props.CoursesReducer.lesson.descripcionTema}/>
                  :null
                }
                <List
                    itemLayout="horizontal"
                    dataSource={this.props.data}
                    renderItem={(item,index) => (
                    <List.Item 
                      actions={
                        this.props.LoginReducer.sessionInformation.user.userType===1 
                        ?
                          this.props.justView !== true ?
                          [<EditFilled onClick={()=>this.onEdit(item)}/>,
                          <Popconfirm
                          title     = { '¿Desea eliminiar este tema?'} 
                          icon      = { <Icon type='close-circle' style={{ color:'red' }}/> }
                          okText    = "ELIMINAR"
                          okType    = "danger"
                          cancelText= "Cancelar"
                          onConfirm = { ()=>this.onDelete(item)} >
                            <DeleteFilled style={{ fontSize: '16px', color: 'red' }}/>
                          </Popconfirm>]
                          :null
                        : /*this.props.LoginReducer.sessionInformation.user.userType===2 
                          ?
                            [<BarsOutlined onClick={()=>this.onEdit(item)} style={{ fontSize: '16px'}}/>]
                          :*/ null
                                
                      }>
                        <List.Item.Meta
                        avatar={
                          this.props.CoursesReducer.courseDetails !== undefined &&
                          this.props.CoursesReducer.courseDetails.asistencias !== undefined && this.props.CoursesReducer.courseDetails.asistencias !== null ? 
                            this.props.CoursesReducer.courseDetails.asistencias.includes(item.idTema)
                            ?<Avatar style={{ backgroundColor: 'green' }} >{index+1}</Avatar>
                            :<Avatar >{index+1}</Avatar>
                          :<Avatar >{index+1}</Avatar>
                        }
                        title={item.tituloTema}
                        description={item.descripcionTema}
                        />
                    </List.Item>
                    )}
                />
            </div>
        )
    }
}
const mapStateToProps=(state)=>{
  return state
}
export default connect(mapStateToProps,CoursesActionsCreators)(LessonsList);
