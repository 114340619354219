import  { ADD } from '../../utility/AppConstants'
const CoursesReducer=(state={}, action)=>{
    if(action.type === 'SET_LOADING_COURSES'){
        return{
            ...state,
            loading: action.payload.loading,
        }
    }
    else if(action.type === 'SET_COURSES'){
        if(state.courseDetails!==undefined){
            let courseDetailsTemario = state.courseDetails.temario
            let courseDetailsClases = state.courseDetails.clases
            let newCourseDetails = action.payload.courses.filter(course => course.idCurso === state.courseDetails.idCurso)

            newCourseDetails[0].temario = courseDetailsTemario
            newCourseDetails[0].clases = courseDetailsClases

            return{
                ...state,
                courses: action.payload.courses,
                courseDetails: newCourseDetails[0],
                loading: action.payload.loading,
            }
        }
        return{
            ...state,
            courses: action.payload.courses,
            loading: action.payload.loading,
        }
    }
    else if(action.type === 'SET_AVAILABLE_COURSES'){
        return{
            ...state,
            availableCourses: action.payload.courses,
            loading: action.payload.loading,
        }
    }
    else if(action.type === 'SET_UPDATED_DATA_COURSE'){
        return{
            ...state,
            updatedData: action.payload.updatedData,
            loading: action.payload.loading,
        }
    }
    else if(action.type === 'COURSE_DETAILS'){
        return{
            ...state,
            showDetails: action.payload.showDetails,
            courseDetails: action.payload.courseDetails,
        }
    }
    else if(action.type === 'UPDATE_COURSE_FAIL'){
        return{
            ...state,
            updateCourseFail: action.payload.updateCourseFail
        }
    }
    else if(action.type === 'REFRESH_COURSES_IN_COURSES'){
        return{
            ...state,
            refreshCoursesInCourses: action.payload.refreshCoursesInCourses,
        }
    }
    else if(action.type === 'DELETE_CLASS'){
        let newClasses = state.courseDetails.clases.filter( clase => clase.idClase !== action.payload.idClass)
        let course = state.courseDetails
        course.clases = newClasses;
        return{
            ...state,
            courseDetails: course,
        }
    }
    else if(action.type === 'ADD_CLASS'){
        state.courseDetails.clases.push(action.payload.newClass) 
        return{
            ...state
        }
    }
    else if(action.type === 'ADD_LESSON'){
        state.courseDetails.temario.push(action.payload.newLesson) 
        return{
            ...state,
            addedLesson:true
        }
    }
    else if(action.type === 'LESSON_ADDED'){
        return{
            ...state,
            addedLesson:action.payload.addedLesson
        }
    }
    else if(action.type === 'DELETE_LESSON'){
        let newLessons = state.courseDetails.temario.filter( tema => tema.idTema !== action.payload.idLessonDelete )
        let course = state.courseDetails
        course.temario = newLessons;
        return{
            ...state,
            courseDetails: course,
        }
    }
    else if(action.type === 'UPDATE_LESSON_MODAL'){
        return{
            ...state,
            updateLessonModal: action.payload.updateLessonModal,
            lesson: action.payload.lesson,
        }
    }
    else if(action.type === 'LESSON_EDITED'){
        state.courseDetails.temario.forEach(tema => {
            if(tema.idTema === action.payload.lessonEdited.idTema){
                tema.tituloTema = action.payload.lessonEdited.tituloTema
                tema.descripcionTema = action.payload.lessonEdited.descripcionTema
            }
        })
        return{
            ...state
        }
    }
    else if(action.type === 'ASSISTANCE_MANAGEMENT_MODAL'){
        return{
            ...state,
            assistanceManagementModal: action.payload.assistanceManagementModal,
            assistanceIdClass: action.payload.assistanceIdClass
        }
    }
    else if(action.type === 'STUDENT_COURSE_DETAILS'){
        state.courseDetails = action.payload.studentCourseDetails.curso
        state.courseDetails.vencimiento = action.payload.studentCourseDetails.vencimiento
        state.courseDetails.bloqueo = action.payload.studentCourseDetails.bloqueo
    
        return{
            ...state,
            showDetails: action.payload.showDetails
        }
    }
    else if(action.type === 'USER_ENROLLED_UPDATE_BY_STUDENT'){
        let registered = (action.payload.action===ADD)?true:false
        
        for (let courseClass of state.courseDetails.clases) {
            if(courseClass.idClase === action.payload.idClase) {
                courseClass.availability = (action.payload.action===ADD) ? courseClass.availability-1 : courseClass.availability+1
                courseClass.registred = registered
                break;
            }
        } 
        return{
            ...state,
            loading: action.payload.loading,
            refreshCoursesInCourses: action.payload.refreshCoursesInCourses
        }
    }
    else if(action.type === 'SET_LESSONS_TO_COURSE'){
        state.availableCourses[action.payload.courseIndex].lessons = action.payload.courseLessons
        return{
            ...state,
            showCourseLessons: action.payload.showCourseLessons,
            courseInformation: state.availableCourses[action.payload.courseIndex]
        }
    }
    else if(action.type === 'SET_SHOW_COURSE_LESSONS'){
        
        return{
            ...state,
            showCourseLessons: action.payload.showCourseLessons
        }
    }
    else if(action.type === 'SET_COURSE_INFORMATION'){
        
        return{
            ...state,
            courseInformation: action.payload.courseInformation
        }
    }
    else {
        return state
    }
}
export default CoursesReducer;