import React from 'react'
import Form from '../../components/login/Form'
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

function LoginLayer(props){
    const style = { 
        backgroundColor:'withe',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        display:'flex',
        width: '100vw', /* viewport width */
        height:'100vh', /* viewport height */
        overflowY:'scroll',
        overflowX:'hidden',
    }
    return(
        <div style={style} >
            {props.LoginReducer.successLogin ? <Redirect to={'/home'}/> : <Form/>}
        </div>
    )
}
const mapStateToProps=(state)=>{
    return state
}
export default connect (mapStateToProps)(LoginLayer);