import  axios from 'axios';
import  {SERVER_URL,
        PATH_ADMIN,
        STUDENTS,
        CLASSES,
        CLASSES_II,
        LESSON,
        COURSES,
        CLASSES_UNENROLLMENT
        } from '../../utility/AppConstants'
import {message} from 'antd'

export function getClassStudentsRequest(payload){
    const GET_CLASS_STUDENTS_REQ_URL = SERVER_URL+PATH_ADMIN+STUDENTS+CLASSES+'/'+payload.idClase;
    return( dispatch=>{
        dispatch(setLoading({loading:true}))
        return axios.get(   GET_CLASS_STUDENTS_REQ_URL,
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }
                            }
        ).then( response => {
            console.log( 'Status Code: ' + response.status )
            if( response.status === 200 ){
                console.log( 'Success response -> ' + GET_CLASS_STUDENTS_REQ_URL )
                console.log(response.data)
                dispatch(setStudentsList({studentsRegistredInClass:response.data}))

                dispatch(setLoading({loading:false}))
            }
        }).catch( error => {
            dispatch(setStudentsList({studentsRegistredInClass:undefined}))
            dispatch(setLoading({loading:false}))

            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + GET_CLASS_STUDENTS_REQ_URL )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + GET_CLASS_STUDENTS_REQ_URL )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + GET_CLASS_STUDENTS_REQ_URL )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + GET_CLASS_STUDENTS_REQ_URL )
                } 
                message.error( error.response.data.details + ' ('+error.response.status+')' )
            }else{
                console.error( 'Disabled API service -> ' + GET_CLASS_STUDENTS_REQ_URL )
            }
        })  
    })
}
export function getClassAssistsByLesson(payload){
    const GET_CLASS_ASSISTS_REQ_URL = SERVER_URL+PATH_ADMIN+CLASSES_II+payload.idClase+'/'+LESSON+payload.idLesson;
    return( dispatch=>{
        dispatch(setLoading({loading:true}))
        return axios.get(   GET_CLASS_ASSISTS_REQ_URL,
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }
                            }
        ).then( response => {
            console.log( 'Status Code: ' + response.status )
            if( response.status === 200 ){
                console.log( 'Success response -> ' + GET_CLASS_ASSISTS_REQ_URL )
                console.log(response.data)
                dispatch(setAssists({assistsList:response.data}))
                dispatch(assistsUpdated({assistsUpdated:true}))
                dispatch(setLoading({loading:false}))
            }
        }).catch( error => {
            dispatch(assistsUpdated({assistsUpdated:true}))
            dispatch(setAssists({assistsList:[]}))
            dispatch(setLoading({loading:false}))

            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + GET_CLASS_ASSISTS_REQ_URL )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + GET_CLASS_ASSISTS_REQ_URL )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + GET_CLASS_ASSISTS_REQ_URL )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + GET_CLASS_ASSISTS_REQ_URL )
                } 
                message.error( error.response.data.details + ' ('+error.response.status+')' )
            }else{
                console.error( 'Disabled API service -> ' + GET_CLASS_ASSISTS_REQ_URL )
            }
        })  
    })
}
export function updateAssistsList (payload){
    const POST_UPDATE_CLASS_ASSISTS_REQ_URL = SERVER_URL+PATH_ADMIN+CLASSES_II+payload.idClase+'/'+LESSON+payload.idLesson;
    return( dispatch=>{
        dispatch(setLoading({loading:true}))
        return  axios.post( POST_UPDATE_CLASS_ASSISTS_REQ_URL, 
                            {
                                toDelete : payload.toDelete,
                                toAdd : payload.toAdd,
                                updatedById: payload.updatedById
                            },
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }  
                            }
        ).then( response=>{
            console.log( 'Status Code: ' + response.status )
            if(response.status === 200){
                message.success('LA LISTA DE ASISTENCIAS SE HA ACTUALIZADO CORRECTAMENTE');
                console.log( 'Success response -> ' + POST_UPDATE_CLASS_ASSISTS_REQ_URL )
                dispatch(updateAssistsResult({result:true,assistsList:payload.assistsList}))
                dispatch(setLoading({loading:false}))
            }
        }).catch( error => {
            dispatch(updateAssistsResult({result:false,assistsList:[]}))
            dispatch(setLoading({loading:false}))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 400 ){
                    console.error( 'Bad request ->  ' + POST_UPDATE_CLASS_ASSISTS_REQ_URL )
                    message.error( 'FORMATO DE DATOS INCORRECTO!' )
                }
                else if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + POST_UPDATE_CLASS_ASSISTS_REQ_URL )
                    message.error( 'TU SESION HA EXPIRADO!' )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + POST_UPDATE_CLASS_ASSISTS_REQ_URL )
                    message.error( 'NO ESTAS AUTORIZADO PARA REALIZAR ESTA ACCION!' )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + POST_UPDATE_CLASS_ASSISTS_REQ_URL )
                    message.error( 'ERROR INTERNO, POR FAVOR INTENTE MAS TARDE Y COMUNIQUESE CON SOPORTE!' )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + POST_UPDATE_CLASS_ASSISTS_REQ_URL )
                } 
                message.error( error.response.data.details + ' ('+error.response.status+')' )
            }else{
                console.log( 'Disabled API service -> ' + POST_UPDATE_CLASS_ASSISTS_REQ_URL )
            }
        })
    })
}
export function getStudentAssists(payload){
    const GET_STUDENT_ASSISTS_URL = SERVER_URL+PATH_ADMIN+STUDENTS+'/'+payload.studentId+'/'+COURSES+'/'+payload.courseId;
    return( dispatch=>{
        dispatch(trackerAssists({showTrackerAssists:true}))
        dispatch(setLoading({loading:true}))
        return axios.get(   GET_STUDENT_ASSISTS_URL,
            {
                headers:{
                    'Authorization': payload.jwtToken
                }
            }
        ).then( response => {
        console.log( 'Status Code: ' + response.status )
        if( response.status === 200 ){
            console.log( 'Success response -> ' + GET_STUDENT_ASSISTS_URL )
            console.log(response.data)
            if(payload.view === "AssistanceList"){
                dispatch(setStudentAssists({
                    studentAssistsByIdLesson:response.data,
                    studentId:payload.studentId, 
                    loading:false,
                    correctRetrieve:true
                }))
            }
            else if(payload.view === "UserDetails"){
                dispatch(setLoading({loading:false}))
                dispatch(saveAssistancesStudent({   
                    assistances:response.data,
                    lessons:payload.lessons
                }))
            }
        }
        }).catch( error => {
            dispatch(trackerAssists({showTrackerAssists:false}))
            dispatch(setLoading({loading:false}))
            dispatch(setCorrectRetrieve({correctRetrieve:false}))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + GET_STUDENT_ASSISTS_URL )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + GET_STUDENT_ASSISTS_URL )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + GET_STUDENT_ASSISTS_URL )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + GET_STUDENT_ASSISTS_URL )
                } 
                message.error( error.response.data.details + ' ('+error.response.status+')' )
            }else{
            console.error( 'Disabled API service -> ' + GET_STUDENT_ASSISTS_URL )
            }
        })  
    })
}

export function setCorrectRetrieve(payload){
    return{
        type:'SET_CORRECT_RETRIEVE',
        payload
    }
}
export function setStudentAssists(payload){
    return{
        type:'SET_STUDENT_ASSISTS',
        payload
    }
}
export function trackerAssists(payload){
    return{
        type:'SET_TRACKER_ASSISTS',
        payload
    }
}
export function setLoading(payload){
    return{
        type:'SET_LOADING_ASSISTS',
        payload
    }
}
export function setStudentsList(payload){
    return{
        type:'SET_STUDENTS_LIST',
        payload
    }
}
export function setAssists(payload){
    return{
        type:'SET_ASSISTS',
        payload
    }
}
export function assistsUpdated(payload){
    return{
        type:'ASSISTS_UPDATED',
        payload
    }
}
export function updateAssistsResult(payload){
    return{
        type:'UPDATE_ASSISTS_RESULT',
        payload
    }
}

export function studentClassesUnenrollment(payload){
    const STUDENT_CLASSES_UNENROLLMENT = SERVER_URL+STUDENTS+'/'+payload.studentId+'/'+CLASSES_UNENROLLMENT+payload.courseId;
    return( dispatch=>{
        dispatch(setLoading({loading:true}))
        return axios.get(   STUDENT_CLASSES_UNENROLLMENT,
            {
                headers:{
                    'Authorization': payload.jwtToken
                }
            }
        ).then( response => {
        console.log( 'Status Code: ' + response.status )
        if( response.status === 200 ){
            console.log( 'Success response -> ' + STUDENT_CLASSES_UNENROLLMENT )
            message.success(response.data.details);
            console.log(response.data)
            /*dispatch(setStudentAssists({
                studentAssistsByIdLesson:response.data,
                studentId:payload.studentId, 
                loading:false,
                correctRetrieve:true
            }))*/
            dispatch(successUnenrollment({
                loading:false,
                courseId: payload.courseId,
                studentId: payload.studentId,
                successUnenrollemnt: true
            }))
        }
        }).catch( error => {
            dispatch(setLoading({loading:false}))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + STUDENT_CLASSES_UNENROLLMENT )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + STUDENT_CLASSES_UNENROLLMENT )
                }
                else{
                    message.error( error.response.data.details + ' ('+error.response.status+')' )
                } 
            }else{
            console.error( 'Disabled API service -> ' + STUDENT_CLASSES_UNENROLLMENT )
            }
        })  
    })
}
export function successUnenrollment(payload){
    return{
        type:'SUCCESS_UNENROLMENT',
        payload
    }
}
export function setSuccessUnenrollemnt(payload){
    return{
        type:'SET_SUCCESS_UNENROLMENT',
        payload
    }
}
export function saveAssistancesStudent(payload){
    return{
        type:'SAVE_ASSISTANCES_STUDENT',
        payload
    }
}
export function closeAssistancesInClass(payload){
    return{
        type:'CLOSE_ASSISTANCES_IN_CLASS',
        payload
    }
}