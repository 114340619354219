import React,{Component} from 'react'
import {Form, Button, Col, Row, Input, Icon} from 'antd'
import { connect } from 'react-redux';
import * as SedeActionsCreators from '../../redux/actions/SedeActions'
import './NewSede.css'

class NewSede extends Component{
  state = { 
            name:'',
            city:''
    };
    onOk = () => {
      this.props.form.validateFields(err => {
        if (!err) {
          console.log('sending new sede request')
          console.log(this.state)
          this.props.addNewSede({
            newSede: this.state,
            jwtToken: this.props.LoginReducer.sessionJWTToken
          })
        }
      });
    }
    onChange = (e) => {
      this.setState({
        [e.target.id]: e.target.value
      })
    }
    componentDidUpdate(){
        if(this.props.SedeReducer.updatedData){
            this.props.form.resetFields();
            this.props.setUpdatedDataSede({
                updatedData:false,
                loading:false
            })
        }
    }
    render(){
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Form layout="vertical" hideRequiredMark>
            <Row >
                <h2 style={{float:'left'}}>Agregar Nueva Sede</h2>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Nombre Sede ">
                        {getFieldDecorator('name', {
                        rules: [{ required: true, message: 'Introduzca un nombre' }],
                        })(<Input id='name' placeholder="Introduzca el nombre de sede" onChange={this.onChange} />)}
                    </Form.Item>
                </Col>
                <Col span={12} >
                <Form.Item label="Ciudad Sede">
                    {getFieldDecorator('city', {
                    rules: [{ required: true, message: 'Introduzca una ciudad' }],
                    })(<Input id='city' placeholder="Introduzca la ciudad de la sede" onChange={this.onChange} />)}
                </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24} >
                    <Button type="primary" onClick={this.onOk} >
                        <Icon type="plus" /> Agregar Sede
                    </Button>
                </Col>
            </Row>
        </Form>
      </div>
    );
    }
}
const mapStateToProps=(state)=>{
  return state
}
export default connect (mapStateToProps,SedeActionsCreators)(Form.create()(NewSede));