import React,{ Component } from 'react'
import { Collapse,Spin, message, Tag } from 'antd';
import { connect } from 'react-redux';
import WeeklySchedule from '../schedule/WeeklySchedule'
import ProgressBar from '../progressbar/ProgressBar'
import AssistanceTracker from '../assistances/AssistanceTracker'
import  { ADD,DELETE,USUARIO,INSTRUCTOR } from '../../utility/AppConstants'


import * as UsersActions from '../../redux/actions/UsersActions'
import * as TeacherActions from '../../redux/actions/TeacherActions'
import * as CoursesActions from '../../redux/actions/CoursesActions'
import * as AssistsActions from '../../redux/actions/AssistsActions'

const { Panel } = Collapse;


class ClassAssignments extends Component{
    state={ 
        enrollMessage:'',
        unenrollMessage:''
    }
    UNSAFE_componentWillMount(){
        if(this.props.idUserType === 2){
            //traerte el id de sus clases! HTTP REQUEST
            this.setState({
                enrollMessage:'¿DESEAS REGISTRARTE COMO MAESTRO DE ESTA CLASE?',
                unenrollMessage:'¿DESEAS DARTE DE BAJA COMO MAESTRO DE ESTA CLASE?'
            }) 
        }
        else if(this.props.idUserType === 3){
            if(this.props.courses === null){
                this.props.getStudentCoursesRequest({
                    userId:this.props.UsersReducer.userToShow.idUsuario,
                    jwtToken: this.props.LoginReducer.sessionJWTToken
                })
            }
            this.setState({
                enrollMessage:'¿DESEAS REGISTRARTE COMO ALUMNO DE ESTA CLASE?',
                unenrollMessage:'¿DESEAS DARTE DE BAJA DE ESTA CLASE?'
            }) 
        }
    }
    teacherEnrollment = (detailsClass) =>{
        let idClass = detailsClass.idClase
        let jwtToken = this.props.LoginReducer.sessionJWTToken
        let idUsuario = this.props.idUser
        let action
        //VALID IF TEACHER IS OR NOT TEACHER OF THIS CLASS
        action = detailsClass.registred
                    ? DELETE //IF YOU'RE ENROLLED, YOU WILL BE REMOVED FROM THIS CLASS
                    : ADD //IF YOU'RE NOT ENROLLED YOU WILL BE ADDED TO THIS CLASS
        
        this.props.classTeacherUpdateRequest({
            jwtToken,
            idClass,
            idUsuario,
            action
        })
    }
    openAssistanceManagement = (detailsClass) =>{
        console.log('Calling assistanceManagementModal request to open modal ')
        this.props.assistanceManagementModal({
            assistanceManagementModal: true,
            assistanceIdClass : detailsClass.idClase
        })
        console.log('Calling getClassStudentsRequest request to get class students list')
        this.props.getClassStudentsRequest({
            idClase: detailsClass.idClase,
            jwtToken: this.props.LoginReducer.sessionJWTToken
        })
        
    }
    okTeacher=(detailsClass,hoursAssigned)=>{
        if(this.props.view === USUARIO){
            this.teacherEnrollment(detailsClass)
        }else if(this.props.view === INSTRUCTOR){
            
            if(detailsClass.instructor == null || detailsClass.instructor.idUsuario !== this.props.idUser){
                message.error('NO PUEDES ACCEDER A LA INFORMACION DE ESTA CLASE, DEBIDO A QUE NO ERES INSTRUCTOR DE ELLA!')
            }else{
                this.openAssistanceManagement(detailsClass)
            }
        }
    }
    okStudent=(detailsClass,hoursAssigned)=>{
        let idCourse = detailsClass.idCourse
        let idClase = detailsClass.idClase
        let jwtToken = this.props.LoginReducer.sessionJWTToken
        let idUsuarioAlumno = this.props.idUser
        let studentHrsPerWeek= (this.props.studentLimitHrs===undefined?0:this.props.studentLimitHrs)
        console.log('hrsLimited :'+studentHrsPerWeek)
        let userEnable= (this.props.userEnable===undefined?false:this.props.userEnable)
        //VALID IF STUDENT IS OR NOT ENROLLED TO THIS CLASS
        let action = detailsClass.registred 
                    ? DELETE //IF YOU'RE ENROLLED, YOU WILL BE REMOVED FROM THIS CLASS
                    : ADD; //IF YOU'RE NOT ENROLLED YOU WILL BE ADDED TO THIS CLASS
        if(action === ADD && (hoursAssigned+detailsClass.classHours)>studentHrsPerWeek){
            message.error('NO PUEDES REGISTRARTE EN ESTA CLASE, HAS ALCANZADO EL LIMITE DE HORAS POR SEMANA!')
        }else if(action === ADD && !detailsClass.availability > 0){
            message.error('EL CUPO DE ESTA CLASE HA LLEGADO A SU LIMITE!')
        }else if(action === ADD && userEnable === false){
            message.error('USUARIO INACTIVO, COMUNICATE CON ADMINISTRACION PARA PODER REGISTRAR CLASES')
        }else{
            if(this.props.LoginReducer.sessionInformation.user.userType === 1){
                console.log('action executed by admin')
                this.props.classEnrollmentRequestByAdmin({
                    jwtToken,
                    action,
                    idUsuarioAlumno,
                    idCourse,
                    idClase,
                    userType:1
                })
            }
            else if(this.props.LoginReducer.sessionInformation.user.userType === 3){
                console.log('action executed by student')
                this.props.classEnrollmentRequestByStudent({ // this is the action that we send to the server in order to enrroll an student into a class!!
                    jwtToken,
                    action,
                    idUsuarioAlumno,
                    idCourse,
                    idClase,
                    userType:3
                })
            }
        }
        /*(action === ADD && (hoursAssigned+detailsClass.classHours)>studentHrsPerWeek)?
            message.error('NO PUEDES REGISTRARTE EN ESTA CLASE, HAS ALCANZADO EL LIMITE DE HORAS POR SEMANA!')
        : detailsClass.availability > 0 ?
            this.props.classEnrollmentRequest({
            jwtToken,
            action,
            idUsuarioAlumno,
            idCourse,
            idClase})
            : message.error('EL CUPO DE ESTA CLASE HA LLEGADO A SU LIMITE!')*/
    }
    render(){
        return(
            <div>
                <Spin spinning={this.props.UsersReducer.loading || this.props.loadingFlag}>
                    {(this.props.visibilityFlag !== undefined)?(
                        (this.props.courses !== null && this.props.courses.length > 0) ?
                            <Collapse
                            expandIconPosition={'right'}
                            defaultActiveKey={null} 
                            >
                                {this.props.courses.map((course, id) => (
                                    <Panel header={course.nombre.toUpperCase()} key={id} style={{backgroundColor:course.etiqueta}}>
                                        {(this.props.idUserType===3)?(
                                            <div>
                                                <AssistanceTracker student={this.props.UsersReducer.userToShow} keyComponent={course.idCurso} view="UserDetails"/>
                                                <ProgressBar progressType='CURSO' percent={course.progressPercentage} assistanceTracker={true} courseId={course.idCurso} lessons={course.temario} />
                                            </div>
                                        ):(null)}
                                        <Tag style={{marginBottom: '10px'}} color={course.etiqueta}>{(course.duracion === 1) ? 'Duracion: 1 Mes' :  'Duracion: ' +course.duracion +' Meses' }</Tag>
                                        <br/>
                                        <WeeklySchedule 
                                        view={this.props.view} 
                                        okFunction={
                                            this.props.idUserType === 2 
                                            ? this.okTeacher 
                                            : this.okStudent}
                                        openAssistanceFunction={this.openAssistanceManagement}
                                        curso={course} 
                                        enrollToClassMsg={this.state.enrollMessage}
                                        unenrollToClassMsg={this.state.unenrollMessage}
                                        userType = {this.props.idUserType}
                                        />
                                    </Panel>
                                ))}
                            </Collapse>
                        : 
                            <div>NO HAY CURSOS ASIGNADOS</div>
                    ):(
                        null
                    )}   
                </Spin>
            </div>
        )
    }
}
const mapStateToProps=(state)=>{
    return state
}
const mapDispatchToProps = {
    ...TeacherActions,
    ...UsersActions,
    ...CoursesActions,
    ...AssistsActions
};
export default connect (mapStateToProps,mapDispatchToProps)(ClassAssignments);