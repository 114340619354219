import React,{Component} from 'react'
import { connect } from 'react-redux';
import {Transfer,Icon,Button,Row,Col,Spin} from 'antd'
import * as UserActionsCreators from '../../redux/actions/UsersActions'
import './CoursesList.css'

    
class CoursesList extends Component{
  state = {
    mockData: [],
    targetKeys: [],
    userIdShowing: (this.props.UsersReducer !== undefined) ? (this.props.UsersReducer.userToShow.idUsuario) : (undefined)
  }

  handleChange = nextTargetKeys => {
    this.setState({ targetKeys: nextTargetKeys });
  }

  filterOption = (inputValue, option) => option.nombre.indexOf(inputValue) > -1;

  componentDidUpdate(){
    if(this.props.UsersReducer.userToShow !== undefined){
      if(this.state.userIdShowing !== this.props.UsersReducer.userToShow.idUsuario){
        this.getCourses()
      }else if(this.props.UsersReducer.updated===true){
        this.getCourses()
        this.props.updateChangeState({
          updated:false
        })
        
      }
    }
    else{
      if(this.state.targetKeys > 0){
        this.setState({ mockData: (this.props.CoursesReducer !== undefined) ? (this.props.CoursesReducer.courses) : ([]),
                        targetKeys: []
                      })
      }
    }
  }

  UNSAFE_componentWillMount(){
    if(this.props.UsersReducer.userToShow.tipoUsuario.idTipoUsuario===3){
      if(this.props.UsersReducer.userToShow.courses === undefined){
        this.props.getStudentCoursesRequest({
          userId:this.props.UsersReducer.userToShow.idUsuario,
          jwtToken: this.props.LoginReducer.sessionJWTToken
        })
        this.setState({ mockData: [], targetKeys:[], userIdShowing: this.props.UsersReducer.userToShow.idUsuario });
      }else{
        this.getCourses()  
      }
    }else if (this.props.UsersReducer.userToShow.tipoUsuario.idTipoUsuario===2){
      this.getCourses()
    }
  }
  getCourses = () => {
    let mockData = [],targetKeys = [];
    mockData = (this.props.CoursesReducer !== undefined) ? (this.props.CoursesReducer.courses) : ([]);
    let courses = (this.props.UsersReducer.userToShow.tipoUsuario.idTipoUsuario===2)
                  ?(this.props.UsersReducer.userToShow.maestroCursos)
                  :(this.props.UsersReducer.userToShow.courses)
    if(courses !== undefined){
      targetKeys = courses.map( curso => curso.idCurso)            
    }
    this.setState({ mockData, targetKeys, userIdShowing: this.props.UsersReducer.userToShow.idUsuario });
  }

  onClickUpdate  = () => {
    let cursos = this.state.targetKeys.map(value=>{return{idCurso:value}})
    console.log('sending update courses request')
    this.props.updateCoursesRequest({
      idUsuario:this.state.userIdShowing,
      tipoUsuario:this.props.UsersReducer.userToShow.tipoUsuario.idTipoUsuario,
      cursos,
      jwtToken: this.props.LoginReducer.sessionJWTToken
    })
  }

  render(){
    return(
      <div>
        <Spin spinning={this.props.UsersReducer.loading}>
          <Row justify="space-around" align="middle" style={{padding:'30px'}}>
            <Col span={24} >
              <Transfer
                dataSource={this.state.mockData}
                targetKeys={this.state.targetKeys}
                rowKey={record => record.idCurso}
                listStyle={
                    {
                        width: '45%',
                        height: 300,
                        textAlign:"start"
                    }
                }
                titles={['Sin agregar', 'Agregados']}
                showSearch
                filterOption={this.filterOption}
                onChange={this.handleChange}
                render={item => 
                    (<span>
                        {item.nombre+' '}
                        <Icon 
                            type="tag" 
                            theme="twoTone" 
                            twoToneColor={item.etiqueta} 
                        />
                    </span>)
                }
                locale={
                    {
                        itemUnit: "",
                        itemsUnit: "",
                        notFoundContent: "Lista vacia",
                        searchPlaceholder: "Buscar Curso",
                    }
                }
              />
            </Col>
          </Row>
          <Row justify="space-around" align="middle" >
            <Col span={12} >
              <Button size="large" onClick={this.getCourses}>RESTABLECER VALORES</Button>
            </Col>
            <Col span={12} >
              <Button type="primary" size="large" onClick={this.onClickUpdate}>ACTUALIZAR CURSOS</Button>
            </Col>
          </Row>
        </Spin>
      </div>
    )
  }
}
const mapStateToProps=(state)=>{
    return state
}
export default connect(mapStateToProps,UserActionsCreators)(CoursesList);