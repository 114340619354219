const NewsReducer=(state={}, action)=>{
    if(action.type === 'NEWS_DETAILS_MODAL'){
        return{
            ...state,
            newsDetails:{
                id: action.payload.id,
                visible: action.payload.visible,
                title: action.payload.title !== undefined ? action.payload.title : state.newsDetails.title,
                content: action.payload.content !== undefined ? action.payload.content : state.newsDetails.content,
                date: action.payload.date !== undefined ? action.payload.date : state.newsDetails.date,
                image: action.payload.image !== undefined ? action.payload.image : state.newsDetails.image
            }
        }
    }
    else if(action.type === 'ADD_NEWS_MODAL'){
        return{
            ...state,
            showAddNews: action.payload.visible
        }
    }
    else if(action.type === 'NEWS_REQ'){
        return{
            ...state,
            inProgress: action.payload.inProgress
        }
    }
    else if(action.type === 'SET_NEWS'){
        return{
            ...state,
            newsList: action.payload.newsList
        }
    }
    else if(action.type === 'ADD_NEWS_REQ'){
        return{
            ...state,
            addingNewInProgress: action.payload.addingNewInProgress
        }
    }
    else if(action.type === 'DELETE_NEWS_REQ'){
        return{
            ...state,
            deletingNewInProgress: action.payload.deletingNewInProgress
        }
    }
    else if(action.type === 'NEW_CREATED'){
        let newCreated = action.payload.newCreated
        if(state.newsList.length<6){
            state.newsList.unshift(newCreated)
        }else{
            let newDueDate = new Date(newCreated.fechaVencimiento);
            let firstDate = new Date(state.newsList[0].fechaVencimiento)
            let lastDate = new Date(state.newsList[5].fechaVencimiento)
            if(newDueDate >= firstDate && newDueDate <= lastDate){
                state.newsList.pop()
                state.newsList.unshift(newCreated)
            }
        }
        return{
            ...state,
            newsCreationSuccess: true
        }
    }
    else if(action.type === 'SET_CREATION_SUCCESS'){
        return{
            ...state,
            newsCreationSuccess: action.payload.newsCreationSuccess
        }
    }
    else if(action.type === 'NEW_DELETED'){
        let newsListUpdated = state.newsList.filter( news => news.idNoticia !== action.payload.idDeleted)
        return{
            ...state,
            newsDeletedSuccess: true,
            newsList: newsListUpdated
        }
    }
    else if(action.type === 'SET_DELETED_SUCCESS'){
        return{
            ...state,
            newsDeletedSuccess: action.payload.newsDeletedSuccess
        }
    }
    else {
        return state
    }
}
export default NewsReducer;