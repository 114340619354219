import React,{Component} from 'react'
import { Form, Row, Col, Input, Button, Select, Icon, Popconfirm  } from 'antd'
import { connect } from 'react-redux';
import * as CoursesActionsCreators from '../../redux/actions/CoursesActions'
import  {COURSES_COLLORS, COURSES_DURATION } from '../../utility/AppConstants'
const { Option } = Select;

class CourseInfoForm extends Component{

    state={
        idCurso: this.props.CoursesReducer.courseDetails.idCurso,
        nombre: this.props.CoursesReducer.courseDetails.nombre,
        cupo: this.props.CoursesReducer.courseDetails.cupo,        
        etiqueta: this.props.CoursesReducer.courseDetails.etiqueta,
        duracion: this.props.CoursesReducer.courseDetails.duracion,
        tolerancia: this.props.CoursesReducer.courseDetails.tolerancia
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id] : e.target.value
        })
    
    }
    onChangeSelectInputCourseColor = (value) => {
        this.setState({
            etiqueta : value
        })
    }
    onChangeSelectInputCourseDuration = (value) => {
        this.setState({
            duracion : value
        })
    }
    onChangeSelectInputCourseTolerancia = (value) => {
        this.setState({
            tolerancia : value
        })
    }
    saveCourse =() =>{
        console.log(this.state)
        this.props.form.validateFields(err => {
            if (!err) {
                console.log('sending course update request')
                this.props.updateCourseInformation({
                    courseToUpdate: this.state,
                    jwtToken: this.props.LoginReducer.sessionJWTToken
                })
            }
          });
    } 
    deleteCourse =() =>{
        console.log(this.state)
        console.log('Deleting course ' + this.state.idCurso)
        this.props.deleteCourse({
            courseIdToDelete: this.state.idCurso,
            jwtToken: this.props.LoginReducer.sessionJWTToken
        })
    } 
    componentDidUpdate(){   
        if(this.props.CoursesReducer.showDetails === false && this.state.idCurso !== null){ //if close the information modal, set as null the id of user to show
            this.setState({idCurso: null})
        }else{//if the modal is open
            if(this.props.CoursesReducer.courseDetails !== undefined){//if there is a user available to show
                if(this.props.CoursesReducer.updateCourseFail){ //if the data update fails, reset the fields with the initial values;
                    this.props.form.resetFields();
                    this.setState({idCurso: null})
                    this.props.setUpdateCourseFail({updateCourseFail: null})
                }
                if(this.state.idCurso !== this.props.CoursesReducer.courseDetails.idCurso){//if the user id in the state is different to the id of the user to show, ww load the user to show information
                    this.setState({
                        idCurso: this.props.CoursesReducer.courseDetails.idCurso,
                        nombre: this.props.CoursesReducer.courseDetails.nombre,
                        cupo: this.props.CoursesReducer.courseDetails.cupo,        
                        etiqueta: this.props.CoursesReducer.courseDetails.etiqueta,
                        duracion: this.props.CoursesReducer.courseDetails.duracion,
                        tolerancia: this.props.CoursesReducer.courseDetails.tolerancia
                    })
                }
            }
        }
    }
    render(){
        const { getFieldDecorator } = this.props.form;
        return(
            <div>
                    <Form style={{paddingBottom:'30px'}}>  
                        <Row gutter={16} type="flex" justify="center" style={{alignItems: 'center'}}>
                            <Col span={12}>
                                <Form.Item label="Nombre">
                                {getFieldDecorator('nombre', {
                                    initialValue: this.state.nombre,
                                    rules: [{ required: true, message: 'Introduzca un nombre para el curso' }],
                                })(<Input id='nombre' placeholder="Introduzca nombre de curso" onChange={this.handleChange}  />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16} type="flex" justify="center" style={{alignItems: 'center'}}>
                            <Col span={12}>
                                <Form.Item label="Cupo">
                                {getFieldDecorator('cupo', {
                                    initialValue: this.state.cupo,
                                    rules: [{ required: true, message: 'Introduzca un cupo maximo para el curso' }],
                                })(<Input id='cupo' placeholder="Introduzca el cupo maximo del curso" onChange={this.handleChange}  />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16} type="flex" justify="center" style={{alignItems: 'center'}}>
                            <Col span={12} >
                                <Form.Item label="Color">
                                    {getFieldDecorator('etiqueta', {
                                        initialValue: 
                                            <Icon 
                                                type="tag" 
                                                theme="twoTone" 
                                                twoToneColor={this.state.etiqueta} 
                                                style={{fontSize:30}} />,
                                        rules: [{ required: true, message: 'Seleccione un color para el curso' }],
                                    })(
                                    <Select placeholder="Color del curso" onChange={this.onChangeSelectInputCourseColor}>
                                        {COURSES_COLLORS !== undefined ? (
                                            COURSES_COLLORS.map((color, id)=> 
                                                <Option key={id} value={color}>
                                                    <Icon 
                                                        type="tag" 
                                                        theme="twoTone" 
                                                        twoToneColor={color} 
                                                        style={{fontSize:30}} 
                                                    />
                                                </Option>
                                            )
                                        ) : (
                                            <Option  value='0'>COLORES NO DISPONIBLES</Option>
                                        )}
                                    </Select>,
                                    )}
                                </Form.Item>
                            </Col>
                        </Row> 
                        <Row gutter={16} type="flex" justify="center" style={{alignItems: 'center'}}>
                            {console.log("this is the duration: " + this.state.duracion)}
                            <Col span={6} >
                                <Form.Item label="Duración (Meses)">
                                    {getFieldDecorator('duration', {
                                        initialValue: <div>{this.state.duracion}</div>,
                                        rules: [{ required: true, message: 'Seleccione la duracion del curso en meses' }],
                                    })(
                                    <Select id='duration' placeholder="Duración del curso" onChange={this.onChangeSelectInputCourseDuration}>
                                        {COURSES_DURATION !== undefined ? (
                                            COURSES_DURATION.map((duration)=> 
                                                <Option key={duration} value={duration}>
                                                <div>{duration}</div>
                                                </Option>
                                            )
                                        ) : (
                                            <Option  value='0'>NUMERO DE MESES NO DISPONIBLES</Option>
                                        )}
                                    </Select>,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={6} >
                                <Form.Item label="Tolerancia (Meses)">
                                    {getFieldDecorator('tolerancia', {
                                        initialValue: <div>{this.state.tolerancia}</div>,
                                        rules: [{ required: true, message: 'Seleccione la tolerancia del curso en meses' }],
                                    })(
                                    <Select id='tolerancia' placeholder="Tolerancia del curso" onChange={this.onChangeSelectInputCourseTolerancia}>
                                        {COURSES_DURATION !== undefined ? (
                                            COURSES_DURATION.map((duration)=> 
                                                <Option key={duration} value={duration}>
                                                <div>{duration}</div>
                                                </Option>
                                            )
                                        ) : (
                                            <Option  value='0'>NUMERO DE MESES NO DISPONIBLES</Option>
                                        )}
                                    </Select>,
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>

                    <Row justify="space-around" align="middle" >
                        <Col span={12} >
                            <Button type="primary" size="large" onClick={this.saveCourse}>GUARDAR CAMBIOS</Button>
                        </Col>
                        <Col span={12} >
                            <Popconfirm
                            title     = { '¿Desea eliminiar este curso?'} 
                            icon      = { <Icon type='close-circle' style={{ color:'red' }}/> }
                            okText    = "ELIMINAR"
                            okType    = "danger"
                            cancelText= "Cancelar"
                            onConfirm = { this.deleteCourse } >
                                <Button type="danger" size="large">ELIMINAR CURSO</Button>
                            </Popconfirm>
                        </Col>
                    </Row>
            </div>
        )
    }
}
const mapStateToProps=(state)=>{
    return state
}
export default connect (mapStateToProps,CoursesActionsCreators)(Form.create()(CourseInfoForm));