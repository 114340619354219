import React,{Component} from 'react'
import {Menu,Icon,Layout} from 'antd'
import {Link } from 'react-router-dom';
import { connect } from 'react-redux';

const { Sider } = Layout;

class MenuBar extends Component{
    adminMenu =()=>{
        return(
            <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
                <Menu.Item key="1" disabled={this.props.NotificationsReducer.rqInProgress}>
                    <Icon type="home" />
                    <span>Inicio</span>
                    <Link to="/home" />
                </Menu.Item>
                <Menu.Item key="2" disabled={this.props.NotificationsReducer.rqInProgress}>
                    <Icon type="team" />
                    <span>Alumnos</span>
                    <Link to="/admin/students" />
                </Menu.Item>
                <Menu.Item key="3" disabled={this.props.NotificationsReducer.rqInProgress}>
                    <Icon type="appstore" />
                    <span>Administracion</span>
                    <Link to="/admin/administration" />
                </Menu.Item>
                <Menu.Item key="4" disabled={this.props.NotificationsReducer.rqInProgress}>
                    <Icon type="book" />
                    <span>Cursos</span>
                    <Link to="/courses" />
                </Menu.Item>
                <Menu.Item key="5" disabled={this.props.NotificationsReducer.rqInProgress}>
                    <Icon type="bell" />
                    <span>Notificaciones</span>
                    <Link to="/notifications" />
                </Menu.Item>
            </Menu>
        )
    }
    teachersMenu =()=>{
        return (
            <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
                <Menu.Item key="1">
                    <Icon type="home" />
                    <span>Inicio</span>
                    <Link to="/home" />
                </Menu.Item>
                <Menu.Item key="2">
                    <Icon type="book" />
                    <span>Mis Cursos</span>
                    <Link to="/courses" />
                </Menu.Item>
            </Menu>
        )
    }
    studentsMenu =()=>{
        return (
            <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
                 <Menu.Item key="1" disabled={this.props.NotificationsReducer.rqInProgress}>
                    <Icon type="home" />
                    <span>Inicio</span>
                    <Link to="/home" />
                </Menu.Item>
                <Menu.Item key="2" disabled={this.props.NotificationsReducer.rqInProgress}>
                    <Icon type="book" />
                    <span>Mis Cursos</span>
                    <Link to="/courses" />
                </Menu.Item>
                <Menu.Item key="3" disabled={this.props.NotificationsReducer.rqInProgress}>
                    <Icon type="bell" />
                    <span>Notificaciones</span>
                    <Link to="/notifications" />
                </Menu.Item>
            </Menu>
        )
    }
    getMenu(){
        return this.props.LoginReducer.sessionInformation.user.userType===3
        ?(this.studentsMenu())
        :(this.props.LoginReducer.sessionInformation.user.userType===1
            ?(this.adminMenu())
            :(this.teachersMenu())
        )
    }
    render(){
        return(
            this.props.LoginReducer.sessionInformation !== undefined?
            <Sider
             collapsed={this.props.collapsed}
             onCollapse={this.props.onCollapse}>
                {this.getMenu()}
            </Sider>
            :null
        )
    }
}
const mapStateToProps=(state)=>{
    return state
}
export default connect (mapStateToProps)(MenuBar);