const SedeReducer=(state={}, action)=>{
    if(action.type === 'SET_LOADING_SEDE'){
        return{
            ...state,
            loading: action.payload.loading,
        }
    }
    else if(action.type === 'SET_UPDATED_DATA_SEDE'){
        return{
            ...state,
            updatedData: action.payload.updatedData,
            loading: action.payload.loading,
        }
    }
    else {
        return state
    }
}
export default SedeReducer;