const LoginReducer=(state={}, action)=>{
    if(action.type === 'LOGIN_RESPONSE'){
        return{
            ...state,
            successLogin: action.payload.successLogin,
            sessionJWTToken: action.payload.sessionJWTToken,
            sessionModule: action.payload.sessionModule,
            loadingLogin: action.payload.loadingLogin
        }
    }
    else if(action.type === 'SET_LOADING'){
        return{
            ...state,
            loadingLogin: action.payload.loadingLogin
        }
    }
    else if(action.type === 'SET_USER_IN_SESSION'){
        return{
            ...state,
            sessionInformation: action.payload
        }
    }
    else if(action.type === 'PWD_UPDATED'){
        let user = state.sessionInformation.user
        user.resetPwd = "0"
        console.log(user)
        return{
            ...state,
            sessionInformation: {
                user
            }
        }
    }
    else if(action.type === 'PWD_CHG_REQ_MODAL'){
        return{
            ...state,
            pwdChgReqVisible: action.payload.pwdChgReqVisible
        }
    }
    else if(action.type === 'PWD_CHANGED'){
        return{
            ...state,
            pwdChanged: action.payload.pwdChanged
        }
    }else if(action.type === 'SET_LOADING_CR'){
        return{
            ...state,
            loadingCh: action.payload.loadingCh
        }
    }else if(action.type === 'SET_REQUEST_IN_PROGRESS'){
        return{
            ...state,
            requestInProgress: action.payload.requestInProgress
        }
    }
    else {
        return state
    }
}
export default LoginReducer;