import React, {Component} from 'react'
import './NewsPanel.css'
import {Row, Col, Button, Tag} from 'antd'
import { connect } from 'react-redux';
import * as NewsActions from '../../redux/actions/NewsActions'
import { ClockCircleOutlined } from '@ant-design/icons';

class New extends Component{
    limitedText = (text) => {
        if(text !== null && text.length>80){
            return text.substring(0,80)+'...'
        }
        return text
    }
    openNew =()=>{
        this.props.newsDetailsModal({
            id: this.props.new.idNoticia,
            visible:true,
            title:this.props.new.titulo,
            content:this.props.new.contenido, 
            date:this.props.new.fechaCreacion, 
            image:require('../../images/axss-news.png')
        })
    }
    
    render(){
        return(
            <Row className='panel-item-news' type='flex' justify='center' align='top'>
                <Col className='panel-item-col-news' xs={24} /*sm={16}*/>
                    <Row className='new-image' type='flex' justify='center' align='middle'>
                        <img alt="new" className='responsive-image' src={require('../../images/axss-news.png')} />
                    </Row>
                </Col>
                <Col className='panel-item-col-news' xs={24} /*sm={16}*/>
                    <Row className='new-section new-content-section'>
                        <Row className='new-title' type='flex' justify='space-between' align='middle'>
                            {this.props.new.titulo}
                            <Tag color="#000000"  > <ClockCircleOutlined /> {this.props.new.fechaCreacion} </Tag>
                        </Row>
                        <Row className='new-content'> 
                            {this.limitedText(this.props.new.contenido)}
                        </Row>
                    </Row>
                </Col>
                <Col className='panel-item-col-news' xs={24} /*sm={16}*/>
                    <Row className='new-section' type='flex' justify='end' align='top'>
                        <Button className='panel-item-btn-news' onClick={(dispatch)=>this.openNew(dispatch)} >VER NOTICIA</Button>
                    </Row>
                </Col>
            </Row>
        )
    }
}
const mapStateToProps=(state)=>{
    return state
}
export default connect(mapStateToProps,NewsActions)(New);