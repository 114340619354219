import React,{Component} from 'react'
import {Modal, Tabs, Spin} from 'antd'
import { connect } from 'react-redux';
import * as CoursesActions from '../../redux/actions/CoursesActions'
import CourseInfoForm from './CourseInfoForm'
import { USUARIO,ADMINISTRADOR,INSTRUCTOR } from '../../utility/AppConstants'
import ClassAssignments from '../classes/ClassAssignments'
import LessonsContainer from '../lessons/LessonsContainer'


const { TabPane } = Tabs;

class CourseDetailsModal extends Component{
    handleCloseModal = () => {
        this.props.courseDetails({
            showDetails : false,
            courseDetails : undefined
        })
    }
    getView=()=>{
        return this.props.LoginReducer.sessionInformation.user.userType === 1
        ? ADMINISTRADOR
        : this.props.LoginReducer.sessionInformation.user.userType === 2 
            ? INSTRUCTOR
            : USUARIO
    }
    render(){
        return(
            <div>
                {(this.props.CoursesReducer.courseDetails !== undefined && this.props.CoursesReducer.courseDetails !== null )?(
                    <Modal
                    visible={this.props.CoursesReducer.showDetails}
                    title={this.props.CoursesReducer.courseDetails.nombre}
                    onCancel={this.handleCloseModal}
                    footer={false}
                    width={800}
                    >
                        <Spin spinning={this.props.CoursesReducer.loading === true}>
                            <Tabs defaultActiveKey={this.props.LoginReducer.sessionInformation.user.userType === 1?'1':'2'}>
                                {this.props.LoginReducer.sessionInformation.user.userType === 1
                                ?
                                    <TabPane tab="INFORMACION DEL CURSO" key="1">
                                        <CourseInfoForm />
                                    </TabPane>
                                :null}
                                <TabPane tab="TEMARIO" key="2">
                                    <LessonsContainer
                                        idCourse={this.props.CoursesReducer.courseDetails.idCurso}/>
                                </TabPane>
                                <TabPane tab="CALENDARIO" key="3">
                                    <ClassAssignments 
                                        view={this.getView()}
                                        loadingFlag={this.props.CoursesReducer.loading} 
                                        visibilityFlag={this.props.CoursesReducer.showDetails} 
                                        courses={[this.props.CoursesReducer.courseDetails]} 
                                        idUserType={this.props.LoginReducer.sessionInformation.user.userType} 
                                        idUser={this.props.LoginReducer.sessionInformation.user.userId} 
                                        studentLimitHrs ={this.props.LoginReducer.sessionInformation.user.intensiveMode==="1" ? 8 : 4}
                                        userEnable = {this.props.LoginReducer.sessionInformation.user.userEnable==="1" ? true : false} 
                                    />
                                         
                                </TabPane>
                            </Tabs>
                        </Spin>
                    </Modal>
                ):(null)}
            </div>
        )
    }

}
const mapStateToProps=(state)=>{
    return state
}
export default connect(mapStateToProps,CoursesActions)(CourseDetailsModal);
 