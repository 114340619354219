import React, {Component} from 'react'
import './CoursesPanel.css'
import {Row, Col, Button} from 'antd'
import { connect } from 'react-redux';
import * as CoursesActions from '../../redux/actions/CoursesActions'

class CoursesPanelItem extends Component{
    hexToRgb = (colorHex) => {
        let hex = colorHex.replace('#','')
        let aRgbHex  = hex.match(/.{1,2}/g)
        let rgb = [
            parseInt(aRgbHex[0], 16),
            parseInt(aRgbHex[1], 16),
            parseInt(aRgbHex[2], 16)
        ]
        return 'rgba(' + rgb + ',0.5)';
    }
    showLessons = (courseId, index) => {
        if(this.props.CoursesReducer.availableCourses[index].lessons === undefined){
            console.log('call service to retrieve lessons of course id ' +courseId+ ' and open lessons details window for course in index '+index)
            this.props.getLessonsByCourseRequets({
                jwtToken: this.props.LoginReducer.sessionJWTToken,
                courseId:courseId,
                courseIndex:index
            })
        }else{
            console.log('open lessons details window for course in index '+index)
            this.props.setShowCourseLessons({showCourseLessons:true})
            this.props.setCourseInformation({courseInformation:this.props.CoursesReducer.availableCourses[index]})
        }
    }
    render(){
        return(
            <Row className='panel-item' type='flex' justify='center' align='middle' style={{backgroundColor: this.props.course.etiqueta/*this.hexToRgb(this.props.course.etiqueta)*/}}>
                <Col className='panel-item-col' xs={24} sm={16}>
                    <Row className='panel-item-title' type='flex' justify='start' align='middle'>
                        {this.props.course.nombre}
                    </Row>
                </Col>
                {<Col className='panel-item-col' xs={24} sm={8}>
                    <Row className='panel-item-row' type='flex' justify='end' align='middle'>
                        <Button className='panel-item-btn' onClick={()=>this.showLessons(this.props.course.idCurso,this.props.index)} >VER TEMARIO</Button>
                    </Row>
                </Col>}
            </Row>
        )
    }
}
const mapStateToProps=(state)=>{
    return state
}
export default connect(mapStateToProps,CoursesActions)(CoursesPanelItem);