const StudentsReducer=(state={}, action)=>{
    if(action.type === 'SET_LOADING_STUDENTS'){
        return{
            ...state,
            loading: action.payload.loading,
        }
    }
    else if(action.type === 'SET_STUDENTS'){
        return{
            ...state,
            students: action.payload.students,
            loading: action.payload.loading,
        }
    }
    else {
        return state
    }
}
export default StudentsReducer;