import  axios from 'axios';
import  {SERVER_URL,
        PATH_ADMIN,
        SEDES,
        ADD
        } from '../../utility/AppConstants'
import { message } from  'antd'

export function addNewSede (payload){
    const NEW_SEDE_URL = SERVER_URL+PATH_ADMIN+SEDES+ADD;
    return( dispatch=>{
        dispatch(setLoadingSede({loading:true}))
        return  axios.post( NEW_SEDE_URL, 
                            {
                                nombre: payload.newSede.name,
                                ciudad: payload.newSede.city,
                            },
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }  
                            }
        ).then( response=>{
            console.log( 'Status Code: ' + response.status )
            if(response.status === 201){
                message.success('LA SEDE SE HA CREADO CORRECTAMENTE');
                console.log( 'Success response -> ' + NEW_SEDE_URL )
                dispatch(setUpdatedDataSede({
                    updatedData:true,
                    loading:false
                }))
            }
        }).catch( error => {
            dispatch(setUpdatedDataSede({
                updatedData:false,
                loading:false
            }))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 400 ){
                    console.error( 'Bad request ->  ' + NEW_SEDE_URL )
                    message.error( 'FORMATO DE DATOS INCORRECTO!' )
                }
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + NEW_SEDE_URL )
                    message.error( 'TU SESION HA EXPIRADO!' )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + NEW_SEDE_URL )
                    message.error( 'NO ESTAS AUTORIZADO PARA REALIZAR ESTA ACCION!' )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + NEW_SEDE_URL )
                    message.error( 'ERROR INTERNO, POR FAVOR INTENTE MAS TARDE Y COMUNIQUESE CON SOPORTE!' )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + NEW_SEDE_URL )
                } 
                message.error( error.response.data.details + ' ('+error.response.status+')' )
            }else{
                console.log( 'Disabled API service -> ' + NEW_SEDE_URL )
            }
        })
    })
}
export function setLoadingSede(payload){
    return{
        type:'SET_LOADING_SEDE',
        payload
    }
}
export function setUpdatedDataSede(payload){
    return{
        type:'SET_UPDATED_DATA_SEDE',
        payload
    }
}