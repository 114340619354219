const AssistsReducer=(state={}, action)=>{
    if(action.type === 'SET_LOADING_ASSISTS'){
        return{
            ...state,
            loading: action.payload.loading,
        }
    }
    else if(action.type === 'SET_STUDENTS_LIST'){
        if(action.payload.studentsRegistredInClass!== undefined){
            action.payload.studentsRegistredInClass.forEach(student => {
                student.key = student.idUsuario
            })
        }
        return{
            ...state,
            studentsRegistredInClass: action.payload.studentsRegistredInClass
        }
    }
    else if(action.type === 'SET_ASSISTS'){
        let assists = [];
        if(state.studentsRegistredInClass!==undefined){
            state.studentsRegistredInClass.forEach(classStudent => {
                if(action.payload.assistsList.includes(classStudent.idUsuario)){
                    assists.push(classStudent.idUsuario)
                } 
            })
        }
        return{
            ...state,
            assistsList: assists
        }
    }
    else if(action.type === 'ASSISTS_UPDATED'){
        return{
            ...state,
            assistsUpdated: action.payload.assistsUpdated
        }
    }
    else if(action.type === 'SET_STUDENT_ASSISTS'){
        for (let student of state.studentsRegistredInClass) {
            if(student.idUsuario === action.payload.studentId) {
                student.assists = action.payload.studentAssistsByIdLesson
                break;
            }
        }
        return{
            ...state,
            loading: action.payload.loading,
            correctRetrieve: action.payload.correctRetrieve,
        }
    }
    else if(action.type === 'SET_TRACKER_ASSISTS'){
        return{
            ...state,
            showTrackerAssists: action.payload.showTrackerAssists
        }
    }
    else if(action.type === 'SET_CORRECT_RETRIEVE'){
        return{
            ...state,
            correctRetrieve: action.payload.correctRetrieve
        }
    }
    else if(action.type === 'UPDATE_ASSISTS_RESULT'){
        let objToReturn
        if(action.payload.result === true){
            objToReturn =   {
                                ...state,
                                result: action.payload.result,
                                assistsList: action.payload.assistsList
                            }
        }else{
            objToReturn =   {
                ...state,
                result: action.payload.result
            }
        }
        return objToReturn
    }
    else if(action.type === 'SUCCESS_UNENROLMENT'){
        /*action.payload.courseId,
        action.payload.studentId*/
        let studentsRegistredInClassUpdated = state.studentsRegistredInClass.filter(student => student.idUsuario !== action.payload.studentId)
        return{
            ...state,
            loading: action.payload.loading,
            studentsRegistredInClass : studentsRegistredInClassUpdated,
            successUnenrollemnt: action.payload.successUnenrollemnt
        }
    }
    else if(action.type === 'SET_SUCCESS_UNENROLMENT'){
        return{
            ...state,
            successUnenrollemnt: action.payload.successUnenrollemnt
        }
    }
    else if(action.type === 'SAVE_ASSISTANCES_STUDENT'){
        return{
            ...state,
            assistancesInClass:action.payload.assistances,
            lessons:action.payload.lessons,
            correctRetrieve: true
        }
    }
    else if(action.type === 'CLOSE_ASSISTANCES_IN_CLASS'){
        return{
            ...state,
            assistancesInClass:undefined,
            lessons:undefined
        }
    }
    else {
        return state
    }
}
export default AssistsReducer;