import  axios from 'axios';
import  {SERVER_URL,
        PATH_ADMIN,
        COURSES,
        ADD,
        COURSE_UPDATE,
        ADD_TEACHER,
        CLASSES_II,
        DELETE,
        LESSON,
        EDIT,
        TEACHERS,
        USER,
        STUDENTS,
        CLASSES,
        PATH_USER
        } from '../../utility/AppConstants'
import { message } from  'antd'



export function getAvailableCoursesRequest(payload){

    let GET_AVAILABLE_COURSES_URL = SERVER_URL+PATH_ADMIN+COURSES;    
    console.log(GET_AVAILABLE_COURSES_URL)
    return( dispatch=>{
        dispatch(setLoadingCourses({loading:true}))
        return axios.get(   GET_AVAILABLE_COURSES_URL,
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }
                            }
        ).then( response => {
            console.log( 'Status Code: ' + response.status )
            if( response.status === 200 ){
                console.log( 'Success response -> ' + GET_AVAILABLE_COURSES_URL )
                console.log(response.data)
                dispatch( setAvailableCourses({
                    courses:response.data,
                    loading:false
                }))
                dispatch(refreshCoursesInCoursesActions({
                    refreshCoursesInCourses: true,
                }))
            }
        }).catch( error => {
            dispatch( 
                setAvailableCourses({
                    courses:{},
                    loading:false
                })
            )
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + GET_AVAILABLE_COURSES_URL )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + GET_AVAILABLE_COURSES_URL )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + GET_AVAILABLE_COURSES_URL )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + GET_AVAILABLE_COURSES_URL )
                } 
                message.error( error.response.data.details + ' ('+error.response.status+')' )
            }else{
                console.error( 'Disabled API service -> ' + GET_AVAILABLE_COURSES_URL )
            }
        })  
    })
}

export function getCoursesRequest(payload){
    let GET_COURSES_URL;
    if(payload.userType === 1){
        GET_COURSES_URL = SERVER_URL+PATH_ADMIN+COURSES;
    }else if(payload.userType === 2){
        GET_COURSES_URL = SERVER_URL+PATH_ADMIN+TEACHERS+'/'+payload.userId+'/'+COURSES;
    }else{
        GET_COURSES_URL = SERVER_URL+PATH_ADMIN+USER+payload.userId+'/'+COURSES;
    }
    console.log(GET_COURSES_URL)
    return( dispatch=>{
        dispatch(setLoadingCourses({loading:true}))
        return axios.get(   GET_COURSES_URL,
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }
                            }
        ).then( response => {
            console.log( 'Status Code: ' + response.status )
            if( response.status === 200 ){
                console.log( 'Success response -> ' + GET_COURSES_URL )
                console.log(response.data)
                dispatch( setCourses({
                    courses:response.data,
                    loading:false
                }))
                dispatch(refreshCoursesInCoursesActions({
                    refreshCoursesInCourses: true,
                }))
            }
        }).catch( error => {
            dispatch( 
                setCourses({
                    courses:{},
                    loading:false
                })
            )
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + GET_COURSES_URL )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + GET_COURSES_URL )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + GET_COURSES_URL )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + GET_COURSES_URL )
                } 
                message.error( error.response.data.details + ' ('+error.response.status+')' )
            }else{
                console.error( 'Disabled API service -> ' + GET_COURSES_URL )
            }
        })  
    })
}
export function getCourseRequest(payload){
    const GET_COURSE_URL = SERVER_URL+PATH_ADMIN+COURSES+'/'+payload.courseId;
    return( dispatch=>{
        dispatch(setLoadingCourses({loading:true}))
        return axios.get(   GET_COURSE_URL,
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }
                            }
        ).then( response => {
            console.log( 'Status Code: ' + response.status )
            if( response.status === 200 ){
                console.log( 'Success response -> ' + GET_COURSE_URL )
                console.log(response.data)
                dispatch(courseDetails({
                    showDetails : true,
                    courseDetails : response.data
                }))
                dispatch(setLoadingCourses({loading:false}))
            }
        }).catch( error => {
            dispatch(setLoadingCourses({loading:false}))
            dispatch(courseDetails({
                showDetails : false,
                courseDetails : null
            }))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + GET_COURSE_URL )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + GET_COURSE_URL )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + GET_COURSE_URL )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + GET_COURSE_URL )
                } 
                message.error( error.response.data.details + ' ('+error.response.status+')' )
            }else{
                console.error( 'Disabled API service -> ' + GET_COURSE_URL )
            }
        })  
    })
}
export function setLoadingCourses(payload){
    return{
        type:'SET_LOADING_COURSES',
        payload
    }
}
export function setCourses(payload){
    return{
        type:'SET_COURSES',
        payload
    }
}
export function setAvailableCourses(payload){
    return{
        type:'SET_AVAILABLE_COURSES',
        payload
    }
}
export function getStudentCourseRequest(payload){
    const GET_STUDENT_COURSES_URL = SERVER_URL+PATH_ADMIN+STUDENTS+'/'+payload.studentId+'/'+ COURSES+'/'+payload.courseId+CLASSES;
    return( dispatch=>{
        dispatch(setLoadingCourses({loading:true}))
        return axios.get(   GET_STUDENT_COURSES_URL,
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }
                            }
        ).then( response => {
            console.log( 'Status Code: ' + response.status )
            if( response.status === 200 ){
                console.log( 'Success response -> ' + GET_STUDENT_COURSES_URL )
                console.log(response.data)
                dispatch(studentCourseDetails({
                    showDetails : true,
                    studentCourseDetails : response.data
                }))
                dispatch(setLoadingCourses({loading:false}))
            }
        }).catch( error => {
            dispatch(setLoadingCourses({loading:false}))
            dispatch(courseDetails({
                showDetails : false,
                courseDetails : null
            }))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + GET_STUDENT_COURSES_URL )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + GET_STUDENT_COURSES_URL )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + GET_STUDENT_COURSES_URL )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + GET_STUDENT_COURSES_URL )
                } 
                message.error( error.response.data.details + ' ('+error.response.status+')' )
            }else{
                console.error( 'Disabled API service -> ' + GET_STUDENT_COURSES_URL )
            }
        })  
    })
}
export function studentCourseDetails(payload){
    return{
        type:'STUDENT_COURSE_DETAILS',
        payload
    }
}
export function addNewCourse (payload){
    const NEW_COURSE_URL = SERVER_URL+PATH_ADMIN+COURSES+'/'+ADD;
    return( dispatch=>{
        dispatch(setLoadingCourses({loading:true}))
        return  axios.post( NEW_COURSE_URL, 
                            {
                                nombre : payload.newCourse.courseName,
                                cupo : payload.newCourse.capability,
                                etiqueta : payload.newCourse.tagColor,
                                duracion : payload.newCourse.duration,
                                tolerancia : payload.newCourse.tolerancia
                            },
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }  
                            }
        ).then( response=>{
            console.log( 'Status Code: ' + response.status )
            if(response.status === 201){
                message.success('EL CURSO SE HA CREADO CORRECTAMENTE');
                console.log( 'Success response -> ' + NEW_COURSE_URL )
                dispatch(setUpdatedDataCourses({
                    updatedData:true,
                    loading:false
                }))
            }
        }).catch( error => {
            dispatch(setUpdatedDataCourses({
                updatedData:false,
                loading:false
            }))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 400 ){
                    console.error( 'Bad request ->  ' + NEW_COURSE_URL )
                    message.error( 'FORMATO DE DATOS INCORRECTO!' )
                }
                else if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + NEW_COURSE_URL )
                    message.error( 'TU SESION HA EXPIRADO!' )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + NEW_COURSE_URL )
                    message.error( 'NO ESTAS AUTORIZADO PARA REALIZAR ESTA ACCION!' )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + NEW_COURSE_URL )
                    message.error( 'ERROR INTERNO, POR FAVOR INTENTE MAS TARDE Y COMUNIQUESE CON SOPORTE!' )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + NEW_COURSE_URL )
                } 
                message.error( error.response.data.details + ' ('+error.response.status+')' )
            }else{
                console.log( 'Disabled API service -> ' + NEW_COURSE_URL )
            }
        })
    })
}
export function updateCourseInformation (payload){
    const UPDATE_COURSE_URL = SERVER_URL+PATH_ADMIN+COURSES+'/'+COURSE_UPDATE
    return( dispatch=>{
        dispatch(setLoadingCourses({loading:true}))
        return  axios.post(UPDATE_COURSE_URL, payload.courseToUpdate,
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }  
                            }
        ).then( response=>{
            console.log( 'Status Code: ' + response.status )
            if(response.status === 201){
                message.success('EL CURSO SE HA ACTUALIZADO CORRECTAMENTE');
                console.log( 'Success response -> ' + UPDATE_COURSE_URL )
                dispatch(setUpdatedDataCourses({
                    updatedData:true,
                    loading:false
                }))
            }
        }).catch( error => {
            dispatch(setUpdatedDataCourses({
                updatedData:false,
                loading:false
            }))
            dispatch(setUpdateCourseFail({
                updateCourseFail: true
            }))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 400 ){
                    console.error( 'Bad request ->  ' + UPDATE_COURSE_URL )
                    message.error( 'FORMATO DE DATOS INCORRECTO!' )
                }
                else if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + UPDATE_COURSE_URL )
                    message.error( 'TU SESION HA EXPIRADO!' )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + UPDATE_COURSE_URL )
                    message.error( 'NO ESTAS AUTORIZADO PARA REALIZAR ESTA ACCION!' )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + UPDATE_COURSE_URL )
                    message.error( 'ERROR INTERNO, POR FAVOR INTENTE MAS TARDE Y COMUNIQUESE CON SOPORTE!' )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + UPDATE_COURSE_URL )
                } 
                message.error( error.response.data.details + ' ('+error.response.status+')' )
            }else{
                console.log( 'Disabled API service -> ' + UPDATE_COURSE_URL )
            }
        })
    })
}

export function deleteCourse (payload){
    const DELETE_COURSE_URL = SERVER_URL+COURSES+'/'+DELETE+'/'+payload.courseIdToDelete
    return( dispatch=>{
        dispatch(setLoadingCourses({loading:true}))
        return axios.get(DELETE_COURSE_URL,
            {
                headers:{
                    'Authorization': payload.jwtToken
                }
            }
        ).then( response=>{
            console.log( 'Status Code: ' + response.status )
            if(response.status === 200){
                message.success('EL CURSO SE HA ELIMINADO CORRECTAMENTE');
                console.log( 'Success response -> ' + DELETE_COURSE_URL )
                dispatch(courseDetails({
                    showDetails : false,
                    courseDetails : undefined
                }))
                dispatch(setUpdatedDataCourses({
                    updatedData:true, 
                    loading:false
                }))
            }
        }).catch( error => {
            dispatch(setUpdatedDataCourses({
                updatedData:false,
                loading:false
            }))
            dispatch(setUpdateCourseFail({
                updateCourseFail: true
            }))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 400 ){
                    console.error( 'Bad request ->  ' + DELETE_COURSE_URL )
                    message.error( 'FORMATO DE DATOS INCORRECTO!' )
                }
                else if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + DELETE_COURSE_URL )
                    message.error( 'TU SESION HA EXPIRADO!' )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + DELETE_COURSE_URL )
                    message.error( 'NO ESTAS AUTORIZADO PARA REALIZAR ESTA ACCION!' )
                }
                else if( error.response.status === 404 ){
                    console.error( 'Not Found -> ' + DELETE_COURSE_URL )
                    message.error( 'EL CURSO QUE DESEA ELIMINAR NO EXISTE!' )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + DELETE_COURSE_URL )
                    message.error( 'ERROR INTERNO, POR FAVOR INTENTE MAS TARDE Y COMUNIQUESE CON SOPORTE!' )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + DELETE_COURSE_URL )
                } 
                message.error( error.response.data.details + ' ('+error.response.status+')' )
            }else{
                console.log( 'Disabled API service -> ' + DELETE_COURSE_URL )
            }
        })
    })
}


export function addTeacherToClassRequest(payload){
    const ADD_TEACHER_TO_CLASS_URL = SERVER_URL+PATH_ADMIN+CLASSES_II+payload.idClass+'/'+ADD_TEACHER+payload.idUsuario;
    return( dispatch=>{
        dispatch(setLoadingCourses({loading:true}))
        return  axios.get( ADD_TEACHER_TO_CLASS_URL, 
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }  
                            }
        ).then( response=>{
            console.log( 'Status Code: ' + response.status )
            if(response.status === 200){
                
                message.success('EL INSTRUCTOR SE HA AGREGADO CORRECTAMENTE A LA CLASE!');
                console.log( 'Success response -> ' + ADD_TEACHER_TO_CLASS_URL )
                console.log( response.data )
                dispatch(courseDetails({
                    showDetails : true,
                    courseDetails : response.data
                }))
                dispatch(refreshCoursesInCoursesActions({
                    refreshCoursesInCourses: true,
                }))
                dispatch(setLoadingCourses({loading:false}))
            }
        }).catch( error => {
            dispatch(setUpdatedDataCourses({
                updatedData:true,
                loading:false
            }))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 400 ){
                    console.error( 'Bad request ->  ' + ADD_TEACHER_TO_CLASS_URL )
                    message.error( 'FORMATO DE DATOS INCORRECTO!' )
                }
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + ADD_TEACHER_TO_CLASS_URL )
                    message.error( 'TU SESION HA EXPIRADO!' )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + ADD_TEACHER_TO_CLASS_URL )
                    message.error( 'NO ESTAS AUTORIZADO PARA REALIZAR ESTA ACCION!' )
                }
                else if( error.response.status === 404 ){
                    console.error( 'Unauthorized to access -> ' + ADD_TEACHER_TO_CLASS_URL )
                    message.error( 'ID DE CLASE INVALIDO!' )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + ADD_TEACHER_TO_CLASS_URL )
                    message.error( 'ERROR INTERNO, POR FAVOR INTENTE MAS TARDE Y COMUNIQUESE CON SOPORTE!' )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + ADD_TEACHER_TO_CLASS_URL )
                } 
                message.error( error.response.data.details + ' ('+error.response.status+')' )
            }else{
                console.log( 'Disabled API service -> ' + ADD_TEACHER_TO_CLASS_URL )
            }
        })
    })
}

export function deleteClassRequest(payload){
    const DELETE_CLASS_URL = SERVER_URL+PATH_ADMIN+CLASSES_II+payload.idClass+'/'+DELETE;
    return( dispatch=>{
        dispatch(setLoadingCourses({loading:true}))
        return axios.get(   DELETE_CLASS_URL,
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }
                            }
        ).then( response => {
            console.log( 'Status Code: ' + response.status )
            if( response.status === 200 ){
                message.success('LA CLASE SE HA ELIMINADO CORRECTAMENTE DEL CURSO!');
                console.log( 'Success response -> ' + DELETE_CLASS_URL )
                dispatch(classDeleted({
                    idClass: payload.idClass
                }))
                dispatch(refreshCoursesInCoursesActions({
                    refreshCoursesInCourses: true,
                }))
                dispatch(setLoadingCourses({loading:false}))
            }
        }).catch( error => {
            dispatch( 
                dispatch(setUpdatedDataCourses({
                    updatedData:true,
                    loading:false
                }))
            )
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + DELETE_CLASS_URL )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + DELETE_CLASS_URL )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + DELETE_CLASS_URL )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + DELETE_CLASS_URL )
                } 
                message.error( error.response.data.details + ' ('+error.response.status+')' )
            }else{
                console.error( 'Disabled API service -> ' + DELETE_CLASS_URL )
            }
        })  
    })
}


export function addNewCourseClass (payload){
    const NEW_COURSE_CLASS_URL = SERVER_URL+PATH_ADMIN+CLASSES_II+ADD;
    return( dispatch=>{
        dispatch(setLoadingCourses({loading:true}))
        return  axios.post( NEW_COURSE_CLASS_URL, 
                            {
                                curso : { idCurso: payload.curso.idCurso,
                                          cupo: payload.curso.cupo },
                                diaClase : { idDia: payload.idDia },
                                horarioClase : { idHorarioClase: payload.idHorarioClase },
                                availability : payload.curso.cupo
                            },
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }  
                            }
        ).then( response=>{
            console.log( 'Status Code: ' + response.status )
            if(response.status === 201){
                message.success('LA CLASE SE HA CREADO CORRECTAMENTE');
                console.log( 'Success response -> ' + NEW_COURSE_CLASS_URL )
                dispatch(classAdded({
                    newClass:response.data,
                }))
                dispatch(refreshCoursesInCoursesActions({
                    refreshCoursesInCourses: true,
                }))
                dispatch(setLoadingCourses({loading:false}))
            }
        }).catch( error => {
            dispatch(setUpdatedDataCourses({
                updatedData:false,
                loading:false
            }))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 400 ){
                    console.error( 'Bad request ->  ' + NEW_COURSE_CLASS_URL )
                    message.error( 'FORMATO DE DATOS INCORRECTO!' )
                }
                else if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + NEW_COURSE_CLASS_URL )
                    message.error( 'TU SESION HA EXPIRADO!' )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + NEW_COURSE_CLASS_URL )
                    message.error( 'NO ESTAS AUTORIZADO PARA REALIZAR ESTA ACCION!' )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + NEW_COURSE_CLASS_URL )
                    message.error( 'ERROR INTERNO, POR FAVOR INTENTE MAS TARDE Y COMUNIQUESE CON SOPORTE!' )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + NEW_COURSE_CLASS_URL )
                } 
                message.error( error.response.data.details + ' ('+error.response.status+')' )
            }else{
                console.log( 'Disabled API service -> ' + NEW_COURSE_CLASS_URL )
            }
        })
    })
}

export function addNewCourseLesson (payload){
    const NEW_COURSE_LESSON_URL = SERVER_URL+PATH_ADMIN+LESSON+ADD;
    return( dispatch=>{
        dispatch(setLoadingCourses({loading:true}))
        return  axios.post( NEW_COURSE_LESSON_URL, 
                            {
                                curso : { idCurso: payload.idCurso },
                                tituloTema : payload.tituloTema,
                                descripcionTema : payload.descripcionTema,
                            },
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }  
                            }
        ).then( response=>{
            console.log( 'Status Code: ' + response.status )
            if(response.status === 201){
                message.success('EL TEMA SE HA CREADO CORRECTAMENTE');
                console.log( 'Success response -> ' + NEW_COURSE_LESSON_URL )
                dispatch(lessonAdded({
                    newLesson:response.data,
                }))
                /*dispatch(refreshCoursesInCoursesActions({
                    refreshCoursesInCourses: true,
                }))*/
                dispatch(setUpdatedDataCourses({
                    updatedData:false,
                    loading:false
                }))
                
            }
        }).catch( error => {
            dispatch(setUpdatedDataCourses({
                updatedData:false,
                loading:false
            }))
            dispatch(lessonAddedFlag({
                addedLesson:false
            }))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 400 ){
                    console.error( 'Bad request ->  ' + NEW_COURSE_LESSON_URL )
                    message.error( 'FORMATO DE DATOS INCORRECTO!' )
                }
                else if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + NEW_COURSE_LESSON_URL )
                    message.error( 'TU SESION HA EXPIRADO!' )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + NEW_COURSE_LESSON_URL )
                    message.error( 'NO ESTAS AUTORIZADO PARA REALIZAR ESTA ACCION!' )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + NEW_COURSE_LESSON_URL )
                    message.error( 'ERROR INTERNO, POR FAVOR INTENTE MAS TARDE Y COMUNIQUESE CON SOPORTE!' )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + NEW_COURSE_LESSON_URL )
                } 
                message.error( error.response.data.details + ' ('+error.response.status+')' )
            }else{
                console.log( 'Disabled API service -> ' + NEW_COURSE_LESSON_URL )
            }
        })
    })
}

export function deleteLessonRequest(payload){
    const DELETE_LESSON_URL = SERVER_URL+PATH_ADMIN+LESSON+DELETE+'/'+payload.idLesson;
    return( dispatch=>{
        dispatch(setLoadingCourses({loading:true}))
        return axios.get(   DELETE_LESSON_URL,
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }
                            }
        ).then( response => {
            console.log( 'Status Code: ' + response.status )
            if( response.status === 200 ){
                message.success('EL TEMA SE HA ELIMINADO CORRECTAMENTE');
                console.log( 'Success response -> ' + DELETE_LESSON_URL )
                console.log(response.data)
                dispatch(lessonDeleted({
                    idLessonDelete:payload.idLesson,
                }))
                /*dispatch(refreshCoursesInCoursesActions({
                    refreshCoursesInCourses: true,
                }))*/
                dispatch(setUpdatedDataCourses({
                    updatedData:false,
                    loading:false
                }))
            }
        }).catch( error => {
            dispatch( 
                setCourses({
                    courses:{},
                    loading:false
                })
            )
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + DELETE_LESSON_URL )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + DELETE_LESSON_URL )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + DELETE_LESSON_URL )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + DELETE_LESSON_URL )
                } 
                message.error( error.response.data.details + ' ('+error.response.status+')' )
            }else{
                console.error( 'Disabled API service -> ' + DELETE_LESSON_URL )
            }
        })  
    })
}
export function editLessonRequest (payload){
    const EDIT_LESSON_URL = SERVER_URL+PATH_ADMIN+LESSON+EDIT;
    return( dispatch=>{
        dispatch(setLoadingCourses({loading:true}))
        return  axios.post( EDIT_LESSON_URL, 
                            {
                                idTema : payload.idTema,
                                tituloTema : payload.tituloTema,
                                descripcionTema : payload.descripcionTema,
                            },
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }  
                            }
        ).then( response=>{
            console.log( 'Status Code: ' + response.status )
            if(response.status === 200){
                message.success('EL TEMA SE HA MODIFICADO CORRECTAMENTE');
                console.log( 'Success response -> ' + EDIT_LESSON_URL )
                dispatch(lessonEdited({
                    lessonEdited:response.data,
                }))
                dispatch(setUpdatedDataCourses({
                    updatedData:true,
                    loading:false
                }))
                dispatch(updateLessonModal({
                    updateLessonModal:false,
                    lesson:undefined
                }))
            }
        }).catch( error => {
            dispatch(setUpdatedDataCourses({
                updatedData:false,
                loading:false
            }))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 400 ){
                    console.error( 'Bad request ->  ' + EDIT_LESSON_URL )
                    message.error( 'FORMATO DE DATOS INCORRECTO!' )
                }
                else if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + EDIT_LESSON_URL )
                    message.error( 'TU SESION HA EXPIRADO!' )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + EDIT_LESSON_URL )
                    message.error( 'NO ESTAS AUTORIZADO PARA REALIZAR ESTA ACCION!' )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + EDIT_LESSON_URL )
                    message.error( 'ERROR INTERNO, POR FAVOR INTENTE MAS TARDE Y COMUNIQUESE CON SOPORTE!' )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + EDIT_LESSON_URL )
                } 
                message.error( error.response.data.details + ' ('+error.response.status+')' )
            }else{
                console.log( 'Disabled API service -> ' + EDIT_LESSON_URL )
            }
        })
    })
}
export function refreshCoursesInCoursesActions(payload){
    return{
        type:'REFRESH_COURSES_IN_COURSES',
        payload
    }
}
export function setUpdatedDataCourses(payload){
    return{
        type:'SET_UPDATED_DATA_COURSE',
        payload
    }
}
export function courseDetails(payload){
    return{
        type:'COURSE_DETAILS',
        payload
    }
}

export function setUpdateCourseFail(payload){
    return{
        type:'UPDATE_COURSE_FAIL',
        payload
    }
}
export function classDeleted(payload){
    return{
        type:'DELETE_CLASS',
        payload
    }
}
export function classAdded(payload){
    return{
        type:'ADD_CLASS',
        payload
    }
}
export function lessonAdded(payload){
    return{
        type:'ADD_LESSON',
        payload
    }
}
export function lessonDeleted(payload){
    return{
        type:'DELETE_LESSON',
        payload
    }
}
export function lessonAddedFlag(payload){
    return{
        type:'LESSON_ADDED',
        payload
    }
}
export function updateLessonModal(payload){
    return{
        type:'UPDATE_LESSON_MODAL',
        payload
    }
}
export function lessonEdited(payload){
    return{
        type:'LESSON_EDITED',
        payload
    }
}

export function assistanceManagementModal(payload){
    return{
        type:'ASSISTANCE_MANAGEMENT_MODAL',
        payload
    }
}

export function classEnrollmentRequestByStudent(payload){ //changes here
    console.log('*******')
    console.log('jwtToken ', payload.jwtToken)
    console.log('action ', payload.action)
    console.log('idUsuarioAlumno ' , payload.idUsuarioAlumno)
    console.log('idCourse ', payload.idCourse)
    console.log('idClase ' , payload.idClase)
    console.log('*******')
    const CLASS_ENROLLMENT_URL = SERVER_URL+PATH_USER+STUDENTS+CLASSES+'/'+payload.action+'/'+payload.userType;
    return( dispatch=>{
        dispatch(setLoadingCourses({loading:true}))
        return  axios.post( CLASS_ENROLLMENT_URL, 
                            {
                                alumno: {
                                    idUsuario: payload.idUsuarioAlumno
                                },
                                idClase: payload.idClase
                            },
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }  
                            }
        ).then( response=>{
            console.log( 'Status Code: ' + response.status )
            if(response.status === 201){
                if(payload.action === 'add'){
                    message.success('EL USUARIO SE HA INSCRITO A LA CLASE CORRECTAMENTE!');
                }else{
                    message.success('EL USUARIO SE HA DADO DE BAJA DE LA CLASE CORRECTAMENTE!');
                }
                console.log( 'Success response -> ' + CLASS_ENROLLMENT_URL )
                dispatch(setUserEnrolledUpdateByStudent({
                    idCourse: payload.idCourse,
                    idClase: payload.idClase,
                    action: payload.action ,
                    refreshCoursesInCourses:true,
                    loading:false
                }))
            }
        }).catch( error => {
            dispatch(setLoadingCourses({loading:false}))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 400 ){
                    console.error( 'Bad request ->  ' + CLASS_ENROLLMENT_URL )
                    message.error( 'FORMATO DE DATOS INCORRECTO!' )
                }
                else if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + CLASS_ENROLLMENT_URL )
                    message.error( 'TU SESION HA EXPIRADO!' )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + CLASS_ENROLLMENT_URL )
                    message.error( 'NO ESTAS AUTORIZADO PARA REALIZAR ESTA ACCION!' )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + CLASS_ENROLLMENT_URL )
                    message.error( 'ERROR INTERNO, POR FAVOR INTENTE MAS TARDE Y COMUNIQUESE CON SOPORTE!' )
                }
                else if(error.response.status === 507){
                    message.error('EL CUPO DE ESTA CLASE HA LLEGADO A SU LIMITE!');
                }
                else if(error.response.status === 510){
                    message.error('RECUERDA: PARA ASIGNARTEA UN HORARIO, DEBES HACERLO CON 24 HORAS DE ANTICIPACION!');
                }
                else{
                    console.error( 'Unidentified error -> -> ' + CLASS_ENROLLMENT_URL )
                } 
            }else{
                console.log( 'Disabled API service -> ' + CLASS_ENROLLMENT_URL )
            }
        })
    })
}
export function setUserEnrolledUpdateByStudent(payload){
    return{
        type:'USER_ENROLLED_UPDATE_BY_STUDENT',
        payload
    }
}
export function getLessonsByCourseRequets(payload){

    let LESSONS_BY_COURSE_REQ = SERVER_URL+PATH_ADMIN+COURSES+'/'+payload.courseId+'/'+LESSON;    
    console.log(LESSONS_BY_COURSE_REQ)
    return( dispatch=>{
        dispatch(setLoadingCourses({loading:true}))
        return axios.get(   LESSONS_BY_COURSE_REQ,
                            {
                                headers:{
                                    'Authorization': payload.jwtToken
                                }
                            }
        ).then( response => {
            dispatch(setLoadingCourses({loading:false}))
            console.log( 'Status Code: ' + response.status )
            if( response.status === 200 ){
                console.log( 'Success response -> ' + LESSONS_BY_COURSE_REQ )
                console.log(response.data)
                dispatch( 
                    setLessonsToCourse({
                        courseLessons:response.data,
                        courseIndex : payload.courseIndex,
                        showCourseLessons : true,
                    })
                )
            }
        }).catch( error => {
            dispatch(setLoadingCourses({loading:false}))
            dispatch(setShowCourseLessons({showCourseLessons:false}))
            if(error.response !== undefined){
                console.error( 'Status Code: ' + error.response.status )
                if( error.response.status === 400 ){
                    console.error( 'Bad request ->  ' + LESSONS_BY_COURSE_REQ )
                    message.error( 'FORMATO DE DATOS INCORRECTO!' )
                }
                else if( error.response.status === 401 ){
                    console.error( 'Unauthenticated to access -> ' + LESSONS_BY_COURSE_REQ )
                }
                else if( error.response.status === 403 ){
                    console.error( 'Unauthorized to access -> ' + LESSONS_BY_COURSE_REQ )
                }
                else if( error.response.status === 500 ) {
                    console.error( 'Internal error -> ' + LESSONS_BY_COURSE_REQ )
                }
                else{
                    console.error( 'Unidentified error -> -> ' + LESSONS_BY_COURSE_REQ )
                } 
            }else{
                console.error( 'Disabled API service -> ' + LESSONS_BY_COURSE_REQ )
            }
        })  
    })
}
export function setLessonsToCourse(payload){
    return{
        type:'SET_LESSONS_TO_COURSE',
        payload
    }
}
export function setShowCourseLessons(payload){
    return{
        type:'SET_SHOW_COURSE_LESSONS',
        payload
    }
}
export function setCourseInformation(payload){
    return{
        type:'SET_COURSE_INFORMATION',
        payload
    }
}
